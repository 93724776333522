import React from "react";
import { useRef } from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./DestinationWeddings.css";
import AboutHotelWithBtn from "../../components/AboutHotelWithBtn";
import AboutHotelWithBtn2 from "../../components/AboutHotelWithBtn2";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Wedding/Destination/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Wedding/Destination/Box-2.png";

import explorer from "../../images/Selected/Wedding/Destination/DestinationIcon.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/wedding/Destination.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function checkScreen() {
  if (window.innerWidth > 1200) {
    document.getElementById("mainDivSec").style.className =
      "container mt-5 mb-5";
  } else {
    document.getElementById("mainDivSec").style.className =
      "container-fluid mt-5 mb-5";
  }
}

function DestinationWeddings() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);
  return (
    <>
      <div className="DestinationHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 ">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>
                <p className="hotel-address mb-5 pb-2">
                  You deserve a wedding venue as unique as you, and we know how
                  important it is to have the perfect celebration…
                </p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 ">
                  Wondering where to have your dream hillside wedding?
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 ">
                  Picture this: You and your partner, surrounded by the people
                  you love saying ‘I do’ while the majestic Sri Lankan hills
                  stretch behind you.
                  <p></p>We understand weddings in the hill country are not as
                  common as beachside weddings. However, if you both enjoy the
                  outdoors and dream of getting married under the open sky
                  everything you need is already here, waiting for you including
                  one of the most beautiful ceremonies in Hatton.
                  <p></p>The tea country in Sri Lanka is extremely picturesque,
                  with rolling hills covered in tea bushes and dense green cloud
                  forests, offering gorgeous vistas and a cool climate make for
                  the perfect intimate wedding – storybook charm on your special
                  day!
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Spafacilities"></div>
        <AboutHotelWithBtn
          img={aboutHotelimg1}
          header="Weddings in the Wild
          "
          des1="Are you and your partner an adventurous duo? Looking for a one-of-a-kind wedding to continue your love story. A Jungle wedding at Aviyana will give you just that! Exchange vows and celebrate your special day tucked in the lush green landscape amidst wildflowers and birdsongs.
          Picture a wedding like no other…an intimate and cozy ceremony in the outdoors, a long communal reception table, surrounded by towering trees accompanied by the mystery and magic of the forest.
          As you walk down the aisle, greetings from tropical birds, rushing and rumbling sounds of the waters nearby and the sun setting against the horizon are all set to add more air and excitement to your wedding
          The calming sounds of the lake and rustling leaves at Aviyana sure to elevate the ambiance of your special celebration. A chic boho style modern rustic-themed event with more emphasis on earthy tones, wooden interiors and bright oral arrangements, together with a live band jamming out to your favourite tunes as guests make their way to dance the night away, is just one of the many possibilities here.
          Another highlight will be our culinary experience. A thoughtfully curated food spread, live bar and several lounge areas for your guests will undoubtedly make your wedding truly memorable one. 
          "
          des2=" 
          "
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="TheSalon"></div>
        <AboutHotelWithBtn2
          img={aboutHotelimg2}
          header="Mountain Weddings"
          des1="Imagine a breeze flowing across the breathtaking mountain scenery used as the backdrop for a ceremony on the first day of a wonderful life together. 
          Aviyana provide a range of mountain wedding venues for your big day. Your wish is our command, whether it includes a dramatic top of the slope ceremony, a scenic backdrop of a stunning gold-framed wedding alter decorated with white bright arrangements overlooking the calm wind and the river - our dedicated staff will assist you in designing a seamless event.
         If you are considering a formal or traditional black-tie wedding and you envision your guests dressing ‘to the nines’, this might just be your ideal choice. For a more grandiose nuptial, we recommend setting up an exquisite head table for a sit-down dinner with elegant and lavish arrangements and classy music.
         To top it all off we recommend a grand entrance for the couple by boat (of course) and the exchange of vows on a deck set up on the water with the flowing river will accentuate the setting.
         Our delicious food and signature drinks amidst the grandest atmosphere will make your special day the most exquisite it can be.
         "
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="Spabrands"></div>

        {/* des sec end */}

        <div className="Destination-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default DestinationWeddings;
