import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

function Calender({ changeDate }) {
  const [date, setDate] = useState(new Date());
  const [viewCalender, setViewCalender] = useState(false);
  const onChange = (date) => {
    setDate(date);
    let fulldate = date.toString();
    let dd = fulldate.substring(11, 8);
    let mm = fulldate.substring(8, 4);
    let yy = fulldate.substring(15, 11);
    let finalDate = dd.concat(mm).concat(yy);
    changeDate(finalDate);
    setViewCalender(!viewCalender);
  };

  // const FormatingDate = (data) => {
  //   let fulldate = date.toString();
  //   let dd = fulldate.substring(11, 8);
  //   let mm = fulldate.substring(8, 4);
  //   let yy = fulldate.substring(15, 11);
  //   let finalDate = dd.concat(mm).concat(yy);
  //   changeDate(finalDate);
  // };

  return (
    <>
      <div>
        <Calendar onChange={onChange} value={date} />
      </div>
      <div id="demo"></div>
    </>
  );
}

export default Calender;
