import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";

import "./Awards.css";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import videobg from "../../images/Selected/HomeVideos/AwardsAccolades.m4v"; //replace the home banner to video

function Awards() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="Awards-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />

              {/* <h1>AVIYANA</h1> */}
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        {/* <div className="container">
          <div className="row">
            <div className="list-sec">
              <div className="listTitle mt-3 mb-3">
                <h1>Among the very best</h1>
              </div>
              <div className="points-section mt-3 mb-3">
                <div className="points-sec-part col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <ul>
                    <li>
                      Condé Nast Traveler Readers’ Choice Awards – Voted No. 7
                      in Best Resorts in Europe – 2022
                    </li>
                    <li>
                      The Travel Industry Awards 2022 - Winner of Sustainability
                      Initiative Of The Year - Communities
                    </li>
                    <li>
                      Bar of the Year Awards 2022 - voted 5 Star Hotel Bar of
                      the Year
                    </li>
                    <li>
                      Travel + Leisure 2022 - Voted No. 1 in The 5 Best Hotel
                      Resorts in the UK and Ireland
                    </li>
                    <li>
                      Forbes Travel Guide 2022 – Retained prestigious Five-Star
                      rating{" "}
                    </li>
                    <li>U.S. News 2022 - Voted No.4 Best Hotels in Europe </li>
                    <li>World Spa Awards 2021 - Ireland's Best Hotel Spa</li>
                    <li>
                      Green Awards 2021 - The Green Tourism & Entertainment
                      Award
                    </li>
                  </ul>
                </div>
                <div className="points-sec-part col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <ul>
                    <li>
                      TripAdvisor Travellers' Choice Awards 2021 - Voted No. 4
                      in the Top 25 Hotels in Ireland{" "}
                    </li>
                    <li>
                      Condé Nast Traveler Readers’ Choice Awards 2020 - Voted
                      No. 4 in Resort Hotel in Europe{" "}
                    </li>
                    <li>
                      TripAdvisor Travellers' Choice Awards 2020 - Voted No. 2
                      in the Top 25 Luxury Hotels in Ireland{" "}
                    </li>
                    <li>
                      World Spa Awards - Awarded 'Ireland's Best Hotel Spa 2020'
                      for the fifth consecutive year
                    </li>
                    <li>
                      Travel + Leisure ‘World’s Best’ Awards 2019 - Voted No. 3
                      in Top 10 Resort Hotels in the U.K. and Ireland{" "}
                    </li>
                    <li>
                      Green Hospitality 2019 – Ashford Estate awarded Gold for
                      Best Practice{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="space mt-5 mb-5"></div>
        <div className="Award-intro-header mb-5 mt-5">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1  mb-4">
                  Awards & Accolades
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Our rewards and accolades showcase the high standards that
                  personify the Aviyana brand
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="space mt-5 mb-5"></div>
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Awards;
