import React from "react";
import { useRef } from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./SpaFacilities.css";
import AboutHotelWithBtn from "../../components/AboutHotelWithBtn";
import AboutHotelWithBtn2 from "../../components/AboutHotelWithBtn2";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/spa/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/spa/Box-2.png";
import aboutHotelimg3 from "../../images/Selected/spa/Box-3.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/spa/Box-4.png";

import explorer from "../../images/Selected/spa/Spa.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/spa/Spa.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function checkScreen() {
  if (window.innerWidth > 1200) {
    document.getElementById("mainDivSec").style.className =
      "container mt-5 mb-5";
  } else {
    document.getElementById("mainDivSec").style.className =
      "container-fluid mt-5 mb-5";
  }
}

function SpaFacilities() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);
  return (
    <>
      <div className="spaHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 ">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 ">
                  Spa
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5">
                  Utterly serene and the ultimate in elegant escapism, seemingly
                  beyond time’s reach in Aviyana’s lush gardens, lies the
                  resort’s holistic Aviyana Spa.
                  <p></p> A deeply cocooning place to reconnect and indulge.
                  Expert wellness specialists offer a range of therapies and
                  treatments, and our in-house Ayurvedic doctor personally
                  curates multi-day ‘Individual Wellness Immersions’ drawing on
                  the wisdom of this ancient healing philosophy.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="spa-header-img-sec1"></div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Spafacilities"></div>
        <AboutHotelWithBtn
          img={aboutHotelimg1}
          header="Spa facilities 
          "
          des1="Welcome to Aviyana as Sri Lanka’s first full-fledged destination spa. Our spa tucked into the hillside’s natural contours is a true oasis, and has been thoughtfully designed with holistic
          wellness in mind where your mind and body will be enveloped in the ultimate blend of care, professionalism and harmony.
          The Aviyana Spa’s experienced therapists combine global wellness practices with traditional healing rituals combining natural ingredients sourced from the surroundings locales and
          around Sri Lanka. Various Ayurvedic massage techniques are used in synergy with Provos organic cold-pressed virgin coconut oil, known for its healing and anti-ageing properties.
          "
          des2=" "
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="TheSalon"></div>
        <AboutHotelWithBtn2
          img={aboutHotelimg2}
          header="The Salon"
          des1="Glow from top to toe, the perfect culmination after your spa experience. The Salon's services include hair treatments and styling, mask therapy for conditioning the scalp and hair. All services
          can be personalised to suit your needs and included with any service."
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="Spabrands"></div>

        <AboutHotelWithBtn
          img={aboutHotelimg3}
          header="Spa brands
          "
          des1="Our spa brands feature exceptional partners to elevate treatments and wellness therapies for guests. We also use a diverse range of organic ingredients in our treatments"
          des2=""
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW  
          "
        />
        <div className="space mt-5 mb-5" id="Spaetiquette"></div>

        <AboutHotelWithBtn2
          img={aboutHotelimg4}
          header="Spa etiquette "
          des1="We safety of our guests are our highest priority. During this unprecedented time, if you would like to use a facility, please do book a reservation. We advise guests to bring a bathing suit to use the
          relaxation pool and gym attire for the fitness suite."
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 "></div>

        {/* des sec end */}

        <div className="Header-Content1" id="Spainquiry">
          <div className="Header-Content-bar  ">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Spa inquiry
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-5">
                  Our dedicated Spa team are excited to create your bespoke Spa
                  journey. Please submit your request via the form provided
                  below...
                  <br />
                  <p></p> Please submit your request via the form provided
                  below...
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="spa-header-img-sec2"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default SpaFacilities;
