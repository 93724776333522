import React from "react";
import CheckDate from "../../components/CheckDate";
import StayInTouch from "../../components/StayInTouch";
import { OurTeamdata } from "../../components/SliderData";
import OurteamSlider from "../../components/OurteamSlider";

import Footer from "../../components/Footer";
import "./ImgGallaryPage.css";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import galleryimg1 from "../../images/galleryImg/galleryimg1.png";
import galleryimg2 from "../../images/galleryImg/galleryimg2.png";
import galleryimg3 from "../../images/galleryImg/galleryimg3.png";
import galleryimg4 from "../../images/galleryImg/galleryimg4.png";
import galleryimg5 from "../../images/galleryImg/galleryimg5.png";
import galleryimg6 from "../../images/galleryImg/galleryimg6.png";
import galleryimg7 from "../../images/galleryImg/galleryimg7.png";
import NewCheckDate from "../../components/NewCheckDate.js";

import ImgGallaryPageComponent from "../../components/ImgGallaryPageComponent";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import videobg from "../../images/Selected/HomeVideos/ImageGallery.m4v"; //replace the home banner to video

function ImgGallaryPage() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="Image-gallary-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />

              {/* <h1>AVIYANA</h1> */}
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />

        {/* <ImgGallaryPageComponent
          gimg1={galleryimg1}
          gimg2={galleryimg2}
          gimg3={galleryimg3}
          gimg4={galleryimg4}
          gimg5={galleryimg5}
          gimg6={galleryimg6}
          gimg7={galleryimg7}
        /> */}
        <div className="space mt-5 mb-5"></div>
        <div className="image-gallary-intro-header mb-5 mt-5">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1  mb-4">
                  Image Gallery
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Explore the beauty and stunning views of Aviyana and become
                  inspired
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="space mt-5 mb-5"></div>
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default ImgGallaryPage;
