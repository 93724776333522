import { useEffect, useState } from "react";
import "./PopUpserver.css";
import NewCheckDate from "./NewCheckDate.js";
import NewNavbar2 from "./NewNavbar2";

import monoSiteLogo from "../images/siteLogo/logo.png";

function PopUpserver() {
  // window.addEventListener("scroll", noscroll);

  // function noscroll() {

  // }

  function hidePopUp() {
    document.getElementById("PopUpSever").style.display = "none";
  }

  return (
    <>
      <div className="Pop-up-sever-sec" id="PopUpSever">
        <div className="server-bar">
          <div className="server-box">
            <div>
              <div className="card-sec">
                <div className="card">
                  <div className="card-header">
                    <h3>Why did you select Aviyana?</h3>
                  </div>
                  <div className="select-sec">
                    <select name="Question1" id="Q1" className="select-list">
                      <option value="AdventurousActivities">
                        Adventurous activities
                      </option>
                      <option value="Relaxation">Relaxation</option>
                      <option value="Surroundings">Surroundings </option>
                      <option value="Food">Food</option>
                      <option value="Wellness">Wellness </option>
                    </select>
                  </div>
                  <div className="card-header">
                    <h3> What activity are you seeking from Aviyana?</h3>
                  </div>
                  <div className="select-sec">
                    <select name="Question2" id="Q2" className="select-list">
                      <option value="AirSports">Air Sports</option>
                      <option value="WellnessRetreat">Wellness Retreat </option>
                      <option value="AshramExperience">
                        Ashram Experience
                      </option>
                      <option value="Honeymoon">Honeymoon </option>
                      <option value="Vacation">Vacation </option>
                      <option value="TemporaryOrdination">
                        Temporary Ordination
                      </option>
                      <option value="BusinessPurpose ">Business Purpose</option>
                    </select>
                  </div>
                  <div className="server-submit-btn-sec">
                    <input
                      type="submit"
                      value="submit"
                      onClick={() => hidePopUp()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewNavbar2 />
      <div className="home" id="enqyuirehome">
        <div className="home-sec row">
          <NewCheckDate />
          <div className="enquire-from-sec mb-5">
            <div className="enquire-from-bar">
              <div className="enquire-from-area">
                <form action="./" className="enquire-from">
                  <div className="enquire-site-logo">
                    <img src={monoSiteLogo} alt="" />
                  </div>
                  <div className="enquire-header-txt">
                    <h3>
                      Plan your event at <br />
                      Aviyana
                    </h3>
                  </div>
                  <div className="enquire-input-list">
                    <div className="enquire-input-sec row">
                      <div className="enquire-input-item col-6">
                        <label for="Title">Title *</label>
                        <select
                          name="Title"
                          id="Title"
                          className="enquire-item"
                        >
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs </option>
                          <option value=" Ms">Ms</option>
                          <option value="Miss">Miss </option>
                          <option value="Dr">Dr </option>
                          <option value=" Prof">Prof</option>
                          <option value="Other">Other</option>
                        </select>

                        <label for="fname">first name *</label>
                        <input type="text" name="fname" className="txtInput" />

                        <label for="lname">Last name *</label>
                        <input type="text" name="lname" className="txtInput" />

                        <label for="email">Email *</label>
                        <input type="Email" name="email" className="txtInput" />

                        <label for="telephone">Telephone *</label>
                        <input
                          type="text"
                          name="telephone"
                          className="txtInput"
                        />
                      </div>
                      <div className="enquire-input-item col-6">
                        <label for="occasion">Please select occasion *</label>
                        <select
                          name="occasion"
                          id="Title"
                          className="enquire-item"
                        >
                          <option value="Mr">Wedding</option>
                          <option value="Mrs">Brithday</option>
                          <option value=" Ms">Anniversay</option>
                          <option value="Miss">Meeting</option>
                          <option value="Dr">Hybrid meeting</option>
                          <option value=" Prof">Outdoor event</option>
                          <option value="Other">Christmas</option>
                          <option value="Other">Other</option>
                        </select>

                        <label for="Time">Date *</label>

                        <input
                          type="date"
                          id="SelectedDate"
                          name="date"
                          className="EnquireSelecteddate"
                        />
                        <label for="date">AM/PM *</label>

                        <input
                          type="time"
                          id="appt"
                          name="appt"
                          min="09:00"
                          max="18:00"
                          className="EnquireSelecteddate"
                          required
                        />

                        <label for="adults">Adults *</label>
                        <input type="text" name="asults" className="txtInput" />

                        <label for="childern">Childern *</label>
                        <input
                          type="text"
                          name="childern"
                          className="txtInput"
                        />
                      </div>

                      <div className="enquire-input-sec mt-5 col-12">
                        <div>
                          <p>Special requirements</p>
                          <p>
                            <textarea
                              id="w3review"
                              name="w3review"
                              rows="6"
                              cols="50"
                            ></textarea>
                          </p>
                          <p>Data Consent</p>
                          <input
                            type="checkbox"
                            id="agree"
                            name="agreeCheck"
                            value="agree"
                            className="agreeCheck"
                          ></input>
                          Please tick to receive newsletters with exclusive
                          offers, experiences and travel inspiration
                          <p></p>
                          <div className="server-submit-btn-sec">
                            <input type="submit" value="submit" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopUpserver;
