// import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/VirtualTourStyles.css";
import { BsArrowRight } from "react-icons/bs";

//animation
import { useEffect } from "react";
import "./Animation.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AOS from "aos";
import "aos/dist/aos.css";
import { useRef } from "react";

function VirtualTour(props) {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);
  return (
    <div className="BgContent-sec mt-5 ">
      <div className="row">
        <div
          className="vr-content"
          style={{
            backgroundImage: `url(${props.bgImg})`,
          }}
        >
          <div data-aos="fade-up" className="header-section">
            <p className="semi-header">{props.tagLine}</p>
            <h1 className="vr-header">{props.header}</h1>
          </div>
          <div data-aos="fade-up" className="header-section ">
            <p className="tour-desc">{props.des}</p>
            <a className={props.value ? "vr-link" : "hide"} href={props.link}>
              {props.linkName} <BsArrowRight className="link-arrow" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualTour;
