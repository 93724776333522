import { useRef } from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./CULTURE.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/culture/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/culture/Box-2.png";
import aboutHotelimg3 from "../../images/Selected/culture/Box-3.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/culture/Box-4.png";
import aboutHotelimg5 from "../../images/Selected/culture/Box-5.png"; //chnage the img
import aboutHotelimg6 from "../../images/Selected/culture/Box-6.png";

import explorer from "../../images/Selected/culture/Culture .png";

import videobg from "../../images/Selected/HomeVideos/exp/Culture.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function CULTURE() {
  function checkScreen() {
    if (window.innerWidth > 1200) {
      document.getElementById("mainDivSec").style.className =
        "container mt-5 mb-5";
    } else {
      document.getElementById("mainDivSec").style.className =
        "container-fluid mt-5 mb-5";
    }
  }
  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);

  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="CULTUREHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content ">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1
                  data-aos="fade-up"
                  className="hotel-semi-title1 mt-4 mb-4"
                  id="Explorevillage"
                >
                  Explore village life
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Escape to Sri Lanka's Kandyan foothills and spend a morning
                  exploring the picturesque rural countryside which lies in the
                  shadow of the glorious Knuckles Mountain Range.
                  <br />
                  <p></p>Trek through a scenic village and the surrounding
                  countryside and take in the peaceful surroundings of
                  glimmering green paddy fields, babbling brooks and small
                  houses set next to vibrant vegetable plots. You will have the
                  opportunity to learn about Sri Lanka's traditional farming
                  techniques first-hand as you pass by farmers working in the
                  fields. This gentle hike is also rewarding for wildlife are
                  you will spot endemic birds, butterflies and quick-footed
                  lizards during your journey.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="culture-header-img-sec1"></div>

        <div className="Header-Content mt-4" id="ExploreKandy">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Explore Kandy
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Kandy is one of the most beautiful cities in Sri Lanka, known
                  for its scenic beauty and rich cultural heritage. Spread over
                  an area of around 1940 square kilometers, Kandy belongs to the
                  exclusive list of UNESCO World Heritage Sites
                  <br />
                  <p></p>The city promises a number of tourist attractions that
                  include the Old Royal Palace and the Temple of the Tooth.
                  Kandy offers an array of exciting shopping experiences with
                  souvenirs ranging from precious stones to handmade laces and
                  stone and wooden carvings. If travelling during August, you
                  can get a taste of fabulous Kandy Esala Perahera where your
                  vacation can be spiritually enriching and culturally uplifting
                  at the same time.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="culture-header-img-sec2 "></div>

        {/* des sec start */}
        <div className="space mt-2 mb-5" id="SriLankanPaddy"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Sri Lankan Paddy farming Experience
          "
          des1="Get ready to get your hands dirty as this activity is very immersive and hands-on. Work alongside the local farmers in paddy fields and experience true Sri Lankan farming practices. Gain
          first-hand, an experience of the daily life of villagers as they work through the day. Especially for our guests who have a passion for agriculture, the experience is ideal as you learn about
          Sri Lankan traditional agriculture practices as well as experience the daily life of a farmer and enjoy a wholesome lunch with him and his colleagues."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="ToddyTapping"></div>

        <AboutHotel2
          img={aboutHotelimg2}
          header="Trickle / Toddy Tapping and Testing Experience"
          des1="Sri Lanka is blessed with an abundance of coconut trees. It is also famous for coconut toddy which is a mildly alcoholic drink made from the sap of the coconut flower. This is a
          refreshing drink that is popular among the people of Sri Lanka. You will have the opportunity to meet a toddy tapper and learn more about the art and enjoy some fresh toddy."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        <div
          onLoad={checkScreen}
          className="container mt-5 mb-5"
          id="mainDivSec"
        >
          <div className="row about-content" id="Library">
            <div className="Library-hotel-bar">
              <div className="txt-area col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div
                  data-aos="fade-up" //text animation
                  className="txt-sec "
                >
                  <h1 className="header-welcome ">The Library</h1>
                  <p className="desc-chapter-11 row">
                    A Library is more than a collection of documents. Libraries
                    have used different methods for procurement, arrangement,
                    and maintenance in preserving the housed information
                    materials for posterity. The Aviyana library Manuscripts is
                    a reservoir of the collective human spirit through which we
                    preserve and perpetuate the dreams and achievements of
                    humanity and thus help preserve World heritage documented in
                    various types of manuscripts. <br />
                    The existence of manuscripts is as old as the origin of
                    written records. The history of writing and the history of
                    civilisation are inseparable entities. The ancient knowledge
                    in the form of manuscripts which are the powerful medium for
                    the transmission of ideas, facts, and knowledge of our
                    society and they serve as powerful tools for the
                    preservation of our literary, linguistic, cultural and
                    artistic heritage. Manuscripts serve as a source for
                    targeted knowledge rediscovering system as such and thus
                    help in the evolution of the intellectual process of
                    mankind.
                    <br />
                    The choice to savour only the finer things in life comes
                    with experience. Or at least, with a very refined outlook
                    about everything in general. A classic tribute to this way
                    of living is The Library, a bar that is open to members only
                    and takes privacy and exclusivity seriously. True to its
                    name, it also houses a treasure of classic books for you to
                    lose yourself in while enjoying a shot of single malt. Even
                    while you sit here by yourself, you'll have the fine company
                    of the classic oldschool charm. When you're in a
                    light-hearted frame of mind, you could spend the evening.
                    <br />
                    If you're someone with a particularly sophisticated taste,
                    or just a connoisseur of everything classy, a beautiful
                    evening at The Library is something you wouldn't want to
                    miss.
                  </p>
                </div>
              </div>
              <div className="img-sec col-xl-6 col-lg-12 col-md-12 col-sm-12 ">
                <div className="img-area1">
                  <a className="linked-image1" href="/">
                    {/* img animation */}
                    <div ref={animRef1} className="animation">
                      <img
                        className="AboutHotel-img"
                        src={aboutHotelimg3}
                        alt="castle"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="space mt-5 mb-5" id="Ourresident"></div>

        <AboutHotel2
          img={aboutHotelimg4}
          header="Meet our Artisan Craftsman
          "
          des1="Sri Lanka has a rich history of artisan craftsmanship, and central Sri Lanka in particular has a plethora of craftspeople showcasing their talent and work. We will
          take you into the workshops and homes of these artists and craftspeople. You will receive an understanding of the amazing skills involved in the use of their
          hand-tools and be able to try them out for yourself.
          "
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        <div className="culture-header-img-sec3 "></div>
        <div className="space mt-5 mb-5" id="ChocolateFactory"></div>

        <AboutHotel
          img={aboutHotelimg5}
          header="Chocolate Factory
          "
          des1="Aviyana Chocolate Factory was founded with the intent of perfection and satisfaction. We are dedicated to making high-quality
          chocolate in a socially responsible way. Our artisan chocolate bars are hand-poured and hand-labeled to maintain curated product.
          The Aviyana Chocolate Factory has found suppliers who share values in preserving the earth and taking care of the people who
          grow and produce this wonderful organic food. "
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="Cinema"></div>

        <AboutHotel2
          img={aboutHotelimg6}
          header="Cinema"
          des1="Our premier cinema room is a magnificent feature - designed with the charm and luxurious comfort of a traditional cinematic experience. Bring the family along and
          settle in with fresh popcorn, snacks and beverages and watch a variety of family favourites and all-time classics or enjoy a romantic evening with a loved one. The film
          screened change weekly to ensure there is something for everyone to enjoy. For a unique experience, why not hire a private screening and enjoy the facilities
          completely to yourself! "
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 "></div>

        {/* des sec end */}

        <div className="Header-Content " id="Ataste">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1  mb-4">
                  A taste of the Knuckles
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  A hiker’s heaven in Sri Lanka, the Knuckles Mountain Range
                  consists of five prominent peaks and 34 other smaller peaks.
                  Filled with breathtaking hiking trails with unspoiled beauty,
                  it is considered among the best hiking and trekking areas in
                  Sri Lanka.
                  <br />
                  <p></p>The Knuckles Mountain terrain suits both beginner and
                  experienced trekkers, where it offers popular hiking trails as
                  well as off-the-beaten trekking routes. The climate of the
                  Knuckles Mountain range is constantly changing and will help
                  you enjoy the best weather on the day of your adventure.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="culture-header-img-sec4 "></div>

        <div className="Header-Content mt-5" id="SriLankanancient">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1  mb-4">
                  Sri Lankan ancient medicine & story
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  We provide one of the most authentic and traditional Ayurveda*
                  experiences. Our tour is tailored to help you rejuvenate and
                  to help you relax. We also arrange appointments with Ayurveda
                  doctors if you have any special requirements or ailments that
                  you seek advice. You will get to experience the ancient
                  treatment techniques that have been followed for centuries. We
                  provide one of the most authentic and traditional Ayurveda
                  experiences. For those who are interested in Yoga we can
                  extend the program for you to experience some yoga lessons
                  with our yoga trainer.
                  <br />
                  <p></p>(*In Sanskrit Ayurveda means the ‘Science of Life’. The
                  system of medicine is based on ancient scripts written in the
                  ancient language of Sanskrit. Ayurveda is one of the oldest
                  sciences of natural healing in the world. Whilst Ayurveda
                  originated in India more than 5000 years ago it has been
                  practised in Sri Lanka for many centuries. This branch of
                  science focuses on natural remedies for the treatment of
                  ailments and believes in the harmony and balance of the
                  physical body with the worldly elements.)
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="culture-header-img-sec5 "></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default CULTURE;
