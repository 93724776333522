import React, { useState } from "react";
import { Link } from "react-router-dom";
import { theExperiencesDropdown } from "./NavItems";
import "./ExperiencesDropdown.css";
import { HashLink } from "react-router-hash-link";

function ExperiencesDropdown() {
  const [dropdown, setDropdown] = useState(false); // create stute
  const redirectToPage = (a) => {
    window.location = a;
  };

  return (
    <>
      <div className="navigation-sub-title-sec">
        <div className="navigation-sub-nav-bar ">
          <div className="navigation-sub-nav-part col">
            <div className="subNav-part-sec1">
              <ul className="sub-title-list">
                <div className="subnav-header-sec">
                  <li>
                    <p
                      className="subNavHeader"
                      onClick={() => redirectToPage("/OutdoorAdventure")}
                    >
                      - Outdoor Adventure
                    </p>
                  </li>
                </div>
                {theExperiencesDropdown.map((item) => {
                  if (item.id < 14) {
                    return (
                      <div className="subnav-list-item">
                        <li key={item.id}>
                          <HashLink
                            to={item.path}
                            // onClick={() => redirectToPage(item.path)}
                            className="submenu-item1"
                          >
                            {item.title}
                          </HashLink>
                        </li>
                      </div>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="navigation-sub-nav-part col">
            <div className="subNav-part-sec1">
              <ul className="sub-title-list">
                <div className="subnav-header-sec">
                  <li>
                    <p
                      className="subNavHeader"
                      onClick={() => redirectToPage("/Sports")}
                    >
                      - Sport
                    </p>
                  </li>
                </div>
                {theExperiencesDropdown.map((item) => {
                  if (item.id > 13 && item.id < 22) {
                    return (
                      <div className="subnav-list-item">
                        <li key={item.id}>
                          <HashLink
                            to={item.path}
                            className="submenu-item1"
                            // onClick={() => redirectToPage(item.path)}
                          >
                            {item.title}
                          </HashLink>
                        </li>
                      </div>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="navigation-sub-nav-part col">
            <div className="subNav-part-sec1">
              <ul className="sub-title-list">
                <div className="subnav-header-sec">
                  <li>
                    <p
                      className="subNavHeader"
                      onClick={() => redirectToPage("/Culture")}
                    >
                      - Culture
                    </p>
                  </li>
                </div>
                {theExperiencesDropdown.map((item) => {
                  if (item.id > 21 && item.id < 33) {
                    return (
                      <div className="subnav-list-item">
                        <li key={item.id}>
                          <HashLink
                            to={item.path}
                            className="submenu-item1"
                            // onClick={() => redirectToPage(item.path)}
                          >
                            {item.title}
                          </HashLink>
                        </li>
                      </div>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="navigation-sub-nav-part col">
            <div className="subNav-part-sec1">
              <ul className="sub-title-list">
                <div className="subnav-header-sec">
                  <li>
                    <p
                      className="subNavHeader"
                      onClick={() => redirectToPage("/Entertainment")}
                    >
                      - Entertainment{" "}
                    </p>
                  </li>
                </div>
                {theExperiencesDropdown.map((item) => {
                  if (item.id > 32) {
                    return (
                      <div className="subnav-list-item">
                        <li key={item.id}>
                          <HashLink
                            to={item.path}
                            className="submenu-item1"
                            // onClick={() => redirectToPage(item.path)}
                          >
                            {item.title}
                          </HashLink>
                        </li>
                      </div>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExperiencesDropdown;
