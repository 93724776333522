// making arrays for main nav and sun navbars
//array for main navbar
import gatewayImg1 from "../images/gatewayImgs/gataway1.webp";
import gatewayImg2 from "../images/gatewayImgs/gataway2.webp";
import gatewayImg3 from "../images/gatewayImgs/gataway3.webp";
import gatewayImg4 from "../images/gatewayImgs/gataway4.webp";

export const bookItems = [
  {
    id: 1,
    title: "CONTACT",
    path: "./contact",
    cName: "booking-item",
  },
  {
    id: 2,
    title: "MODIFY BOOKING",
    path: "./modifyBooking",
    cName: "booking-item",
  },
];

export const navItems = [
  {
    id: 1,
    title: "THE AVIYANA",
    path: "/",
    cName: "nav-item",
  },
  {
    id: 2,
    title: "CHALETS",
    path: "/chalets",
    cName: "nav-item",
  },
  {
    id: 3,
    title: "OFFERS & GIFTS",
    path: "/offers",
    cName: "nav-item",
  },
  {
    id: 4,
    title: "EXPERIENCES",
    path: "/experiences",
    cName: "nav-item",
  },

  {
    id: 5,
    title: "DINNING & DRINKS",
    path: "/discover",
    cName: "nav-item",
  },
  {
    id: 6,
    title: "SPA & WELLNESS",
    path: "/spa",
    cName: "nav-item",
  },
  {
    id: 7,
    title: "WEDDING & EVENTS",
    path: "/wedding",
    cName: "nav-item",
  },
  {
    id: 8,
    title: "BOUTIQUES",
    path: "/boutique",
    cName: "nav-item",
  },
];
// making array for sub nav bar
export const theAviyanaDropdown = [
  {
    id: 1,
    title: "Aviyana",
    path: "./Aviyana",

    cName: "submenu-item",
  },
  {
    id: 2,
    title: "Reasons we are unique",
    path: "./reasonsWeAreUnique",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "AVIYANA Concept",
    path: "./aviyanaConcept",
    cName: "submenu-item",
  },
  // {
  //   id: 4,
  //   title: "Power of Passion",
  //   path: "./",
  //   cName: "submenu-item",
  // },
  {
    id: 5,
    title: "Partners & suppliers",
    path: "./Partners",
    cName: "submenu-item",
  },
  {
    id: 6,
    title: "Image Gallery",
    path: "./imageGallery",
    cName: "submenu-item",
  },
  {
    id: 7,
    title: "Sustainability",
    path: "./Sustainability",
    cName: "submenu-item",
  },
  {
    id: 8,
    title: "Awards & Accolades",
    path: "./Awards",
    cName: "submenu-item",
  },
  {
    id: 9,
    title: "In the Press",
    path: "./InThePress",
    cName: "submenu-item",
  },
  {
    id: 10,
    title: "Location & Directions",
    path: "./location",
    cName: "submenu-item",
  },
  {
    id: 11,
    title: "Families",
    path: "./families",
    cName: "submenu-item",
  },
  {
    id: 12,
    title: "Pets",
    path: "./pets",
    cName: "submenu-item",
  },
  {
    id: 13,
    title: "Wedding & Events",
    path: "./WeddingAndEvents",
    cName: "submenu-item",
  },
  {
    id: 14,
    title: "Virtual Tour",
    path: "./VirtualTour",
    cName: "submenu-item",
  },
  {
    id: 15,
    title: "Meet our team",
    path: "./MeetOurTeam",
    cName: "submenu-item",
  },
  {
    id: 16,
    title: "Accessibility",
    path: "./Accessibility",
    cName: "submenu-item",
  },
  {
    id: 17,
    title: "Covid-19 Updates",
    path: "./Covid19Update",
    cName: "submenu-item",
  },
  {
    id: 18,
    title: "Frequently ask questions",
    path: "./Faqs",
    cName: "submenu-item",
  },
  {
    id: 19,
    title: "27*7 Butler service",
    path: "./SpecialService#BulterService",
    cName: "submenu-item",
  },
  {
    id: 20,
    title: "On-site Electric car Operation",
    path: "./SpecialService#ElectricCar",
    cName: "submenu-item",
  },
  {
    id: 21,
    title: "27*7 In-house doctor",
    path: "./SpecialService",
    cName: "submenu-item",
  },
  {
    id: 22,
    title: "On-site Helicopter ",
    path: "./SpecialService#Helicopter",
    cName: "submenu-item",
  },
  {
    id: 23,
    title: "Aviyana Arm",
    path: "./SpecialService#AviyanaArm",
    cName: "submenu-item",
  },
];

export const theChaletsDropdown = [
  {
    id: 1,
    title: "Premium Villa",
    path: "./chalets#PremiumVilla",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "Royal Villa",
    path: "./chalets#RoyalVilla",
    cName: "submenu-item",
  },

  {
    id: 3,
    title: "Royal Governor's Villa",
    path: "./chalets#RoyalGovernorsVilla",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Royal Queen's Villa",
    path: "./chalets#RoyalQueensVilla",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "Royal King's Villa",
    path: "./chalets#RoyalKingsVilla",
    cName: "submenu-item",
  },
  {
    id: 6,
    title: "Total Buyout Aviyana",
    path: "./chalets#TotalBuyoutAviyana",
    cName: "submenu-item",
  },
];

export const theOffersDropdown = [
  {
    id: 1,
    title: "National ",
    path: "./",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "International ",
    path: "./",
    cName: "submenu-item",
  },

  {
    id: 3,
    title: "Repeaters Club",
    path: "./",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Honorable",
    path: "./",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "Special Tours",
    path: "./",
    cName: "submenu-item",
  },
  {
    id: 6,
    title: "Promotions",
    path: "./",
    cName: "submenu-item",
  },
  {
    id: 7,
    title: "Special Packages",
    path: "./",
    cName: "submenu-item",
  },
  {
    id: 8,
    title: "Business Corporate Package",
    path: "./",
    cName: "submenu-item",
  },
  {
    id: 9,
    title: "OBT Training Corporate Expo Teams ",
    path: "./",
    cName: "submenu-item",
  },
];

export const theExperiencesDropdown = [
  // {
  //   id: 1,
  //   title: "Discover all activities",
  //   path: "./OutdoorAdventure",
  //   cName: "submenu-item",
  // },
  // {
  //   id: 2,
  //   title: "Air Ballooning",
  //   path: "./OutdoorAdventure",
  //   cName: "submenu-item",
  // },

  {
    id: 3,
    title: "Bird Watching",
    path: "./OutdoorAdventure#BirdWatching",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "River Walk",
    path: "./OutdoorAdventure#RiverWalk",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "Biking Tour & Cycling",
    path: "./OutdoorAdventure#BikingTour",
    cName: "submenu-item",
  },
  {
    id: 6,
    title: "Scenic Walking, Hiking & Tracking ",
    path: "./OutdoorAdventure#ScenicWalking",
    cName: "submenu-item",
  },
  {
    id: 7,
    title: "Estate gardens",
    path: "./OutdoorAdventure#Estategardens",
    cName: "submenu-item",
  },
  {
    id: 8,
    title: "Equestrian",
    path: "./OutdoorAdventure#Equestrian",
    cName: "submenu-item",
  },
  {
    id: 9,
    title: "Jeep Safari",
    path: "./OutdoorAdventure#JeepSafari",
    cName: "submenu-item",
  },
  {
    id: 10,
    title: "Camping Day/Night ",
    path: "./OutdoorAdventure#Camping",
    cName: "submenu-item",
  },
  {
    id: 11,
    title: "River Drifting",
    path: "./OutdoorAdventure#RiverDrifting",
    cName: "submenu-item",
  },
  {
    id: 12,
    title: "Guided Nature Experiences",
    path: "./OutdoorAdventure#GuidedNature",
    cName: "submenu-item",
  },
  {
    id: 13,
    title: "High tea by the lake ",
    path: "./OutdoorAdventure#Hightea",
    cName: "submenu-item",
  },
  {
    id: 14,
    title: "Skydiving",
    path: "./Sports#Skydiving",
    cName: "submenu-item",
  },
  {
    id: 15,
    title: "Paragliding",
    path: "./Sports#Paragliding",
    cName: "submenu-item",
  },
  {
    id: 16,
    title: "Hot Air Ballooning",
    path: "./Sports#HotAir",
    cName: "submenu-item",
  },
  // {
  //   id: 17,
  //   title: "Clay shooting & archery",
  //   path: "./Sports#Clayshooting",
  //   cName: "submenu-item",
  // },
  {
    id: 18,
    title: "Zip-lining & tree climbing",
    path: "./Sports#Ziplining",
    cName: "submenu-item",
  },
  {
    id: 19,
    title: "Rock Climbing",
    path: "./Sports#RockClimbing",
    cName: "submenu-item",
  },
  {
    id: 20,
    title: "Canoeing Victoria Lake",
    path: "./Sports#Canoeing",
    cName: "submenu-item",
  },
  {
    id: 21,
    title: "Fitness Center ",
    path: "./Sports#Fitness",
    cName: "submenu-item",
  },
  {
    id: 22,
    title: "Sri Lankan Paddy farming Experience Trickle / Toddy Testing",
    path: "./Culture#SriLankanPaddy",
    cName: "submenu-item",
  },

  {
    id: 23,
    title: "Chocolate Factory ",
    path: "./Culture#ChocolateFactory",
    cName: "submenu-item",
  },
  {
    id: 24,
    title: "The Library",
    path: "./Culture#Library",
    cName: "submenu-item",
  },
  {
    id: 25,
    title: "Trickle / Toddy Tapping",
    path: "./Culture#ToddyTapping",
    cName: "submenu-item",
  },
  {
    id: 26,
    title: "A taste of the Knuckles",
    path: "./Culture#Ataste",
    cName: "submenu-item",
  },
  {
    id: 27,
    title: "Sri Lankan ancient medicine & story",
    path: "./Culture#SriLankanancient",
    cName: "submenu-item",
  },
  {
    id: 28,
    title: "Our resident artist:",
    path: "./Culture#Ourresident",
    cName: "submenu-item",
  },
  // {
  //   id: 29,
  //   title: "Day trips",
  //   path: "./Culture#Daytrips",
  //   cName: "submenu-item",
  // },
  {
    id: 30,
    title: "Explore village life",
    path: "./Culture#Explorevillage",
    cName: "submenu-item",
  },
  {
    id: 31,
    title: "Explore Kandy",
    path: "./Culture#ExploreKandy",
    cName: "submenu-item",
  },
  {
    id: 32,
    title: "Cinema",
    path: "./Culture#Cinema",
    cName: "submenu-item",
  },
  {
    id: 33,
    title: "Authentic & Traditional Dance",
    path: "./Entertainment#Authentic",
    cName: "submenu-item",
  },
  {
    id: 34,
    title: "Art & Music Studio",
    path: "./Entertainment#ArtAndMusic",
    cName: "submenu-item",
  },
  {
    id: 35,
    title: "Music sessions",
    path: "./Entertainment#Musicsessions",
    cName: "submenu-item",
  },
  {
    id: 36,
    title: "Antique Auctions",
    path: "./Entertainment#AntiqueAuctions",
    cName: "submenu-item",
  },
  {
    id: 37,
    title: "Gem Auctions",
    path: "./Entertainment#GemAuctions",
    cName: "submenu-item",
  },
  {
    id: 38,
    title: "Cocktail Evenings",
    path: "./Entertainment#CocktailEvenings",
    cName: "submenu-item",
  },
  {
    id: 39,
    title: "Fire Pit",
    path: "./Entertainment#FirePit",
    cName: "submenu-item",
  },
  // {
  //   id: 40,
  //   title: "Special Sri Lankan Drama",
  //   path: "./Entertainment#SpecialSriLankan",
  //   cName: "submenu-item",
  // },
];
export const theDiscoverDinningDropdown = [
  {
    id: 1,
    title: "Breakfast",
    path: "./DiningAndDrinks#Breakfast",
    cName: "submenu-item",
  },

  {
    id: 2,
    title: "Afternoon Tea",
    path: "./DiningAndDrinks#AfternoonTea",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "Private Dinning",
    path: "./DiningAndDrinks#PrivateDinning",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Sri Lankan King meal ( Rajasinghe Menu) ",
    path: "./DiningAndDrinks#SriLankanKing",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "Aviyana Wine Collection",
    path: "./DiningAndDrinks#AviyanaWineCollection",
    cName: "submenu-item",
  },

  {
    id: 6,
    title: "Sundowners",
    path: "./DiningAndDrinks#Sundowners",
    cName: "submenu-item",
  },
  {
    id: 6,
    title: "Cooking Lessons",
    path: "./DiningAndDrinks#CookingLessons",
    cName: "submenu-item",
  },
  {
    id: 7,
    title: "Sri Lankan Authentic Restaurant",
    path: "./Restaurants#SriLankanAuthenticRestaurant",
    cName: "submenu-item",
  },
  {
    id: 8,
    title: "The World is one Restaurent",
    path: "./Restaurants#TheWorldisone",
    cName: "submenu-item",
  },
  {
    id: 9,
    title: "The Aviyana Sky Bar",
    path: "./Bars#TheAviyanaSkyBar",
    cName: "submenu-item",
  },
  {
    id: 10,
    title: "The Billiards Room & Cigur Terrace",
    path: "./Bars#TheBilliardsRoom",
    cName: "submenu-item",
  },
  {
    id: 10.5,
    title: "Wine tasting",
    path: "./Bars#Winetasting",
    cName: "submenu-item",
  },

  {
    id: 11,
    title: "Sri Lankan Special Brew",
    path: "./Bars#SriLankanSpecialBrew",
    cName: "submenu-item",
  },
  {
    id: 12,
    title: "Pick and Cook",
    path: "./ExperienceSubItems#PickandCook",
    cName: "submenu-item",
  },
  {
    id: 13,
    title: "Taste the spice ",
    path: "./ExperienceSubItems#Tastethespice",
    cName: "submenu-item",
  },
  {
    id: 14,
    title: "make your handcraft",
    path: "./ExperienceSubItems#handcraft",
    cName: "submenu-item",
  },
];
export const theSpaDropdown = [
  {
    id: 1,
    title: "Spa facilities",
    path: "./spaFacilities#Spafacilities",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "The Salon",
    path: "./spaFacilities#TheSalon",
    cName: "submenu-item",
  },

  {
    id: 3,
    title: "Spa brands",
    path: "./spaFacilities#Spabrands",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Spa etiquette",
    path: "./spaFacilities#Spaetiquette",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "Spa inquiry",
    path: "./spaFacilities#Spainquiry",
    cName: "submenu-item",
  },
  {
    id: 6,
    title: "Yoga",
    path: "./Ashram#Yoga",
    cName: "submenu-item",
  },
  {
    id: 7,
    title: "Meditation",
    path: "./Ashram#Meditation",
    cName: "submenu-item",
  },
  {
    id: 8,
    title: "Temporary Ordination",
    path: "./Ashram#TemporaryOrdination",
    cName: "submenu-item",
  },
  {
    id: 9,
    title: "Healthy Living",
    path: "./Ashram#HealthyLiving",
    cName: "submenu-item",
  },
  // {
  //   id: 10,
  //   title: "Ayurveda",
  //   path: "./Wellness",
  //   cName: "submenu-item",
  // },
  {
    id: 11,
    title: "Recovery",
    path: "./Wellness#Recovery",
    cName: "submenu-item",
  },
  {
    id: 12,
    title: "Detox",
    path: "./Wellness#Detox",
    cName: "submenu-item",
  },
  {
    id: 13,
    title: "Anti-aging",
    path: "./Wellness#Antiaging",
    cName: "submenu-item",
  },
  {
    id: 14,
    title: "Weight loss ",
    path: "./Wellness#Weightloss",
    cName: "submenu-item",
  },
  {
    id: 15,
    title: "Sleep ",
    path: "./Wellness#Sleep",
    cName: "submenu-item",
  },
  {
    id: 16,
    title: "Stress Management ",
    path: "./Wellness#StressManagement",
    cName: "submenu-item",
  },
  // {
  //   id: 17,
  //   title: "Rebalancing",
  //   path: "./Wellness#Rebalancing",
  //   cName: "submenu-item",
  // },
];
export const theWeddingEventDropdown = [
  {
    id: 1,
    title: "Destination Weddings",
    path: "./DestinationWeddings",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "Honeymoons",
    path: "./Honeymoons",
    cName: "submenu-item",
  },

  {
    id: 3,
    title: "Conferences & meetings",
    path: "./Conferences",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Birthdays & Celebrations ",
    path: "./Birthdays",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "Department of Romance",
    path: "./DepartmentofRomance",
    cName: "submenu-item",
  },
  {
    id: 6,
    title: "Department of Happiness ",
    path: "./DepartmentofHappiness",
    cName: "submenu-item",
  },
];
export const theBoutiquesDropdown = [
  {
    id: 1,
    title: "Aviyana Farm House",
    path: "./",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "The Boutique at Aviyana",
    path: "./",
    cName: "submenu-item",
  },

  {
    id: 3,
    title: "Online shop",
    path: "./",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Aviyana Signature Products",
    path: "./",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "Gift vouchers",
    path: "./",
    cName: "submenu-item",
  },
];

export const theCountryDropdown = [
  {
    id: 1,
    title: "Sri lanka",
    path: "./",
  },
  {
    id: 2,
    title: "Afghanistan",
    path: "./",
  },

  {
    id: 3,
    title: "Albania",
    path: "./",
  },
  {
    id: 4,
    title: "Austria",
    path: "./",
  },
];

export const getawayCollection = [
  {
    id: 1,
    title: "One night, gourmet getway",
    path: "./",
    img: gatewayImg1,
  },
  {
    id: 2,
    title: "Two night, gourmet getaway",
    path: "./",
    img: gatewayImg2,
  },

  {
    id: 3,
    title: "Acosy night in at the movies",
    path: "./",
    img: gatewayImg3,
  },
  {
    id: 4,
    title: "A taste of Ireland's fines cuisine",
    path: "./",
    img: gatewayImg4,
  },
];
