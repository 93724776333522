import React, { useState } from "react";
import { Link } from "react-router-dom";
import { theBoutiquesDropdown } from "./NavItems";
import "./Boutiques.css";

function Boutiques() {
  const [dropdown, setDropdown] = useState(false); // create state
  const redirectToPage = (a) => {
    window.location = a;
  };
  return (
    <>
      <div className="chalet-subnav-bar-sec">
        <div className="chaler-subnav-bar">
          <div className="chaler-list-sec">
            <ul className="chaler-subnav-list row">
              {theBoutiquesDropdown.map((item) => {
                return (
                  <li key={item.id}>
                    <Link
                      // to={item.path}
                      className="chaler-subnav-item col"
                      // onClick={() => redirectToPage(item.path)}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Boutiques;
