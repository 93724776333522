import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Sports.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/sports/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/sports/Box-2.png";
import aboutHotelimg3 from "../../images/Selected/sports/Box-3.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/sports/Box-4.png";
import aboutHotelimg5 from "../../images/Selected/sports/Box-5.png"; //chnage the img
import aboutHotelimg6 from "../../images/Selected/sports/Box-6.png";
import aboutHotelimg7 from "../../images/Selected/sports/Box-7.png";

import explorer from "../../images/Selected/experiences/Air Balloon.png";

import videobg from "../../images/Selected/HomeVideos/exp/Sport.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Sports() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="sportsHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content ">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  The only thing that’s impossible is what you say is.
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  To ascend to the skies; it has fuelled the imagination of even
                  the earliest humans, so much so that to be able to do so was
                  considered a divine quality.
                  <br />
                  <p></p>It took many centuries before we could finally achieve
                  this, but more fun deviations from its original purpose did
                  not take long to follow. Air sports have been around for a
                  long time, ever since the birth of the modern parachute and
                  later activities such as hang gliding. Today, the options are
                  many.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="sports-header-img-sec1"></div>

        {/* des sec start */}
        <div className="space mt-2 mb-5" id="Skydiving"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Skydiving
          "
          des1="Aviyana skydiving is ideal for first timers to experience a thrilling, adrenaline-fuelled sport in the safest of environments.
          Guests play an active part in the jump, descending by freefall and parachute, with a seasoned instructor connected from
          the leap to the landing."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="Paragliding"></div>

        <AboutHotel2
          img={aboutHotelimg2}
          header="Paragliding"
          des1="An absolute novel experience not to be missed in the Knuckles mountains! Make the trip at sunrise and be stunned with the vistas. The only drawback is
          that you have to get up early. But once in the air, you will surely see that it was all worth it."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5" id="HotAir"></div>

        <AboutHotel
          img={aboutHotelimg7}
          header="Hot Air Ballooning"
          des1="Let us take you up and away in as you enjoy an experience of a lifetime, drifting over Sri Lanka’s breathaking and majestic landscape. The intoxicating ride will take you through rugged mountains, lakes, rivers, cultural attractions and tree top canopies. Let the excitement grow as your pilot lets you touch treetops, close-up on a bird or watch an animal walking. You are left in complete silence to indulge in the surroundings in peace and serenity and the experience of a lifetime."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5" id="Ziplining"></div>

        <AboutHotel2
          img={aboutHotelimg3}
          header="Zip-lining & Tree climbing
          "
          des1="Several sites are available depending on your physical level and pace. The activities offer intense physical exercise with small climbing passages,
          suspension bridges, ladders, zip lines, tree climbing tacks for aerial games. Experience Sri Lanka’s first-ever mega zip-line and take in a bird’s-eye view of
          the beautiful hills of the island."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="RockClimbing"></div>

        <AboutHotel
          img={aboutHotelimg4}
          header="Rock Climbing
          "
          des1="Aviyana’s impressive mountains and amazing rocks offer climbers an exhilarating experience. We take you to the heights of excitement and make your
          climbing experience a memorable one, regardless of whether you are an amateur or professional. Safety is guaranteed with our dedicated professional
          guides who who pay detailed attention to customer safety and use high-quality equipment that meets international standards.
          "
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5" id="Canoeing"></div>

        <AboutHotel2
          img={aboutHotelimg5}
          header="Canoeing Victoria Lake
          "
          des1="Aviyana is a dream destination for paddlers who want to discover the area by canoe. The gentle waves of the lake will send you along on your journey of discovery. There is also plenty
          for experienced canoers to enjoy. Beginners receive practical tips on handling their canoe while experienced canoers explore the lake under their own steam."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="Fitness"></div>

        <AboutHotel
          img={aboutHotelimg6}
          header="Fitness Centre"
          des1="Need to get your steps in before your day or maintain your regular fitness routine when you’re staying at our hotel? Our fitness centre is fully
          equipped to cater to your requirements and our trainers are happy to assist you on your wellness journey. Our cardio section offers dramatic views
          of the Indian Ocean while our weight room is fitted with all the necessary equipment, you’ll need during your stay with us. "
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="sports-header-img-sec2"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Sports;
