import React from "react";
import { useRef } from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./DepartmentofHappiness.css";
import AboutHotelWithBtn from "../../components/AboutHotelWithBtn";
import AboutHotelWithBtn2 from "../../components/AboutHotelWithBtn2";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Wedding/DepOfHappy/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Wedding/DepOfHappy/Box-2.png";

import explorer from "../../images/Selected/Wedding/DepOfHappy/Happy.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/wedding/DepOfHappy.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function checkScreen() {
  if (window.innerWidth > 1200) {
    document.getElementById("mainDivSec").style.className =
      "container mt-5 mb-5";
  } else {
    document.getElementById("mainDivSec").style.className =
      "container-fluid mt-5 mb-5";
  }
}

function DepartmentofHappiness() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);
  return (
    <>
      <div className="DestinationHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 ">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 ">
                  Moments of happiness can be fleeting, takes us by surprise but
                  remains with us!
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 ">
                  We have created a positive environment at Aviyana where our
                  Happy staff make for happy guests. It's a simple concept but
                  we continuously look for different ways to make our guests
                  feel special but also ensure our staff are happy to spread
                  cheer.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Spafacilities"></div>
        <AboutHotelWithBtn
          img={aboutHotelimg1}
          header="Department of Happiness
          "
          des1=""
          des2=" 
          "
          des3=""
          linkVisible={false}
          link="/"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="TheSalon"></div>
        <AboutHotelWithBtn2
          img={aboutHotelimg2}
          header="Department of Happiness"
          des1=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="Spabrands"></div>

        {/* des sec end */}

        <div className="Happy-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default DepartmentofHappiness;
