import React, { useState } from "react";
import { Link } from "react-router-dom";
import { theWeddingEventDropdown } from "./NavItems";
import "./WeddingDropdown.css";

function WeddingDropdown() {
  const [dropdown, setDropdown] = useState(false); // create state

  const redirectToPage = (a) => {
    window.location = a;
  };
  return (
    <>
      <div className="chalet-subnav-bar-sec">
        <div className="chaler-subnav-bar">
          <div className="chaler-list-sec">
            <ul className="chaler-subnav-list row">
              {theWeddingEventDropdown.map((item) => {
                return (
                  <li key={item.id}>
                    <Link
                      to={item.path}
                      className="chaler-subnav-item col"
                      onClick={() => redirectToPage(item.path)}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default WeddingDropdown;
