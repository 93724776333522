import React from "react";
import { useRef } from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./DepartmentofRomance.css";
import AboutHotelWithBtn from "../../components/AboutHotelWithBtn";
import AboutHotelWithBtn2 from "../../components/AboutHotelWithBtn2";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Wedding/DepOfRomance/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Wedding/DepOfRomance/Box-2.png";

import explorer from "../../images/Selected/Wedding/DepOfRomance/Romance.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/wedding/DepOfRomance.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function checkScreen() {
  if (window.innerWidth > 1200) {
    document.getElementById("mainDivSec").style.className =
      "container mt-5 mb-5";
  } else {
    document.getElementById("mainDivSec").style.className =
      "container-fluid mt-5 mb-5";
  }
}

function DepartmentofRomance() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);
  return (
    <>
      <div className="DestinationHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 ">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 ">
                  There is an irresistible magic at the Aviyana
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 ">
                  love is the best thing we do, embodied in the intimate
                  ambiance and personalized service we offer. We know how
                  important love, trust and passion can be in relationships, and
                  how much taking time off to bond and rekindle relationships
                  mean. At Aviyana, we take great pride and care in providing
                  relaxing retreats and soothing respites for meaningful
                  sojourns and soul enriching experiences for you and your loved
                  one.
                  <p></p>Plan getaways and escapades with us, honeymoons, stage
                  unique marriage proposals, a private candlelit dinner at the
                  water's edge, are a few from the many experiences that we can
                  offer. Explore promotional packages to help you create
                  memories that will last a lifetime.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Spafacilities"></div>
        <AboutHotelWithBtn
          img={aboutHotelimg1}
          header="Love is the best thing we do.
          "
          des1=""
          des2=" 
          "
          des3=""
          linkVisible={false}
          link="/"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="TheSalon"></div>
        <AboutHotelWithBtn2
          img={aboutHotelimg2}
          header="Love is the best thing we do."
          des1=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="Spabrands"></div>

        {/* des sec end */}

        <div className="DepOfRomance-header-img-sec1 "></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default DepartmentofRomance;
