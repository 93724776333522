import React from "react";
import Slider from "react-slick";

import "../Styles/ImageSliderStyles.css";

import { BsArrowRight } from "react-icons/bs";
import "./OurteamSlider.css";

//animation
import { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import { useRef } from "react";

function OurteamSlider(props) {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const sliderRef = useRef(null);

  const settings = {
    ref: sliderRef,
    autoplay: false,
    autoplaySpeed: 2000,
    dots: false,
    draggable: true,
    arrows: false,

    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          raggable: true,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          raggable: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          centerMode: false,
          raggable: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="container-md mt-5 mb-5">
      <div data-aos="fade-up" className="header mb-5">
        <h1>{props.header} </h1>
        {/* <a href={props.link} className={props.value ? "link" : "hide"}>
          {props.linkName}
          <BsArrowRight className="link-arrow" />
        </a> */}
      </div>

      <div className="row  d-flex justify-content-between">
        <div className="MemberCard ">
          <div className="ourTeam-sec row">
            <Slider {...settings}>
              {props.arrayName.map((obj) => (
                <div
                  data-aos="fade-up"
                  className="Member-sec col-xl-4 col-lg-4 col-md-4 col-sm-12"
                >
                  <div className="teamMemberImg">
                    <img src={obj.imgSrc} alt="" />
                  </div>
                  <div className="date-team">
                    <p className="date">{obj.date}</p>
                    <p className="team">{obj.team}</p>
                  </div>
                  <div className="content ">
                    <h1 className="header2">{obj.title}</h1>
                    <p className="Tdesc ">{obj.description}</p>
                    <a href={obj.link} className="link11">
                      {props.linkName2}
                      <BsArrowRight className="link-arrow" />
                    </a>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurteamSlider;
