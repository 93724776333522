import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./SpecialService.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Box-51.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Box-52.png";
import aboutHotelimg3 from "../../images/Selected/Box-53.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/Box-54.png";

import explorer from "../../images/Selected/Special Service.png";
import videobg from "../../images/Selected/HomeVideos/Special.m4v";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function SpecialService() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="SpecialService-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 mt-5 pt-5">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address">KANDY, SRI LANKA</p>
              </div>
            </div>
          </div>
        </div>

        {/* des sec start */}
        <div className="space mt-2 mb-5" id="BulterService"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Everything a call away…
          "
          des1="No request is too small or unattainable no matter what the hour of the day. Our Butler Service ensures that unique preferences are
          known and addressed flawlessly. From beverage service, unpacking and packing, garment pressing, or anything else you may desire,
          service is accessible at any hour. Your butler will make sure you have everything you need to settle in. For now though, it is time to
          relax, get to know the Aviyana and relish its surroundings. If you need anything, just call."
          des2=" "
          des3=""
          linkVisible={true}
          link="/"
          linkName="Enquiry Now…"
        />
        <div className="space mt-5 mb-5" id="ElectricCar"></div>

        <AboutHotel2
          img={aboutHotelimg2}
          header="On-site Electric car Operation"
          des1=""
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5" id="Helicopter"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="On-site Helicopter
          "
          des1="Enjoy the stunning Knuckles landscape and admire our nation from a novel perspective with this exclusive helicopter trip. Airport to Hotel
          – Guests will be whisked away from the Airport and enjoy the unique opportunity of taking a XX-minute helicopter flip to the Resorts s
          private helistop, while overflying spectacular scenery. Hotel to anywhere – Soar over the skies of Sri Lanka taking in breathtaking scenery,
          visit attractions across the island – all on board your private shuttle. Speak to us and we will make the necessary arrangements.
          "
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Enquiry Now…"
        />
        <div className="space mt-5 mb-5" id="AviyanaArm"></div>

        <AboutHotel2
          img={aboutHotelimg4}
          header="Aviyana Arm"
          des1="We can pick from any place in the world with any demands and drop to any
          place with your demands Pvt jets, Helicopters, Luxurious Vehicles"
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}
        <div className="specialService-header-img-sec1"></div>
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default SpecialService;
