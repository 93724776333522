import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Bars.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/drinking/box-7.jpg"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/drinking/box-8.jpg";
import aboutHotelimg3 from "../../images/Selected/drinking/box-9.jpg"; //chnage the img

import explorer from "../../images/Selected/drinking/barIcon.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/dinning/Bar.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Bars() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="BarsHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 mt-5 pt-5">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address">KANDY, SRI LANKA</p>

                <h1
                  data-aos="fade-up"
                  className="hotel-semi-title1 mt-4 mb-4"
                  id="TheAviyanaSkyBar"
                >
                  The Aviyana Sky Bar
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Our bar has an extensive drinks menu which includes an
                  impressive selection of classic cocktails and creative
                  signature mixes, liqueurs and brandies. Our experienced bar
                  team also offer private whiskey and gin tasting experiences.
                  <br />
                  <p></p> Our master mixologist is inspired by his surroundings
                  to create the next best thing. Using gold, gem and other
                  ingredients that grow in our carefully tended gardens, our
                  cocktails and mocktails are refreshing and delectable. The
                  Gold cocktail, our signature cocktail is unique, a masterpiece
                  from our own bartender.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="TheBilliardsRoom"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="The Billiards Room & Cigar Terrace 
          "
          des1="Retreat to the Billiards Room and enjoy a game of billiards, sink into comfy chairs with a glass of your favourite tipple or enjoy light snacks. Relax with a newspaper
          and catch up on the day's affairs. With an impressive selection of beverages, including a selection of finest single pot still whiskey, it is the perfect quiet spot to
          enjoy an afternoon tipple or retreat for a digestif after dinner. "
          des2=" Enthusiastic cigar connoisseurs will be delighted with our limited editions cigar collection which can be enjoyed in the adjoining Cigar Terrace. Cigar Terrace with
          fantastic views overlooking the mountains and beyond is a beautifully designed space with a flagstone floor. The terrace offers the ultimate experience for cigar
          aficionados and novices alike to relax, enjoy a whiskey, cognac or cigar, or simply savour the exceptional setting."
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5" id="Winetasting"></div>
        <AboutHotel2
          img={aboutHotelimg2}
          header="Wine tasting"
          des1="Taste and discover what our wines are about and awaken your senses in style. We offer refined wine tasting where the art of traditional wine tasting can be experienced with
          absolute finesse. Paired with sumptuous tapas, cheeses, cold olives, Spanish delicacies and a variety of appetizers, your wine of choice will take your taste buds on a delightful
          journey while you get acquainted with the depth and variety of our wines"
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5" id="SriLankanSpecialBrew"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Sri Lankan Special Brew 

          "
          des1="Toddies and hot toddies are distinct and iconic Sri Lankan drinks made with sweet sap tapped straight from the stems and flowers of a mature toddy palms.
          The sap can be drunk fresh, or it can be boiled down to form a kind of brown sugar called jaggery, a key ingredient in many Southeast and South Asian
          sweets. Guests can try Palmyrah Toddy, Fermented Pasteurized, or Sweet Pasteurized Toddy, perhaps for the first time. A large array of exciting Sri Lankan
          snacks such as cheese and egg roti, parata, issowade, umbalakada wade kottu and devilled bites await guests who wish to enjoy local comfort food with a
          spicy twist."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="bars-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Bars;
