import React from "react";
import "../pages/Dinning.css";
import AboutHotel from "../components/AboutHotel";
import Footer from "../components/Footer";
import StayInTouch from "../components/StayInTouch";
import EXperinceImgSlider from "../components/EXperinceImgSlider";
import videobg from "../images/Selected/drinking/home/DinningHome.m4v";
import { BsArrowRight } from "react-icons/bs";

import { ExGallary2, ExGallary3 } from "../components/SliderData";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import ExploreBars from "../components/ExploreBars";

import aviyanaNameTag from "../images/aviyanaNameTag.png";
import explorer from "../images/Selected/drinking/DINNINGDRINKS.png";
import aboutHotelimg1 from "../images/Selected/drinking/home/Box-1.png";

import bar1 from "../images/Selected/drinking/home/Box-2.png";
import bar2 from "../images/Selected/drinking/home/Box-3.png";
import bar3 from "../images/Selected/drinking/home/Box-4.png";
import bar4 from "../images/Selected/drinking/home/Box-5.png";

import NewCheckDate from "../components/NewCheckDate.js";

function Dinning() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="dinning-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />
        <div className="Header-Content1 mt-5 pt-5">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Embark on an unforgettable culinary journey
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-5">
                  Discover creative Sri Lankan cuisine inspired by the finest
                  seasonal ingredients from the Sri Lanka and produce foraged
                  from the Aviyana Estate.
                  <br />
                  <p></p>Sri Lanka is renowned for its cuisine, and Aviyana
                  celebrates its variety with a full menu of authentic dishes
                  complemented by Asian and Mediterranean classics. Three
                  restaurants are open to both guests and non-residents, and
                  unique culinary experiences can be enjoyed in the surrounding
                  villages and hillside.
                  <p></p>
                </p>
              </div>
            </div>
          </div>
        </div>

        <AboutHotel
          img={aboutHotelimg1}
          header="Bars & restaurants 
          "
          des1="We believe strongly in sourcing fresh produce from our local
          producers, as well as growing much of our ingredients in our own
          gardens and estate. These are transformed into delicious works of art
          in our two restaurants and bars, at the skilled hands of wonderful team."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <EXperinceImgSlider header="" arrayName={ExGallary2} />
        <div className="explore-middle-header mt-5 mb-5">
          <p className="mt-5"></p>
        </div>
        <ExploreBars
          arrowVisible1={true}
          BarImg1={bar1}
          header1="What’s on"
          des1="Your day at Aviyana begins with the wonderful tradition of Bed Tea,
          served by your butler as part of a gentle wake-up call - the most civilised
          way to start the day. On request the butler will also draw your bath. "
          linkName1="Explore more"
          link1="/"
          arrowVisible2={true}
          BarImg2={bar2}
          header2="Breakfast"
          des2="Escape the morning mists and the beautiful surrounding of the
          Flower Dew cottage garden to enjoy a scrumptious garden breakfast
          to remember!"
          linkName2="Explore more"
          link2="/"
        />

        <ExploreBars
          arrowVisible1={true}
          BarImg1={bar3}
          header1="Afternoon Tea"
          des1="Afternoon Tea is a quintessentially English tradition and is
          upheld in its finest form at the Aviyana."
          linkName1="Explore more"
          link1="/"
          arrowVisible2={true}
          BarImg2={bar4}
          header2="Private Dining"
          des2="Celebrate those special moment with private dining in
          multiple settings on and off the property as well as in-suite. 
          "
          linkName2="Explore more"
          link2="/"
        />

        <EXperinceImgSlider header="" arrayName={ExGallary3} />

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Dinning;
