import React from "react";

import "./Agree.css";

const Agreed = () => {
  document.getElementById("agreeSec").style.visibility = "hidden";
};

function Agree() {
  return (
    <>
      <div className="terms-agree-sec" id="agreeSec">
        <div className="container">
          <div className="agree-term">
            <div className="agree-des">
              <p>
                This website uses cookies. We use cookies to analyse our
                traffic, enhance our website’s functionality and performance and
                better experience for our users. If you require more information
                please view our cookie policy.
              </p>
            </div>
            <div className="agree-btn-sec">
              <button className="agree-btn" onClick={() => Agreed()}>
                AGREE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Agree;
