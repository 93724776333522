import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./DiningAndDrinks.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/drinking/box-1.jpg"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/drinking/box-2.jpg";
import aboutHotelimg3 from "../../images/Selected/drinking/box-3.jpg"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/drinking/box-4.jpg";
import aboutHotelimg5 from "../../images/Selected/drinking/box-5.jpg"; //chnage the img
import aboutHotelimg6 from "../../images/Selected/drinking/box-6.jpg";

import explorer from "../../images/Selected/drinking/DINNINGDRINKS.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/dinning/DiningDrinks.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function DiningAndDrinks() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="DiningAndDrinksHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content ">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Embark on an unforgettable culinary journey
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Discover creative Sri Lankan cuisine inspired by the finest
                  seasonal ingredients from the Sri Lanka and produce foraged
                  from the Aviyana Estate.
                  <br />
                  <p></p>Sri Lanka is renowned for its cuisine, and Aviyana
                  celebrates its variety with a full menu of authentic dishes
                  complemented by Asian and Mediterranean classics. Three
                  restaurants are open to both guests and non-residents, and
                  unique culinary experiences can be enjoyed in the surrounding
                  villages and hillside.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="diningAndDrink-header-img-sec1"></div>

        <div className="Header-Content ">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  What's on
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Your day at Aviyana begins with the wonderful tradition of Bed
                  Tea, served by your butler as part of a gentle wake-up call -
                  the most civilised way to start the day. On request the butler
                  will also draw your bath.
                  <br />
                  <p></p>An early morning walk followed by an English or Sri
                  Lankan breakfast. A truly unique aspect of Aviyana’s cuisine
                  is the use of fresh, homegrown vegetables, herbs and teas from
                  the surrounding estates. Basil, rosemary, thyme, tarragon,
                  sage, parsley and mint inspire and enliven the chef’s menus
                  daily. A fabulously aromatic, authentic Sri Lankan rice and
                  curry for lunch – a definite harbinger of the afternoon
                  siesta!
                  <p></p>
                  <p>
                    A five-course dinner awaits with garden-fresh ingredients
                    married with choice wines, best shared in good company. Our
                    menus are constantly changing, and no two meals are alike -
                    each a visual and gastronomical representation and
                    celebration of seasonal fresh flavours, inspired by the
                    natural surroundings, as well as the freshness of local
                    produce.Rates at Aviyana are fully inclusive of gourmet
                    meals and drinks, including a selection of top range spirits
                    and wines.
                  </p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="diningAndDrink-header-img-sec2"></div>

        <div className="Header-Content " id="Breakfast">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Breakfast
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Escape the morning mists and the beautiful surrounding of the
                  Flower Dew cottage garden to enjoy a scrumptious garden
                  breakfast to remember! With the Aviyana breakfast option,
                  guests are immersed into traditional Sri Lankan cuisine and
                  International cuisine. The menu is designed for the traveller
                  who is curious about authentic local fare and is not shy about
                  being ravenous or adventurous. On offer is a taste bud-teasing
                  spectrum of savoury, spicy, and sweet flavours. Our chefs will
                  even introduce select items on the menu showcasing our local
                  culture.
                  <br />
                  <p></p>For the health conscious, based upon your health goals
                  and physical condition, instead of a printed menu, Aviyana’s
                  experienced, internationally trained chefs will prepare your
                  dishes according to a custom meal plan created in
                  co-ordination with the butler and Aviyana’s in-house Ayurvedic
                  doctor.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="diningAndDrink-header-img-sec3"></div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="SriLankanKing"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Sri Lankan King Meal (The Rajasinghe Menu)
          "
          des1="The resort’s restaurants set the bar for fine dining in Sri Lanka. From elevated clifftop locations with panoramic mountain views, an intimate, 'chef's table' style dining experience
          to a traditional heritage menu, every dining experience honours the deep roots connecting Aviyana to our island’s rich history"
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5" id="AfternoonTea"></div>
        <AboutHotel2
          img={aboutHotelimg2}
          header="Afternoon Tea"
          des1="Afternoon Tea is a quintessentially English tradition and is upheld in its finest form at the Aviyana. Take your seat in luxurious surroundings and choose from a range of
          local Ceylon or fruit teas, savour delicate cakes and dainty savoury morsels and enjoy the beautiful sunset."
          des2="Lush views of the tea country make way for an ideal setting for afternoon tea as you can soak in the atmosphere and reconnect over tea and scrumptious treats.
          High tea with lush fauna and flora as a backdrop? We take the art of wilderness dining to a new level with our high-tea experience in the jungle. Our team will pick a
          beautiful spot in perfect harmony with the surrounding environment, where guests can indulge in some exquisite creations from our pastry kitchen."
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5" id="PrivateDinning"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Private Dining 
          "
          des1="Celebrate those special moment with private dining in multiple settings on and off the property as well as in-suite.
          Relax hillside while you enjoy a Sri Lankan feast in a local home, or picnic on the Aviyana estate, surrounded by spectacular scenery amidst mountains and clouds. "
          des2="Our private dining venues feature uniquely designed spaces, with layouts suitable for corporate meetings and special occasions. From hosting a registration, baby shower,
          engagement party or business meeting, we have options to suit your individual needs.
          Set in the tradition of famous wine room concepts world over, it’s a unique experience to enjoy private dining . It offers an elegant setting with one small or large table.
          Such sensory stimuli seek to set the mood to enjoy Italian specials, premium liquor, fine wines and a selection of delectable desserts in an ideal context of total relaxation."
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Sundowners"></div>
        <AboutHotel2
          img={aboutHotelimg4}
          header="Sundowners
          "
          des1="We have the perfect cocktail for the occasion. Our daily sundowners are inspired by the natural surroundings and the evocative glow of twilight. Each of our
          cocktails are an amalgamation of hyperlocal ingredients, creative ingenuity and sheer passion for artistry. From the tangy sourness of tamarind to the high
          temperature emanating from scotch bonnets (locally known as kochchi), sweet king coconut water to fruity calamansi (locally known as narang), spicy green
          chillies and Indian gooseberries combined. "
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5" id="AviyanaWineCollection"></div>
        <AboutHotel
          img={aboutHotelimg5}
          header="Aviyana Wine Collection
          "
          des1="Discover a new level of wine-tasting excellence with our extensive wine collection carefully curated by our knowledgeable Head Butler. Explore the world’s famous
          wine routes in one place by sampling our fine selection of vintages and a choice of new and old-world wines in a remarkable setting.
          Our dedicated team will assist you with every step of your wine experience. Food pairing can be provided alongside the tastings, to complement the wine
          selection, as well as entertaining with an intimate private dinner, perfect to celebrate all occasions. Our Chefs and Sommeliers work together to bring you the best
          combination."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5" id="CookingLessons"></div>

        <AboutHotel2
          img={aboutHotelimg6}
          header="Cooking Lessons - Learn, cook and indulge
          "
          des1="Enjoy an authentic culinary journey into the heart of Sri Lankan cuisine. Personalized cooking classes given by our Chefs enables you to have the opportunity to experience and
          savour traditional Sri Lankan dishes at its best. Gather the sunny vegetables and herbs you will need to concoct the magic of Sri Lankan cuisine. Learn the basics from the range
          of spices and ingredients to techniques and recipes. Hand-on experience to mix spices, prepare and cooking will immerse you in a fun and wonderful sensory experience and an
          ideal prelude to a full Sri Lankan dinner the same night."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="diningAndDrink-header-img-sec4"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default DiningAndDrinks;
