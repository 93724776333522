import React from "react";
import { useRef } from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Honeymoons.css";
import AboutHotelWithBtn from "../../components/AboutHotelWithBtn";
import AboutHotelWithBtn2 from "../../components/AboutHotelWithBtn2";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Wedding/Honeymoons/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Wedding/Honeymoons/Box-2.png";

import explorer from "../../images/Selected/Wedding/Honeymoons/Honemoon.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/wedding/Honeymoons.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function checkScreen() {
  if (window.innerWidth > 1200) {
    document.getElementById("mainDivSec").style.className =
      "container mt-5 mb-5";
  } else {
    document.getElementById("mainDivSec").style.className =
      "container-fluid mt-5 mb-5";
  }
}

function Honeymoons() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);
  return (
    <>
      <div className="DestinationHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 ">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 ">
                  Looking for a memorable honeymoon and an unforgettable
                  romantic retreat?
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 ">
                  The best place to be is high in the hills. Our immaculately
                  chalets are the ideal venue to explore the local area and
                  enjoy a spot of romance!
                  <p></p> Each chalet has state-of-the-art spring-fed pools in
                  their grounds with idyllic views of the surrounding mountains
                  and an own butler service, catering to your every romantic
                  whim. From Aviyana, you can take a hike up to a quiet scenic
                  spot for a fully serviced picnic lunch, will make you believe
                  that you have just discovered paradise.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Spafacilities"></div>
        <AboutHotelWithBtn
          img={aboutHotelimg1}
          header="Luxurious Comfort
          "
          des1="The Aviyana is made up of several beautifully crafted chalets tucked within the levelled landscape of the mountain, making it one of the most sought-after honeymoon hotels in Sri Lanka. There is ample space between the chalets to offer your ultimate seclusion making it ideal for a quiet holiday of pure relaxation; for honeymooners or even for families. Each chalet offers scenic views of the misty mountains and is equipped with all modern amenities that ensure a luxurious, comfortable stay."
          des2=" 
          "
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="TheSalon"></div>
        <AboutHotelWithBtn2
          img={aboutHotelimg2}
          header="Curate your Dream"
          des1="If your dream honeymoon involves both adventure and intrigue, the Aviyana and its surrounding areas are the perfect playground. The serene waters of the neighbouring River and Victoria Lake lend themselves to boat trips, lake cruising, kayaking, paddle boarding, and shing. The verdant woodlands form the backdrop for many activities including walking, hiking, horse riding, tree climbing, zip-lining, and even falconry. Honeymooners looking to inject some healthy competition into their stay are more than welcome to use our air sports bay and other facilities.
          We also provide plenty of opportunities for those seeking authentic cultural immersion: Sri Lankan food, indigenous craft, music and story, and the real Connemara. After a busy day exploring all the Aviyana has to offer, a cosy night can be enjoyed in our private cinema.
          "
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="Spabrands"></div>

        {/* des sec end */}

        <div className="Honemoon-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Honeymoons;
