import React from "react";
import "../pages/Wedding.css";
import CheckDate from "../components/CheckDate";
import Footer from "../components/Footer";
import StayInTouch from "../components/StayInTouch";
import IntroHeader from "../components/IntroHeader";
import AboutHotelWithBtn from "../components/AboutHotelWithBtn";
import AboutHotelWithBtn2 from "../components/AboutHotelWithBtn2";
import VirtualTour from "../components/VirtualTour";
import ExploreBars from "../components/ExploreBars";
import EXperinceImgSlider from "../components/EXperinceImgSlider";
import { WeddingGallary } from "../components/SliderData";
import Button from "../components/Button";

import bgImg from "../images/tourImage.webp";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { useEffect } from "react";
import { useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NewCheckDate from "../components/NewCheckDate.js";
import videobg from "../images/Selected/HomeVideos/weddingHome.m4v";
import aviyanaNameTag from "../images/aviyanaNameTag.png";
import explorer from "../images/Selected/Wedding/home/weddingIcon.png";

import aboutHotelimg1 from "../images/Selected/Wedding/home/Box-1.png";
import bar1 from "../images/Selected/Wedding/home/Box-2.png";
import bar2 from "../images/Selected/Wedding/home/Box-3.png";

function Wedding() {
  function checkScreen() {
    if (window.innerWidth > 1200) {
      document.getElementById("mainDivSec").style.className =
        "container mt-5 mb-5";
    } else {
      document.getElementById("mainDivSec").style.className =
        "container-fluid mt-5 mb-5";
    }
  }

  const animRef1 = useRef(null);

  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);
  return (
    <>
      <div className="home-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />

              {/* <h1>AVIYANA</h1> */}
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
              {/* <p>
                Leave the cares of the world behind, and reconnect with nature,
                rejoicing in a place where the only sound is the calming rhythm
                of Sri Lanka’s most spectacular Knuckles Mountain range.
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 mt-5 pt-5">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Your fairytale celebration
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-5">
                  Romantic, timeless and magical weddings in the magical setting
                  of Aviyana.
                  <br />
                  <p></p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Weddinghome-header-img-sec1"></div>

        <div
          onLoad={checkScreen}
          className="container mt-5 mb-5"
          id="mainDivSec"
        >
          <div className="row about-content">
            <div className="about-hotel-bar">
              <div className="txt-area col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div
                  data-aos="fade-up" //text animation
                  className="txt-sec "
                >
                  <h1 className="header-welcome ">Intimate weddings</h1>
                  <p className="desc-chapter-11 row">
                    For intimate weddings, the beautiful and serene surroundings
                    of Aviyana are perfectly suited to small celebrations as
                    well as large lavish affairs. Whether you are opting for a
                    celebration for just the two of you together, or with your
                    nearest and dearest, our dedicated staff will make sure your
                    special moment together is everything you could wish for.
                  </p>
                  <p className="desc-chapter-22">
                    <ul>
                      <li>Blessings</li>
                      <li>Vow renewals</li>
                      <li>
                        Elegant chalets rooms are perfect for up to 240 guests
                      </li>
                      <li>
                        Beautiful rooms and bridal suites, or opt for the
                        Hideaway Chalets
                      </li>
                    </ul>
                  </p>
                  <p className="desc-chapter-22"></p>
                  <a
                    className="link111"
                    // href={props.link}
                  ></a>
                </div>
              </div>
              <div className="img-sec col-xl-6 col-lg-12 col-md-12 col-sm-12 ">
                <div className="img-area">
                  <a className="linked-image" href="/">
                    {/* img animation */}
                    <div ref={animRef1} className="animation">
                      <img
                        className="AboutHotel-img"
                        src={aboutHotelimg1}
                        alt="castle"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Weddinghome-header-img-sec2"></div>

        <ExploreBars
          BarImg1={bar1}
          header1="Exclusive use"
          des1="Make Aviyana truly your own - you can hire the entire estate so it’s exclusively yours. The chalets
          60 bedrooms, suites a will accommodate up to 240 guests, allowing you and your wedding party to
          enjoy the full castle experience without interruption.
          Enjoy exclusive use of all restaurants and bars including the main for an unforgettable wedding
          breakfast, the underground Wine Cellars for a magical pre-wedding event or the reception in the
          heart of the castle.
          An exclusive use wedding also offers a range of unforgettable estate activities for you and your
          guests to enjoy including falconry, equestrian, air sports , river cruising, fishing and spa treatments."
          linkName1=""
          link1="/"
          BarImg2={bar2}
          header2="The ceremony"
          des2="Hold your ceremony in a outdoor nature , in the estate elegant Drawing Room or weather
          permitting, outside in the castle gardens with remarkable views over Mountains and an impressive
          Aviyana backdrop."
          linkName2=""
          link2="/"
        />

        <EXperinceImgSlider
          header="A romantic retreat"
          arrayName={WeddingGallary}
        />
        {/* <div className="call-us mt-5 mb-5">
          <div className="container">
            <div className="call-header">
              <h1>We are ready to make your dreams come true</h1>
              <p>Call +353 (0) 94 954 6003 or</p>
              <Button value="false" name="BOOK" />
            </div>
          </div>
        </div> */}
        <div className="Weddinghome-header-img-sec3 mt-5"></div>
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Wedding;
