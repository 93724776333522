import React from "react";
import CheckDate from "../../components/CheckDate";
import StayInTouch from "../../components/StayInTouch";
import IntroHeader from "../../components/IntroHeader";
import Footer from "../../components/Footer";
import "./About.css";
import NewCheckDate from "../../components/NewCheckDate.js";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function About() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="About-header">
        {/* <video autoPlay muted loop source src={videobg} /> */}
        <div className="container">
          <div data-aos="fade-up" className=" header-txt">
            <p></p>
            <h1>
              <br />
            </h1>
            <h4>
              <br />
            </h4>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />
        <div className="container">
          <div className="headerTxt mt-5 mb-5 ">
            <h1>The Aviyana</h1>
          </div>
        </div>
        <IntroHeader
          header="Restore and rebalance as you touch the heavens!"
          Detail="Leave the cares of the world behind, and reconnect with nature, rejoicing in a place where the only sound is the calming rhythm of Sri Lanka’s most spectacular
        Knuckles Mountain range. With x degree views, Aviyana Private Chalets, nestled in the picturesque hills, will help you explore your wellbeing, surrounded by timeless
        serenity. We provide you an unparalleled 7-star experience, a year-round destination to escape and feed the soul, in tangible and intangible ways."
        />

        <IntroHeader
          header="Concept origins and how did we get here?"
          Detail="Aviyana is located in the stunning famed peaks of the UNESCO declared World Heritage Site. The subtropical rainforest terrain offers a wide
        array of wildlife, natural scenery, and plants to admire. The resort is located parallel to the unceasingly flowing brooks of the Gal Mal Oya and
        Heel Oya at the foothills of Knuckles. "
        />

        <IntroHeader
          header=""
          Detail="Highly ecological and rich in biodiversity, mountain peaks, the crystal clear and perennial waterways, cloud forests and exquisite fauna and
        flora, Knuckles is replete with history running into several millennial, a veritable treasure house of cultural heritage and a mirror to the past."
        />

        <IntroHeader
          header=""
          Detail="Aviyana is majestically situated atop a towering rock with breathtaking mountain views. The first ever 7-start hotel with incomparable views of
        the Knuckles peaks. Being 2000 above sea level, offers the ultimate in rest and rejuvenation. Guest are surrounded by the purest air quality.
        The transitional state of the climate creates a spectacular experience, that is seldom found in such startling variety - where guests can
        experience mist, fog, clouds and even heaven within touching distance."
        />

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default About;
