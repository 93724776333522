import React from "react";
import "./VillaData.css";

function VillaData(props) {
  return (
    <>
      <div className="villa-bar-sec">
        <div className="villa-bar">
          <div className="row">
            <div className="villa-header pt-4 pb-4">
              <h1>{props.Header}</h1>
            </div>
            <div className="villa-des">
              <p>{props.villaDes1}</p>

              <p>{props.villaDes2}</p>
            </div>
            <div className="feature-sec">
              <div className="feature-header">
                <p>{props.featureheader}:</p>
                {props.roomFeature.map((features) => (
                  <ul className="chalet-feature-list">
                    <li className="featureItem">{features.title}</li>
                  </ul>
                ))}
              </div>
              <div className="ApproximateSize-sec">
                <p>Approximate size</p>
                <ul className="feature-list">
                  <li className="feature-item">{props.ApproximateSize}</li>
                  <div className="ApproximateSize-details">
                    <p className="approSize-detail-ite">
                      Bedroom: {props.Bedroom}{" "}
                    </p>
                    <p className="approSize-detail-ite">Hall: {props.Hall} </p>
                    <p className="approSize-detail-ite">
                      Bathroom: {props.Bathroom}{" "}
                    </p>
                  </div>
                </ul>
              </div>
            </div>
            <div className="Amenities-list-sec">
              <div className="Amenities-header">Amenities</div>
              <div className="Amenities-list">
                <div className="Amenities-col">
                  {props.AmenitiesList.map((item) => {
                    if (item.id > 0 && item.id < 11) {
                      return (
                        <div className="Amenities-list-item">
                          <li key={item.id}>{item.title}</li>
                        </div>
                      );
                    }
                  })}
                </div>

                <div className="Amenities-col">
                  {props.AmenitiesList.map((item) => {
                    if (item.id > 10 && item.id < 21) {
                      return (
                        <div className="Amenities-list-item">
                          <li key={item.id}>{item.title}</li>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="Amenities-col">
                  {props.AmenitiesList.map((item) => {
                    if (item.id > 20) {
                      return (
                        <div className="Amenities-list-item">
                          <li key={item.id}>{item.title}</li>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VillaData;
