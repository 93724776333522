import React from "react";
import "./Home.css";
import videobg from "../images/AviyanaVideo.m4v";
import { BsArrowRight } from "react-icons/bs";

import AboutHotel from "../components/AboutHotel";
import HeaderContent from "../components/HeaderContent";

import ImageGallery from "../components/ImageGallery";
import Footer from "../components/Footer";
import StayInTouch from "../components/StayInTouch";
import bgImg from "../images/homeimg3.png";
import Agree from "../components/Agree";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NewCheckDate from "../components/NewCheckDate.js";
import AboutHotel2 from "../components/AboutHotel2";
import HeadMsj from "../components/HeadMsj.js";

import castle from "../images/homeimg2.png";
import Homeimg4 from "../images/homeimg4.png";
import aboutHotelimg2 from "../images/lotus.jpg"; //chnage the img
import aboutHotelimg1 from "../images/homeimg2.png";
import aboutHotelimg3 from "../images/garden.webp";
import poolImg from "../images/pool.jpg";
import bedImg from "../images/bed.jpg";
import foodImg from "../images/food.jpg";
import juiceImg from "../images/juice.jpg";
import mapImg from "../images/Map.jpg";
import aviyanaNameTag from "../images/aviyanaNameTag.png";

function Home() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <Agree />

      <div className="home-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />

              {/* <h1>AVIYANA</h1> */}
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
              {/* <p>
                Leave the cares of the world behind, and reconnect with nature,
                rejoicing in a place where the only sound is the calming rhythm
                of Sri Lanka’s most spectacular Knuckles Mountain range.
              </p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <HeaderContent />
        <div className="home-header-img-sec1"></div>
        <div className="Header-Content pb-5">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-2 mb-4">
                  Journey at its luxurious best!
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-4">
                  Our hotel complex is a venue where you get to breathe the
                  clean and healthy air, drink crystal clear mineral water,
                  witness the best views of the world and hear the most pleasant
                  sounds made by the wildlife. <p></p>
                  The world's most talented butlers will provide the services at
                  Aviyana Hotel Complex which is provided with high security and
                  ensures the privacy of the guests to the highest.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="home-header-img-sec2"></div>
        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Restore and rebalance as you touch the
          heavens!"
          des1="Leave the cares of the world behind, and reconnect with nature, rejoicing in a place where the only sound is the calming rhythm of Sri Lanka’s most spectacular Knuckles Mountain range. With 270 degree views, Aviyana Private Chalets, nestled in the picturesque hills, will help you explore your wellbeing, surrounded by timeless serenity. We provide you an unparalleled 7-star experience, a year-round destination to escape and feed the soul, in tangible and intangible ways."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover the history of Ashford"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg2}
          header="Our Uniqueness"
          des1="Aviyana stands apart by offering a supremely serene retreat, blessed abundantly with mother natures’ scenic beauty, the ability to experience the
          purest air and the call of calm with restorative virgin water from the Knuckles Mountain range. Infusing the world of hospitality with the most
          innovative and luxurious high-end hotel, Aviyana has taken a giant leap forward for our growing industry. We offer unsurpassed levels of service, a
          taste of true luxury in spacious villas, passion for quality and detail, curating the most fascinating aspects of our locale, not just in design, but in
          experiences and excursions that are tailored to the taste of each guest. Fresh, seasonal ingredients are at the heart of dining at Aviyana, where
          they are transformed into welcoming, authentic cuisine. Guests arrive and depart traveling through sweeping mountain views from the vaulted
          vantage of a helicopter transfer. Above all resonates our ethos of providing a peaceful sanctuary, a gentle sense of joy and discovery, with a
          magical touch of luxury."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover the history of Ashford"
        />
        <div className="space mt-5 mb-5"></div>
        {/* des sec end */}
        <div className="home-header-img-sec3"></div>
        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={poolImg}
          header="Great service. Excellent emotional connection. "
          des1="Making real human connections that will allow the guests to feel at home, belonged, and fulfilled is a major focus in the Aviyana
          experience. It’s a feeling that is linked to its people, the product, and the service – a wholesome effect that touches your very soul to
          create a meaningful guest experience that is unique to the brand and the property. Aviyana’s prestigious butlers are one-of-a-kind. There’s
          one dedicated to each villa to offer a truly personalised experience that will leave your soul satisfied. Multi-talented and equipped with
          good common sense, he is more than a personal assistant to the guests. He will be familiar with your profile before you even get there
          and will go the extra mile to make you feel truly at home at Aviyana."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover the history of Ashford"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={bedImg}
          header="Exclusivity coupled with quality."
          des1="The concept of chalets not only minimizes impact on the environment but also gives its guests a more splendid experience that goes beyond the concept of bed and bath.
          Following the architectural style of Geoffrey Bawa, Aviyana has a nature-integrated design, which offers a natural open experience to its guests. However, comfort and luxury
          have not been compromised in any way; gold-coated customized bathroom ware with crystals, marble tops tables, intelligent toilet sets, branded bed linen, and finest quality
          furniture offer the perfect balance between man-made opulence and nature’s beauty. Our luxurious hot water pools designed following the natural pool concept are pure joy and
          relaxation. Google Smart Home technology ensures maximum safety and privacy in each chalet."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover the history of Ashford"
        />
        <div className="space mt-5 mb-5"></div>
        {/* des sec end */}

        <div className="home-header-img-sec5"></div>

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={foodImg}
          header="The Aviyana at a glance"
          des1="The guests can dine like royalty with an extended fine dining experience with special amenities, cutlery decorated with gemstones, and the finest food in the world
          with an exquisite local touch. Besides the 2 world-class restaurants, every chalet comes with its own chef who will cook anything the guests desire. The ‘Pick and
          Cook’ experience allows you to visit Aviyana’s very own farmlands to pluck organic vegetables, fruits, and spices with your own hands. If you wish, you can even
          fish in the surrounding streams and rivers and catch your own fish. The food picked in this manner can either be cooked by a chef or by the guests themselves.
          Aviyana is considered the first five-star hotel project with the 'pick & cook' dining concept in South Asia. They also offer the gold cocktails experience, the finest
          liquor, wine, and meat in the world, and a chocolate factory that will make your heart happy."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover the history of Ashford"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={juiceImg}
          header="Aviyana Inspires Innovations"
          des1="Aviyana reception experience is unlike anything you have experienced. The welcome you will receive at the arrival will be something
          extraordinary, a moment that will be imprinted in your memory forever. This will include a delicious welcome drink and 20 to 30 items that
          you can use during your stay including hot towels and wet towels. Aviyana is a place to heal your mind, body, and soul. With the natural
          spiritual energy of the surrounding mountains, rocks, forests, waterfalls and rivers, it’s a wellness sanctuary unlike any other in the world.
          An ashram for yoga, meditation and mindfulness training, Ayurvedic treatments for chronic diseases, and beauty therapy are some of the
          amazing wellness services offered at Aviyana. With sky games and air sports, this will also be the ultimate vacation for the thrill seekers
          and adventure lovers – one of the few hubs in South Asia for such sports."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover the history of Ashford"
        />
        <div className="space mt-5 mb-5"></div>
        {/* des sec end */}
        <div className="home-header-img-sec4"></div>
        <HeadMsj />

        <div className="map-bar-sec">
          <div className="map-bar">
            <div className="map">
              <img src={mapImg} alt="" />
            </div>
          </div>
        </div>

        <div className="innovation-bar-sec mt-5 mb-5">
          <div className="innovation-bar">
            <div className="innovation-txt-sec">
              <div className="innovation-header mb-5">
                <h1>Innovative investment model</h1>
              </div>
              <div className="innovation-des">
                <p>
                  Aviyana hotel complex is precisely the innovation needed to
                  uplift Sri Lanka’s tourism sector to its next level - a
                  much-needed move for our nation’s economic recovery and
                  sustainability. With a range of investment packages to choose
                  from, Aviyana offers local investors a chance to be part of a
                  game-changing business strategy. The concept will
                  revolutionise the industry with its outside-the-box approach
                  to hospitality, which combines spiritual well-being and
                  opulence to offer you an experience that can be life-changing.
                  By upgrading the standard 5-star category to 7-star splendour,
                  the Aviyana brand takes on a remarkable transformation to
                  produce optimal Return On Investment.
                </p>
              </div>
              <div className="innovation-link">
                <a className="link22">
                  Discover more <BsArrowRight className="link-arrow-sc-5" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <ImageGallery
          header="Share your #Aviyana story"
          description="We are always delighted to discover our guests' photos and we love to share those stories and connect in a meaningful way. Nothing
  makes us happier than when our guests become part of our world..."
        />
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Home;
