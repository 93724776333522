import React, { useState } from "react";
import "./Addrooms.css";
import * as Icons from "react-icons/fa";

function Addrooms({ changeRoomNo, changeChildNUm, changeAdultNum }) {
  const [NoOfrooms, setNoOfRooms] = useState(1);
  const [NoOfAdults, setNoOfAdults] = useState(1);
  const [NoOfChild, setNoOfChild] = useState(0);

  const addingRooms = () => {
    if (NoOfrooms < 20) {
      var Num = NoOfrooms + 1;
      changeRoomNo(Num);
      setNoOfRooms(Num);
    } else if (NoOfrooms >= 20) {
      setNoOfRooms(20);
      changeRoomNo(NoOfrooms);
    }
  };

  const minimizingRooms = () => {
    if (NoOfrooms > 1) {
      var Num = NoOfrooms - 1;
      setNoOfRooms(Num);
      changeRoomNo(Num);
    } else if (NoOfrooms == 1) {
      setNoOfRooms(1);
      changeRoomNo(NoOfrooms);
    }
  };
  const addingAdults = () => {
    if (NoOfAdults < 20) {
      var Num = NoOfAdults + 1;
      setNoOfAdults(Num);
      changeAdultNum(Num);
    } else if (NoOfAdults >= 20) {
      setNoOfAdults(20);
      changeAdultNum(20);
    }
  };

  const minimizingAdults = () => {
    if (NoOfAdults > 1) {
      var Num = NoOfAdults - 1;
      setNoOfAdults(Num);
      changeAdultNum(Num);
    } else if (NoOfAdults == 1) {
      setNoOfAdults(1);
      changeAdultNum(1);
    }
  };

  const addingChild = () => {
    if (NoOfChild < 20) {
      var Num = NoOfChild + 1;
      setNoOfChild(Num);
      changeChildNUm(Num);
    } else if (NoOfChild >= 20) {
      setNoOfChild(20);
      changeChildNUm(20);
    }
  };

  const minimizingChild = () => {
    if (NoOfChild > 0) {
      var Num = NoOfChild - 1;
      setNoOfChild(Num);
      changeChildNUm(Num);
    } else if (NoOfChild == 0) {
      setNoOfChild(0);
      changeChildNUm(0);
    }
  };

  return (
    <>
      <div className="NofoRoom">
        <div className="Addrooms-upper-sec">
          <p>Number of rooms</p>
          <div className="adding1">
            <Icons.FaMinusCircle
              id="minu"
              onClick={minimizingRooms}
              className="fontSize"
            />
            <p>{NoOfrooms}</p>
            <Icons.FaPlusCircle
              id="add"
              onClick={addingRooms}
              className="fontSize"
            />
          </div>
        </div>
        <div className="AddRomms-lower-sec">
          <div className="adding">
            <p>Adults</p>
            <Icons.FaMinusCircle
              id="minu"
              onClick={minimizingAdults}
              className="fontSize"
            />
            <p>{NoOfAdults}</p>
            <Icons.FaPlusCircle
              id="add"
              onClick={addingAdults}
              className="fontSize"
            />
            <p>Children</p>
            <Icons.FaMinusCircle
              id="minu"
              onClick={minimizingChild}
              className="fontSize"
            />
            <p>{NoOfChild}</p>
            <Icons.FaPlusCircle
              id="add"
              onClick={addingChild}
              className="fontSize"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Addrooms;
