import { useEffect, useState } from "react";
import "./NewNavBar.css";
import * as Icons from "react-icons/fa";
import * as Icons1 from "react-icons/bs";
import * as Icons2 from "react-icons/bi";

import whiteSiteLogo from "../images/siteLogo/whiteLogo.png";
import monoSiteLogo from "../images/siteLogo/logo.png";

import Dropdown from "./Dropdown";
import ChaletsDropdown from "./ChaletsDropdown";
import OffersDropdown from "./OffersDropdown";
import ExperiencesDropdown from "./ExperiencesDropdown";
import DiningDropdown from "./DiningDropdown";
import SpaDropdown from "./SpaDropdown";
import WeddingDropdown from "./WeddingDropdown";
import Boutiques from "./Boutiques";

import { theAviyanaDropdown } from "./NavItems";
import { theChaletsDropdown } from "./NavItems";
import { theOffersDropdown } from "./NavItems";
import { theExperiencesDropdown } from "./NavItems";
import { theDiscoverDinningDropdown } from "./NavItems";
import { theSpaDropdown } from "./NavItems";
import { theWeddingEventDropdown } from "./NavItems";
import { theBoutiquesDropdown } from "./NavItems";

function NewNavBar() {
  const [NavBarWhite, setNavBarWhite] = useState(false);
  const [SrcolledNavbar, setSrcolledNavbar] = useState(false);
  const [arrayName, setarayname] = useState(theAviyanaDropdown);

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  const controlHeaderIn = () => {
    setNavBarWhite(true);
    document.getElementById("navigationHeader").style.backgroundColor =
      "#ecebde";
    document.getElementById("navigationHeader").style.borderBottom =
      "3px solid #b57d4f";
  };

  const controlHeaderout = () => {
    setNavBarWhite(false);
    document.getElementById("navigationHeader").style.backgroundColor =
      " transparent";
    document.getElementById("navigationHeader").style.borderBottom = "none";
  };

  const controlNavbar = () => {
    if (window.scrollY > 50) {
      setSrcolledNavbar(true);
      if (window.innerWidth < 1350) {
        document.getElementById("ScrolledmobileNavSec").style.display = "flex";
      }
    } else {
      setSrcolledNavbar(false);
      document.getElementById("ScrolledmobileNavSec").style.display = "none";
    }
  };

  const mobileNavbarShowOut = () => {
    document.getElementById("moblieNavbar").style.display = "none";
  };

  const mobileNavbarShow = () => {
    document.getElementById("moblieNavbar").style.display = "flex";
  };

  const mobileSubNavbarShowOut = () => {
    document.getElementById("moblieNavbar").style.display = "flex";
    document.getElementById("mobileAviyanaDrop").style.display = "none";
  };

  const mobilesubNavshow = (a) => {
    setarayname(a);
    document.getElementById("moblieNavbar").style.display = "none";
    document.getElementById("mobileAviyanaDrop").style.display = "flex";
  };

  function scrollNav(a, b, c) {
    document.getElementById(a).style.height = c;
    document.getElementById(a).style.width = "100%";
    document.getElementById(a).style.transition = "all 0.5s";
    document.getElementById(b).style.visibility = "visible";
    document.getElementById(b).style.opacity = "1";
    document.getElementById(b).style.transition = "opacity 0.6s linear";
  }

  function scrollNav2(a, b, c) {
    document.getElementById(a).style.height = c;
    document.getElementById(a).style.width = "100%";
    document.getElementById(a).style.transition = "all 0.5s";
    document.getElementById(b).style.visibility = "visible";
    document.getElementById(b).style.opacity = "1";
    document.getElementById(b).style.transition = "opacity 0.6s linear";
  }

  function scrolOutNav(a, b) {
    document.getElementById(a).style.height = "0%";
    document.getElementById(a).style.width = "100%";

    document.getElementById(b).style.transition = "opacity 0.5s linear";
    document.getElementById(b).style.opacity = "0";
    if (document.getElementById(a).style.height > 0) {
      setNavBarWhite(true);
    }
  }

  function scrolOutNav2(a, b) {
    document.getElementById(a).style.height = "0%";
    document.getElementById(a).style.width = "100%";

    document.getElementById(b).style.transition = "opacity 0.5s linear";
    document.getElementById(b).style.opacity = "0";
    if (document.getElementById(a).style.height > 0) {
      setNavBarWhite(true);
    }
  }

  return (
    <>
      {/* whithout scrolled */}
      <div
        id="navigationHeader"
        className={SrcolledNavbar ? "hide" : "navigationBar-outer-sec"}
        // onMouseEnter={() => setNavBarWhite(true)}
        // onMouseLeave={() => setNavBarWhite(false)}

        onMouseEnter={() => controlHeaderIn() && setNavBarWhite(true)}
        onMouseLeave={() => controlHeaderout()}
      >
        <div className="New-nav-bar-sec">
          <div className="new-nav-bar">
            <div className="new-nav-upper-sec row">
              <div className="nav-bar-upper-part col">
                <Icons.FaSearch
                  size={20}
                  className={NavBarWhite ? "blackfaSearch" : "faSearch"}
                />
                <p className={NavBarWhite ? "hide" : "searchTxt"}>SEARCH</p>
                <input
                  type="text"
                  className={NavBarWhite ? "BlacksearchTxt" : "hide"}
                  placeholder="SEARCH"
                />
              </div>
              <div className="nav-bar-upper-part col center">
                <a href="/">
                  <img
                    src={NavBarWhite ? monoSiteLogo : whiteSiteLogo}
                    alt=""
                  />
                </a>
              </div>
              <div className="nav-bar-upper-part col">
                <ul className="booking-and-contact-list">
                  <li className="booking-item">
                    <a href="">
                      <Icons.FaPhone
                        size={20}
                        className={NavBarWhite ? "Blackfaphone" : "faphone"}
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        NavBarWhite ? "Blackbooking-item" : "booking-item"
                      }
                      href=""
                    >
                      CONTACT
                    </a>
                  </li>
                  <li>
                    <a
                      className={
                        NavBarWhite ? "Blackbooking-item" : "booking-item"
                      }
                      href=""
                    >
                      MODIFY BOOKING
                    </a>
                  </li>
                  <li className="booking-item">
                    <a href="./PopUpserver">
                      <input
                        type="button"
                        className={
                          NavBarWhite
                            ? "blacknew-navbar-booking-btn"
                            : "new-navbar-booking-btn"
                        }
                        value="BOOK"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* nav bar lower section */}
            <div className="new-lower-sec row">
              <ul className="navigation-item-list">
                <li
                  className="navigation-item "
                  onMouseEnter={() => scrollNav("Aviyana2", "pp1", "300px")}
                  onMouseLeave={() => scrolOutNav("Aviyana2", "pp1")}
                >
                  <a
                    className={
                      NavBarWhite ? "BlackNavBar-title" : "NavBar-title"
                    }
                    href="/"
                  >
                    THE AVIYANA
                  </a>
                  <div
                    id="Aviyana2"
                    className="ScrolledSubMenu"
                    onMouseEnter={() => scrollNav("Aviyana2", "pp1", "300px")}
                    onMouseLeave={() => scrolOutNav("Aviyana2", "pp1")}
                  >
                    <p id="pp1" className="p1">
                      <Dropdown />
                    </p>
                  </div>
                </li>
                <li className="navigation-item ">
                  <a
                    onMouseEnter={() => scrollNav("Chalets2", "pp2", "50px")}
                    onMouseLeave={() => scrolOutNav("Chalets2", "pp2")}
                    className={
                      NavBarWhite ? "BlackNavBar-title" : "NavBar-title"
                    }
                    href="/chalets"
                  >
                    CHALETS
                  </a>
                  <div
                    id="Chalets2"
                    className="ScrolledSubMenu"
                    onMouseEnter={() => scrollNav("Chalets2", "pp2", "50px")}
                    onMouseLeave={() => scrolOutNav("Chalets2", "pp2")}
                  >
                    <p id="pp2" className="p1">
                      <ChaletsDropdown />
                    </p>
                  </div>
                </li>
                <li className="navigation-item">
                  <a
                    onMouseEnter={() => scrollNav("offers2", "pp3", "300px")}
                    onMouseLeave={() => scrolOutNav("offers2", "pp3")}
                    className={
                      NavBarWhite ? "BlackNavBar-title" : "NavBar-title"
                    }
                    href="/offers"
                  >
                    MEMBERSHIP & OFFERS
                  </a>
                  <div
                    id="offers2"
                    className="ScrolledSubMenu"
                    onMouseEnter={() => scrollNav("offers2", "pp3", "300px")}
                    onMouseLeave={() => scrolOutNav("offers2", "pp3")}
                  >
                    <p id="pp3" className="p1">
                      <OffersDropdown />
                    </p>
                  </div>
                </li>
                <li className="navigation-item ">
                  <a
                    onMouseEnter={() =>
                      scrollNav("EXPERIENCES2", "pp4", "300px")
                    }
                    onMouseLeave={() => scrolOutNav("EXPERIENCES2", "pp4")}
                    className={
                      NavBarWhite ? "BlackNavBar-title" : "NavBar-title"
                    }
                    href="/experiences"
                  >
                    EXPERIENCES
                  </a>
                  <div
                    id="EXPERIENCES2"
                    className="ScrolledSubMenu"
                    onMouseEnter={() =>
                      scrollNav("EXPERIENCES2", "pp4", "300px")
                    }
                    onMouseLeave={() => scrolOutNav("EXPERIENCES2", "pp4")}
                  >
                    <p id="pp4" className="p1">
                      <ExperiencesDropdown />
                    </p>
                  </div>
                </li>
                <li className="navigation-item ">
                  <a
                    onMouseEnter={() => scrollNav("Dining2", "pp5", "300px")}
                    onMouseLeave={() => scrolOutNav("Dining2", "pp5")}
                    className={
                      NavBarWhite ? "BlackNavBar-title" : "NavBar-title"
                    }
                    href="/discover"
                  >
                    DINNING & DRINKS
                  </a>
                  <div
                    id="Dining2"
                    className="ScrolledSubMenu"
                    onMouseEnter={() => scrollNav("Dining2", "pp5", "300px")}
                    onMouseLeave={() => scrolOutNav("Dining2", "pp5")}
                  >
                    <p id="pp5" className="p1">
                      <DiningDropdown />
                    </p>
                  </div>
                </li>
                <li className="navigation-item ">
                  <a
                    onMouseEnter={() => scrollNav("Spa2", "pp6", "300px")}
                    onMouseLeave={() => scrolOutNav("Spa2", "pp6")}
                    className={
                      NavBarWhite ? "BlackNavBar-title" : "NavBar-title"
                    }
                    href="/spa"
                  >
                    SPA, ASHRAM & WELLNESS
                  </a>
                  <div
                    id="Spa2"
                    className="ScrolledSubMenu"
                    onMouseEnter={() => scrollNav("Spa2", "pp6", "300px")}
                    onMouseLeave={() => scrolOutNav("Spa2", "pp6")}
                  >
                    <p id="pp6" className="p1">
                      <SpaDropdown />
                    </p>
                  </div>
                </li>
                <li className="navigation-item ">
                  <a
                    onMouseEnter={() => scrollNav("Wedding2", "pp7", "50px")}
                    onMouseLeave={() => scrolOutNav("Wedding2", "pp7")}
                    className={
                      NavBarWhite ? "BlackNavBar-title" : "NavBar-title"
                    }
                    href="/wedding"
                  >
                    WEDDING & EVENTS
                  </a>
                  <div
                    id="Wedding2"
                    className="ScrolledSubMenu"
                    onMouseEnter={() => scrollNav("Wedding2", "pp7", "50px")}
                    onMouseLeave={() => scrolOutNav("Wedding2", "pp7")}
                  >
                    <p id="pp7" className="p1">
                      <WeddingDropdown />
                    </p>
                  </div>
                </li>
                <li className="navigation-item ">
                  <a
                    onMouseEnter={() => scrollNav("Boutiques2", "pp8", "50px")}
                    onMouseLeave={() => scrolOutNav("Boutiques2", "pp8")}
                    className={
                      NavBarWhite ? "BlackNavBar-title" : "NavBar-title"
                    }
                    href="/boutique"
                  >
                    BOUTIQUES
                  </a>
                  <div
                    id="Boutiques2"
                    className="ScrolledSubMenu"
                    onMouseEnter={() => scrollNav("Boutiques2", "pp8", "50px")}
                    onMouseLeave={() => scrolOutNav("Boutiques2", "pp8")}
                  >
                    <p id="pp8" className="p1">
                      <Boutiques />
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* scrolled navbar */}
      <div className={SrcolledNavbar ? "scroll-navbar" : "hide"}>
        <div className="scrollednew-lower-sec row">
          <ul className="navigation-item-list">
            <li className="navigation-item ">
              <a
                onMouseEnter={() => scrollNav2("Aviyana22", "pp11", "300px")}
                onMouseLeave={() => scrolOutNav2("Aviyana22", "pp11")}
                className="BlackNavBar-title"
                href="/"
              >
                THE AVIYANA
              </a>
              <div
                id="Aviyana22"
                className="ScrolledSubMenu"
                onMouseEnter={() => scrollNav2("Aviyana22", "pp11", "300px")}
                onMouseLeave={() => scrolOutNav2("Aviyana22", "pp11")}
              >
                <p id="pp11">
                  <Dropdown />
                </p>
              </div>
            </li>
            <li className="navigation-item ">
              <a
                className="BlackNavBar-title"
                href="/chalets"
                onMouseEnter={() => scrollNav("Chalets22", "pp22", "50px")}
                onMouseLeave={() => scrolOutNav("Chalets22", "pp22")}
              >
                CHALETS
              </a>
              <div
                id="Chalets22"
                className="ScrolledSubMenu"
                onMouseEnter={() => scrollNav("Chalets22", "pp22", "50px")}
                onMouseLeave={() => scrolOutNav("Chalets22", "pp22")}
              >
                <p id="pp22">
                  <ChaletsDropdown />
                </p>
              </div>
            </li>
            <li className="navigation-item">
              <a
                onMouseEnter={() => scrollNav("offers22", "pp33", "300px")}
                onMouseLeave={() => scrolOutNav("offers22", "pp33")}
                className="BlackNavBar-title"
                href="/offers"
              >
                MEMBERSHIP & OFFERS
              </a>
              <div
                id="offers22"
                className="ScrolledSubMenu"
                onMouseEnter={() => scrollNav("offers22", "pp33", "300px")}
                onMouseLeave={() => scrolOutNav("offers22", "pp33")}
              >
                <p id="pp33">
                  <OffersDropdown />
                </p>
              </div>
            </li>
            <li className="navigation-item ">
              <a
                onMouseEnter={() => scrollNav("EXPERIENCES22", "pp44", "300px")}
                onMouseLeave={() => scrolOutNav("EXPERIENCES22", "pp44")}
                className="BlackNavBar-title"
                href="/experiences"
              >
                EXPERIENCES
              </a>
              <div
                id="EXPERIENCES22"
                className="ScrolledSubMenu"
                onMouseEnter={() => scrollNav("EXPERIENCES22", "pp44", "300px")}
                onMouseLeave={() => scrolOutNav("EXPERIENCES22", "pp44")}
              >
                <p id="pp44">
                  <ExperiencesDropdown />
                </p>
              </div>
            </li>
            <li className="navigation-item ">
              <a
                onMouseEnter={() => scrollNav("Dining22", "pp55", "300px")}
                onMouseLeave={() => scrolOutNav("Dining22", "pp55")}
                className="BlackNavBar-title"
                href="/discover"
              >
                DINNING & DRINKS
              </a>
              <div
                id="Dining22"
                className="ScrolledSubMenu"
                onMouseEnter={() => scrollNav("Dining22", "pp55", "300px")}
                onMouseLeave={() => scrolOutNav("Dining22", "pp55")}
              >
                <p id="pp5">
                  <DiningDropdown />
                </p>
              </div>
            </li>
            <li className="navigation-item ">
              <a
                onMouseEnter={() => scrollNav("Spa22", "pp66", "300px")}
                onMouseLeave={() => scrolOutNav("Spa22", "pp66")}
                className="BlackNavBar-title"
                href="/spa"
              >
                SPA, ASHRAM & WELLNESS
              </a>
              <div
                id="Spa22"
                className="ScrolledSubMenu"
                onMouseEnter={() => scrollNav("Spa22", "pp66", "300px")}
                onMouseLeave={() => scrolOutNav("Spa22", "pp66")}
              >
                <p id="pp6">
                  <SpaDropdown />
                </p>
              </div>
            </li>
            <li className="navigation-item ">
              <a
                onMouseEnter={() => scrollNav("Wedding22", "pp77", "50px")}
                onMouseLeave={() => scrolOutNav("Wedding22", "pp77")}
                className="BlackNavBar-title"
                href="/wedding"
              >
                WEDDING & EVENTS
              </a>
              <div
                id="Wedding22"
                className="ScrolledSubMenu"
                onMouseEnter={() => scrollNav("Wedding22", "pp77", "50px")}
                onMouseLeave={() => scrolOutNav("Wedding22", "pp77")}
              >
                <p id="pp77">
                  <WeddingDropdown />
                </p>
              </div>
            </li>
            <li className="navigation-item ">
              <a
                onMouseEnter={() => scrollNav("Boutiques22", "pp88", "50px")}
                onMouseLeave={() => scrolOutNav("Boutiques22", "pp88")}
                className="BlackNavBar-title"
                href="/boutique"
              >
                BOUTIQUES
              </a>
              <div
                id="Boutiques22"
                className="ScrolledSubMenu"
                onMouseEnter={() => scrollNav("Boutiques22", "pp88", "50px")}
                onMouseLeave={() => scrolOutNav("Boutiques22", "pp88")}
              >
                <p id="pp88">
                  <Boutiques />
                </p>
              </div>
            </li>
            <li className="navigation-item ">
              <a href="./PopUpserver">
                <input
                  type="button"
                  className="scrolled-booking-btn"
                  value="BOOK"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* moblie nav main bar not scrolled*/}
      <div
        id="mobileNavSec"
        onMouseEnter={() => setNavBarWhite(true)}
        onMouseLeave={() => setNavBarWhite(false)}
        className="mobile-nav-sec"
      >
        <div className="mobile-nav-bar row">
          <div className="mobile-nav-item left col">
            <Icons1.BsJustify
              size={40}
              className={NavBarWhite ? "BlackJustify-btn" : "Justify-btn"}
              onClick={() => mobileNavbarShow()}
            />
            <Icons.FaPhone
              size={30}
              className={NavBarWhite ? "BlackMobilephone" : "Mobilephone"}
            />
          </div>

          <div className="mobile-nav-item  center col">
            <img src={NavBarWhite ? monoSiteLogo : whiteSiteLogo} alt="" />
          </div>
          <div className="mobile-nav-item  right col">
            <a href="./PopUpserver">
              <input
                type="button"
                className={
                  NavBarWhite
                    ? "blacknew-navbar-booking-btn"
                    : "new-navbar-booking-btn"
                }
                value="BOOK"
              />
            </a>
          </div>
        </div>
      </div>

      {/* moblie nav main bar  scrolled*/}
      <div id="ScrolledmobileNavSec" className="ScolledMobile-nav-sec">
        <div className="mobile-nav-bar row">
          <div className="mobile-nav-item left col">
            <Icons1.BsJustify
              size={40}
              className="BlackJustify-btn"
              onClick={() => mobileNavbarShow()}
            />
            <Icons.FaPhone size={30} className="BlackMobilephone" />
          </div>

          <div className="mobile-nav-item  center col">
            <img src={monoSiteLogo} alt="" />
          </div>
          <div className="mobile-nav-item  right col">
            <a href="./PopUpserver">
              <input
                type="button"
                className="blacknew-navbar-booking-btn"
                value="BOOK"
              />
            </a>
          </div>
        </div>
      </div>

      {/* mobile sub nav bar */}
      <div className="mobile-main-navbar-sec" id="moblieNavbar">
        <div className="mobile-main-navbar">
          <div className="cross-icon" onClick={() => mobileNavbarShowOut()}>
            <Icons1.BsX size={50} />
          </div>
          <ul className="mobile-main-navbar-list row">
            <div className="mobile-view title">
              <i
                className="mobile-main-nav-item col"
                onClick={() => mobilesubNavshow(theAviyanaDropdown)}
              >
                THE AVIYANA <Icons2.BiChevronRight size={30} />
              </i>
              <i
                className="mobile-main-nav-item col"
                onClick={() => mobilesubNavshow(theChaletsDropdown)}
              >
                CHALETS
                <Icons2.BiChevronRight size={30} />
              </i>
              <i
                className="mobile-main-nav-item col"
                onClick={() => mobilesubNavshow(theOffersDropdown)}
              >
                MEMBERSHIP & OFFERS
                <Icons2.BiChevronRight size={30} />
              </i>
              <i
                className="mobile-main-nav-item col"
                onClick={() => mobilesubNavshow(theExperiencesDropdown)}
              >
                EXPERIENCES
                <Icons2.BiChevronRight size={30} />
              </i>
              <i
                className="mobile-main-nav-item col"
                onClick={() => mobilesubNavshow(theDiscoverDinningDropdown)}
              >
                DINNING & DRINKS
                <Icons2.BiChevronRight size={30} />
              </i>
              <i
                className="mobile-main-nav-item col"
                onClick={() => mobilesubNavshow(theSpaDropdown)}
              >
                SPA, ASHRAM & WELLNESS
                <Icons2.BiChevronRight size={30} />
              </i>
              <i
                className="mobile-main-nav-item col"
                onClick={() => mobilesubNavshow(theWeddingEventDropdown)}
              >
                WEDDING & EVENTS
                <Icons2.BiChevronRight size={30} />
              </i>
              <i
                className="mobile-main-nav-item col"
                onClick={() => mobilesubNavshow(theBoutiquesDropdown)}
              >
                BOUTIQUES
                <Icons2.BiChevronRight size={30} />
              </i>
            </div>
          </ul>
        </div>
      </div>

      {/* mobile sub sub navar */}
      <div className="mobile-sub-sub-navbar-sec" id="mobileAviyanaDrop">
        <div className="mobile-sub-sub-navbar ">
          <div className="back-btn">
            <Icons2.BiChevronLeft
              size={50}
              className="backBtn"
              onClick={() => mobileSubNavbarShowOut()}
            />
          </div>
          <ul className="sub-sub-navbar">
            {arrayName.map((item) => {
              return (
                <li key={item.id}>
                  <a href={item.path} className="submenu-item row">
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default NewNavBar;
