import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Restayrants.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/drinking/box-1.jpg"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/drinking/box-2.jpg";
import aboutHotelimg3 from "../../images/Selected/drinking/box-3.jpg"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/drinking/box-4.jpg";
import aboutHotelimg5 from "../../images/Selected/drinking/box-5.jpg"; //chnage the img
import aboutHotelimg6 from "../../images/Selected/drinking/box-6.jpg";

import explorer from "../../images/Selected/drinking/resIcon.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/dinning/Restaurant.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Restayrants() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="RestayrantsHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content ">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1
                  data-aos="fade-up"
                  className="hotel-semi-title1 mt-4 mb-4"
                  id="SriLankanAuthenticRestaurant"
                >
                  Sri Lankan Authentic Restaurant
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  <p>
                    A Sri Lankan meal is a feast on a plate. While ingredients
                    are modest, the flavours are exotic. Our Sri Lankan
                    restaurant stives to create an adventure for the diner, a
                    diverse epicurean feast of local cuisine, bringing together
                    delightful dishes from all parts of the island. Take a seat
                    and enjoy!
                  </p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="res-header-img-sec1"></div>

        <div className="Header-Content " id="TheWorldisone">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  The World is one Restaurant
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  <p>
                    We showcase a global culinary journey from European
                    favourites to international classics. Treat your taste buds
                    to the most authentic flavours, fused with innovative
                    culinary techniques aimed at guaranteeing a sublime
                    experience and always with fresh seasonal products - the
                    perfect setting for an unforgettable dining experience.
                  </p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="res-header-img-sec2"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Restayrants;
