// import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/HeaderContentStyles.css";
import { AiFillStar } from "react-icons/ai";
import starLogo from "../images/7star.png";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function HeaderContent() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <div className="Header-Content ">
      <div className="Header-Content-bar">
        <div className="details-sec row">
          <div className="col">
            {/* {Array.from({ length: 7 }, (_, i) => (
              <AiFillStar className="hotel-stars" />
            ))} */}

            <img src={starLogo} alt="" className="starlogo" />
            <h2 className="hotel-title1">Aviyana</h2>
            <p className="hotel-address">KANDY, SRI LANKA</p>
            {/* <a href="" className="hotel-link">
              Aviyana, Cong, County Mayo, Ireland, F31 CA48
            </a> */}
            <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
              True excellence in Sri Lankan hospitality…
            </h1>
            <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
              Located in close proximity to the equator, Sri Lanka is one of the
              most scenic islands in the world with a rich history.
              <br />
              <p></p>
              Anyone who knows about Sri Lanka eagerly waits to make the country
              their next travel destination. Sri Lanka can be recognized as a
              country with one of the highest tourism literacy in the world
              owing to its mild climate and innumerable resources including
              ancient ruins, flora, elephants, gems and pearls.
              <p></p>
              <br />
              Historical records validate that Sri Lanka was in possession of a
              vibrant tourism industry spanning back to over 2500 years. The
              island is known as one of the most hospitable and friendly
              countries shaped by a rich history encompassing many Sri Lankan
              identities which were not available for the tourist 2500 years
              ago. Today, the country prides in an authentic identity in terms
              of nature, flora and fauna, food and hospitality that has brought
              nature's values and experiences together keeping on par with an
              international identity. Further validating this status of Sri
              Lanka, we are all set to launch the 8th seven-star hotel of the
              world with much pride.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderContent;
