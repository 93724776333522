import React, { useState } from "react";
import { Link } from "react-router-dom";
import { theOffersDropdown } from "./NavItems";
import "./OffersDropdown.css";

function OffersDropdown() {
  const [dropdown, setDropdown] = useState(false); // create stute
  const redirectToPage = (a) => {
    window.location = a;
  };

  return (
    <>
      <div className="navigation-sub-title-sec">
        <div className="navigation-sub-nav-bar ">
          <div className="navigation-sub-nav-part col">
            <div className="subNav-part-sec1">
              <ul className="sub-title-list">
                <div className="subnav-header-sec">
                  <li>
                    <p className="subNavHeader">- Membership</p>
                  </li>
                </div>
                {theOffersDropdown.map((item) => {
                  if (item.id < 5) {
                    return (
                      <div className="subnav-list-item">
                        <li key={item.id}>
                          <Link
                            // to={item.path}
                            className="submenu-item1 "
                            // onClick={() => redirectToPage(item.path)}
                          >
                            {item.title}
                          </Link>
                        </li>
                      </div>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="navigation-sub-nav-part col">
            <div className="subNav-part-sec1">
              <ul className="sub-title-list">
                <div className="subnav-header-sec">
                  <li>
                    <p className="subNavHeader">- Offers</p>
                  </li>
                </div>
                {theOffersDropdown.map((item) => {
                  if (item.id > 4) {
                    return (
                      <div className="subnav-list-item">
                        <li key={item.id}>
                          <Link
                            // to={item.path}
                            className="submenu-item1"
                            // onClick={() => redirectToPage(item.path)}
                          >
                            {item.title}
                          </Link>
                        </li>
                      </div>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OffersDropdown;
