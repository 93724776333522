import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Icons from "react-icons/fa";

import "./Navbar.css";
import { navItems } from "./NavItems"; // import navItems array from NavItems.js to Navbar.js
import { bookItems } from "./NavItems";
import Button from "./Button";
import CallIcon from "./CallIcon";
import Dropdown from "./Dropdown";
import ChaletsDropdown from "./ChaletsDropdown";
import OffersDropdown from "./OffersDropdown";
import ExperiencesDropdown from "./ExperiencesDropdown";
import DiningDropdown from "./DiningDropdown";
import SpaDropdown from "./SpaDropdown";
import WeddingDropdown from "./WeddingDropdown";
import Boutiques from "./Boutiques";
import siteLogo from "../images/siteLogo/logo.png";
import SideAviyana from "./SideAviyana";
import SideChalets from "./SideChalets";
import SideOffers from "./SideOffers";

function Navbar() {
  const [showNav, setShowNav] = useState(false); //create state to navbar
  const controlNavbar = () => {
    if (window.scrollY > 50) {
      setShowNav(true);
      setHeaderbgColor(true);
    } else {
      setShowNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  const [dropdown, setDropdown] = useState(false); // create state to THE AVIYANA title
  const [dropdownChalets, setDropChalets] = useState(false); // create state to chalets title
  const [dropdownOffers, setOffersDropdown] = useState(false);
  const [dropdownExperience, setExperiencesDropdown] = useState(false);
  const [dropdownDinning, setDinningDropdown] = useState(false);
  const [headerBgWhite, setHeaderbgColor] = useState(false); // create state to haeder
  const [dropdownSpa, setDropSpa] = useState(false);
  const [dropdownWedding, setDropWedding] = useState(false);
  const [dropdownBouti, setDropBouti] = useState(false);
  const [sideNav, setSideNav] = useState(false);

  const checkBackground = () => {
    setHeaderbgColor(true);
  };

  const checkBackground2 = () => {
    if (showNav) {
      setHeaderbgColor(true);
    } else {
      setHeaderbgColor(false);
    }
  };

  return (
    <div
      className={headerBgWhite ? "header-navigation2" : "header-navigation1"}
      onMouseEnter={checkBackground}
      onMouseLeave={checkBackground2}
    >
      <div
        className={
          showNav
            ? "haeder-upper-sectionHide container-fluid px-5 pt-2"
            : "haeder-upper-sectionShow container-fluid px-5 pt-2"
        }
      >
        <div className="header-left col-lg-4 col-sm-4 col-xs-4">
          <Icons.FaSearch className="Fasearch" />
          <Icons.FaBars className="Fabars" onClick={() => setSideNav(true)} />
          <input
            type="text"
            className="no-outline white Fasearch"
            placeholder="SEARCH"
          />
        </div>
        <div className="header-middle col-lg-4 col-sm-4 col-xs-4">
          <Link to="/" className="navbar-logo">
            <img src={siteLogo} alt="site-logo" />
          </Link>
        </div>
        <div className="header-right col-lg-4 col-sm-4 col-xs-4">
          <div className="callIcon">
            <CallIcon value={headerBgWhite} />
          </div>
          <ul className="booking-section ">
            {bookItems.map((item) => {
              return (
                <li key={item.id} className={item.cName}>
                  <Link
                    className={headerBgWhite ? "linkCSS1 " : "linkCSS2 "}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className="bookbtn">
            <Button value={headerBgWhite} name="BOOK" />
          </div>
        </div>
      </div>
      <nav className={showNav ? "navbar2 " : "navbar"}>
        {/* put navitems inside ul */}
        <ul className="nav-items">
          {navItems.map((item) => {
            //set service sub nav to man nav
            if (item.title === "THE AVIYANA") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <Link
                    className={headerBgWhite ? "nav-item1" : "nav-item2"}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                  {dropdown && <Dropdown value={showNav} />}
                </li>
              );
            }

            if (item.title === "CHALETS") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDropChalets(true)}
                  onMouseLeave={() => setDropChalets(false)}
                >
                  <Link
                    className={headerBgWhite ? "nav-item1" : "nav-item2"}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                  {dropdownChalets && <ChaletsDropdown />}
                </li>
              );
            }

            if (item.title === "OFFERS & GIFTS") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setOffersDropdown(true)}
                  onMouseLeave={() => setOffersDropdown(false)}
                >
                  <Link
                    className={headerBgWhite ? "nav-item1" : "nav-item2"}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                  {dropdownOffers && <OffersDropdown />}
                </li>
              );
            }

            if (item.title === "EXPERIENCES") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setExperiencesDropdown(true)}
                  onMouseLeave={() => setExperiencesDropdown(false)}
                >
                  <Link
                    className={headerBgWhite ? "nav-item1" : "nav-item2"}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                  {dropdownExperience && <ExperiencesDropdown />}
                </li>
              );
            }

            if (item.title === "DISCOVER DINNING & DRINKS") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDinningDropdown(true)}
                  onMouseLeave={() => setDinningDropdown(false)}
                >
                  <Link
                    className={headerBgWhite ? "nav-item1" : "nav-item2"}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                  {dropdownDinning && <DiningDropdown />}
                </li>
              );
            }

            if (item.title === "SPA & WELLNESS") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDropSpa(true)}
                  onMouseLeave={() => setDropSpa(false)}
                >
                  <Link
                    className={headerBgWhite ? "nav-item1" : "nav-item2"}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                  {dropdownSpa && <SpaDropdown />}
                </li>
              );
            }

            if (item.title === "WEDDING & EVENTS") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDropWedding(true)}
                  onMouseLeave={() => setDropWedding(false)}
                >
                  <Link
                    className={headerBgWhite ? "nav-item1" : "nav-item2"}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                  {dropdownWedding && <WeddingDropdown />}
                </li>
              );
            }

            if (item.title === "BOUTIQUES") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDropBouti(true)}
                  onMouseLeave={() => setDropBouti(false)}
                >
                  <Link
                    className={headerBgWhite ? "nav-item1" : "nav-item2"}
                    to={item.path}
                  >
                    {item.title}
                  </Link>
                  {dropdownBouti && <Boutiques />}
                </li>
              );
            }

            return (
              <li key={item.id} className={item.cName}>
                <Link
                  className={headerBgWhite ? "nav-item1" : "nav-item2"}
                  to={item.path}
                >
                  {item.title}
                </Link>
              </li>
            );
          })}
          <div className={showNav ? "booking-button-sec" : "hide"}>
            <Button value={headerBgWhite} name="BOOK" />
          </div>
        </ul>
      </nav>

      {/*collaps navbar */}

      <nav className={sideNav ? "navbar3" : "navbar4"}>
        <div className="cross">
          <Icons.FaTimes
            className="FaTimes"
            onClick={() => setSideNav(!sideNav)}
          />
        </div>

        {/* put navitems inside ul */}

        <div className="subMenu-section">
          <ul className="nav-items-side" id="navList">
            {navItems.map((item) => {
              //set service sub nav to man nav
              if (item.title === "THE AVIYANA") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onClick={() => setDropdown(!dropdown)}
                  >
                    <Link className="nav-item1" to={item.path}>
                      {item.title.toString().concat(" >")}
                      <div
                        className="aviyanaSideBar"
                        onClick={() => setSideNav(!sideNav)}
                      >
                        {dropdown && <SideAviyana />}
                      </div>
                    </Link>
                  </li>
                );
              }

              if (item.title === "CHALETS") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onClick={() => setDropChalets(!dropdownChalets)}
                  >
                    <Link className="nav-item1" to={item.path}>
                      {item.title.toString().concat(" >")}
                      <div
                        className="aviyanaSideBar"
                        onClick={() => setSideNav(!sideNav)}
                      >
                        {dropdownChalets && <SideChalets />}
                      </div>
                    </Link>
                  </li>
                );
              }

              if (item.title === "OFFERS & GIFTS") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onClick={() => setOffersDropdown(!dropdownOffers)}
                  >
                    <Link className="nav-item1" to={item.path}>
                      {item.title.toString().concat(" >")}{" "}
                      <div
                        className="aviyanaSideBar"
                        onClick={() => setSideNav(!sideNav)}
                      >
                        {dropdownOffers && <SideOffers />}
                      </div>
                    </Link>
                  </li>
                );
              }

              if (item.title === "EXPERIENCES") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onClick={() => setExperiencesDropdown(!dropdownExperience)}
                  >
                    <Link className="nav-item1" to={item.path}>
                      {item.title.toString().concat(" >")}
                    </Link>
                  </li>
                );
              }

              if (item.title === "DISCOVER DINNING & DRINKS") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onClick={() => setDinningDropdown(!dropdownDinning)}
                  >
                    <Link className="nav-item1" to={item.path}>
                      {item.title.toString().concat(" >")}
                    </Link>
                  </li>
                );
              }

              if (item.title === "SPA & WELLNESS") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onClick={() => setDropSpa(!dropdownSpa)}
                  >
                    <Link className="nav-item1" to={item.path}>
                      {item.title.toString().concat(" >")}
                    </Link>
                  </li>
                );
              }

              if (item.title === "WEDDING & EVENTS") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onClick={() => setDropWedding(!dropdownWedding)}
                  >
                    <Link className="nav-item1" to={item.path}>
                      {item.title.toString().concat(" >")}
                    </Link>
                  </li>
                );
              }

              if (item.title === "BOUTIQUES") {
                return (
                  <li
                    key={item.id}
                    className={item.cName}
                    onClick={() => setDropBouti(!dropdownBouti)}
                  >
                    <Link className="nav-item1" to={item.path}>
                      {item.title.toString().concat(" >")}
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>

        {/* side sub nav bars */}
      </nav>
    </div>
  );
}

export default Navbar;
