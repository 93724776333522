import React from "react";
import "./LuxuryTownCountry.css";
import NewCheckDate from "../../components/NewCheckDate.js";
import Footer from "../../components/Footer";
import StayInTouch from "../../components/StayInTouch";
import { getawayCollection } from "../../components/NavItems.js";
import Slider from "react-slick";
import { BsArrowRight } from "react-icons/bs";
import { useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function LuxuryTownCountry() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  const sliderRef = useRef(null);
  const settings = {
    ref: sliderRef,
    autoplay: false,
    autoplaySpeed: 2000,
    dots: false,
    draggable: true,
    arrows: false,

    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: false,
          slidesToShow: 3,
          raggable: true,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          raggable: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          centerMode: false,
          raggable: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          raggable: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="LuxuryTownCountry-header">
        {/* <video autoPlay muted loop source src={videobg} /> */}
        <div className="container">
          <div data-aos="fade-up" className=" header-txt">
            <p></p>
            <h1>
              <br />
            </h1>
            <h4>
              <br />
            </h4>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />

        <div className="luxuryStaycation  mb-5">
          <div className="luxuryStaycation-bar ">
            <div className="luxuryStay-header mt-5 mb-5">
              <h2>Luxury staycation</h2>
            </div>
            <div className="luxuryStay-des-sec mt-5 mb-5">
              <div className="luxuryStay-des">
                <p>
                  In partnership with The Merrion Hotel, two historic Irish
                  properties create a customised four-night trip of a lifetime.
                </p>
              </div>
              <div className="luxuryStay-call-and-book-sec">
                <div className="luxuryStay-call-and-book">
                  <p className="luxuryStay-call">call or</p>
                  <input
                    type="button"
                    className="luxuryStay-book-btn"
                    value="BOOK"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* getaway section */}
        <div className="getaway-sec mt-5 mb-5">
          <div className="getaway-bar row">
            <Slider {...settings}>
              {getawayCollection.map((obj) => (
                <div className="getawayCard col" id={obj.id}>
                  <div className="getawayImg-sec">
                    <img src={obj.img} alt="" href={obj.link} />
                  </div>
                  <div className="getaway-details">
                    <div className="getaway-header">
                      <h5>{obj.title}</h5>
                    </div>
                    <div className="getaway-link">
                      <a href={obj.link} className="link11">
                        view offer
                        <BsArrowRight className="link-arrow" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default LuxuryTownCountry;
