import { useEffect } from "react";
import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import TheAviyana from "../../images/Selected/TheAviyana.png";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import "./ReasonsWeAre.css";

import AOS from "aos";
import "aos/dist/aos.css";

import NewCheckDate from "../../components/NewCheckDate.js";

import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aboutHotelimg1 from "../../images/Selected/ceo.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/sunbox.png";
import aboutHotelimg3 from "../../images/Selected/Box-3.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/Box-4.png";
import aboutHotelimg5 from "../../images/Selected/Box-5.png"; //chnage the img
import aboutHotelimg6 from "../../images/Selected/Box-6.png";
import aboutHotelimg7 from "../../images/Selected/Box-7.png"; //chnage the img
import aboutHotelimg8 from "../../images/Selected/Box-8.png";
import aboutHotelimg9 from "../../images/Selected/Box-9.png"; //chnage the img
import aboutHotelimg10 from "../../images/Selected/Box-10.png";

import videobg from "../../images/Selected/HomeVideos/ReasonsWeAre.m4v"; //replace the home banner to video

function ReasonsWeAre() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="ReasonWeAre-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />

              {/* <h1>AVIYANA</h1> */}
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
              {/* <p>
                Leave the cares of the world behind, and reconnect with nature,
                rejoicing in a place where the only sound is the calming rhythm
                of Sri Lanka’s most spectacular Knuckles Mountain range.
              </p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Our family"
          des1="Led by Managing Director Dr. Thisara Hewawasam, with over 20 years of experience, Aviyana is a proud to be part of the investor-owned and run Aviyana
          Hotel. We are a family of passionate people, with a warm, inclusive ethos that embraces every aspect of our guests’ experiences. Our friendly and
          welcoming team is deeply committed to giving the very best in all we do and we will undoubtedly provide the perfect introduction to genuine Sri Lankan
          charm. "
          des2=" "
          des3=""
          linkVisible={true}
          link=""
          linkName="Contact Dr.Indika Hewawasam"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg2}
          header="Exceptional Location"
          des1="Aviyana is a masterpiece, truly remarkable, a simple, architectural structure with natural significance. Leave the cares of the world behind, and reconnect with nature,
          rejoicing in a place where the only sound is the calming rhythm of Sri Lanka’s most spectacular Knuckles Mountain range. With 270 degree views, Aviyana Private Chalets,
          nestled in the picturesque hills, will help you explore your wellbeing, surrounded by timeless serenity. Aviyana stands apart, offering a supremely serene retreat, blessed
          abundantly with mother natures’scenic beauty, the ability to experience the purest air and the call of calm with restorative virgin water from the Knuckles Mountain range."
          des2=""
          des3=""
          linkVisible={true}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Best of the best"
          des1="We are proud of our many accolades, which include our most prized award as , the only hotel to achieve the top-tier elitist status in Sri Lanka. Infusing the world of hospitality
          with the most innovative and luxurious high-end hotel seamlessly blending into its surrounding environment, Aviyana has taken a giant leap forward to grow our industry. We offer
          unsurpassed levels of service, a taste of true luxury in spacious villas, passion for quality and detail, curating the most fascinating aspects of our locale, not just in design, but in
          experiences and excursions that are tailored to the taste of each guest "
          des2=" "
          des3=""
          linkVisible={true}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg4}
          header="An Extraordinary Sanctuary"
          des1="Located amidst 24 acres of beautifully manicured gardens, verdant forests and woodland rich with birdlife, all overlooking sweeping views from Kandy to Nuwara Eliya, our
          sanctuary boasts various activities. We offer magical adventures that transform the way we see our country and provides a natural escape – indulge in the country’s first air
          sports bay, horse-riding, fishing, tennis, off-road driving, zip-wiring, tree-climbing, a host of outdoor activities that will provide provides a natural escape from everyday life or stay
          indoors and unwind with cosy movie nights in our stylish private cinema."
          des2=""
          des3=""
          linkVisible={true}
          link="/"
          linkName="Explore all our and activities"
        />
        <div className="space mt-5 mb-5"></div>
        {/* des sec end */}

        <div className="ReasonWeAre-header-img-sec1"></div>

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg5}
          header="Delighting the eyes and palate"
          des1="Dining at Aviyana offers another form of exploration and delight. A discovery of fresh, seasonal ingredients are at the heart of dining at Aviyana,
          where they are transformed into welcoming, authentic cuisine., where we invite guests to let their palate explore the native ingredients and
          exuberant flavors. Our chefs draw inspiration from the diversity of Sri Lanka’s cuisine and ingredients, where each dish is a whole new
          experience using fresh organic produce from skilled local producers, as well as produce from our own gardens. Be prepared to savour our
          Chefs’ exciting and innovative cuisine with novel additions such as the Rajasinghe King Meal or even ‘Pick and cook’ ingredients to your
          liking.With impeccable hospitality, the hotel offers a refined tea Afternoon High Tea with all the traditional accoutrements. If guest prefer a more
          sublime afternoon, High Tea by the Lake is available elevating this unique English tradition. The cocktail menu has specifically been designed
          with Aviyana and its surroundings in mind. Come sip our exotic array of cocktails, all of them prepared by our expert mixologists."
          des2=" "
          des3=""
          linkVisible={true}
          link="/"
          linkName="Discover our dining venues 
          "
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg6}
          header="We care"
          des1="We adhere to important sustainability measures and believe deeply that our legacy is as important to us as the joyful
          experiences we offer to guests. Aviyana is passionate about ensuring positive contributions to both our local economy and
          ecosystem, supporting the well-being of our people, society, and our planet.We have worked to create a unique value
          proposition focusing on sustainability as well as offering products and services with low environmental impacts while
          prioritizing the well-being of our guests. Committed to the community, we support local agriculture in culinary art, purchase
          of local products. Ensuring environmental protection, we work with respect towards the preservation of our natural settings
          and ecosystems.To uplift livelihoods, we promote through local hiring, sourcing and partnerships and invest in their
          professional growth, wellbeing and development. Aviyana is committed to building an inclusive workplace that welcome
          people from all walks of life."
          des2=""
          des3=""
          linkVisible={true}
          link="/"
          linkName="Explore all our sustainable efforts "
        />

        <div className="space mt-5 mb-5"></div>

        <AboutHotel
          img={aboutHotelimg7}
          header="Inspired design"
          des1="Our exquisite room and suites have been designed to completely immerse you in the world of the surrounding mountains with
          rich and layered interiors - rare, hand-chosen modern amenities that frame the views of our verdant grounds and the mountain
          beyond, echoing its natural beauty.The elegant suites are masterpieces, nestled in the picturesque hills, will help you explore
          your wellbeing, encircled by timeless serenity. We provide you an unparalleled 7-star experience, throughout the year, to
          escape and feed the soul, in tangible and intangible ways. Our accommodation offers a natural colour palette with an intimate
          ambience, focusing on simple lines enriched by refined finishes, and elegant textiles exuding the idea of subtle luxury.
          Contemporary furniture creates an aesthetical pleasing allure exuding an atmosphere that echoes the natural beauty of the
          Resort."
          des2=" "
          des3=""
          linkVisible={true}
          link="/"
          linkName="Explore our exquisite room and suites"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg8}
          header="Wellness"
          des1="We are proud of our deeply serene, genuinely restorative treatments, which draw on ancient, holistic healing practices and
          modern expertise. Having sought out the latest innovations in health science from around the world to harmonize with the
          most effective treatments among the rich heritage of Ayurveda in Sri Lanka, we offer one of the best wellness resorts for our
          guests. The Ashram tranquil surroundings is a space where care is highly personalized and life itself is celebrated. Our
          state-of-the-art facilities include an invigorating Hammam, steam room with breath-taking views blend perfectly with the
          resort’s natural environment, helping you reconnect with the essence of life itself."
          des2=""
          des3=""
          linkVisible={true}
          link="/"
          linkName="Explore the Ashram "
        />
        {/* des sec end */}
        <div className="ReasonWeAre-header-img-sec2"></div>
        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg9}
          header="Lifetime experiences"
          des1="Our connection to the land runs deep. Brimming with centuries of history and boundless innovative energy, our land never
          runs out of places to explore. We offer a variety of bespoke experiences that brings beautiful Sri Lankan to the fore and an
          adventure of a lifetime. Guests will arrive and depart traveling through sweeping mountain views from the vaulted vantage of
          a helicopter transfer. Above all resonates our ethos of providing a peaceful sanctuary, a gentle sense of joy and discovery,
          with a magical touch of luxury."
          des2=" "
          des3=""
          linkVisible={true}
          link="/"
          linkName="Discover more unique experiences "
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg10}
          header="Thoughtful touches"
          des1="Every member is empowered to make a difference, offering exceptionally attention to detail, and our world-famous warm Sri Lankan
          hospitality welcomes you. A signature welcome drink on arrival, fresh-cut bouquets in the suites, candlelit turndowns, personalised gifts –
          these all create a cosseting experience that make guests feel truly valued."
          des2=""
          des3=""
          linkVisible={true}
          link="/"
          linkName="Meet our extraordinary team "
        />

        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="ReasonWeAre-header-img-sec3"></div>
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default ReasonsWeAre;
