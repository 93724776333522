import React from "react";
import NewCheckDate from "../../components/NewCheckDate.js";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import "./Sustainability.css";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Box-14.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Box-15.png";
import aboutHotelimg3 from "../../images/Selected/Box-16.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/Box-17.png";
import aboutHotelimg5 from "../../images/Selected/Box-18.png"; //chnage the img
import aboutHotelimg6 from "../../images/Selected/Box-19.png";
import aboutHotelimg7 from "../../images/Selected/Box-20.png"; //chnage the img
import aboutHotelimg8 from "../../images/Selected/Box-21.png";
import aboutHotelimg9 from "../../images/Selected/Box-22.png"; //chnage the img
import aboutHotelimg10 from "../../images/Selected/Box-23.png";

import videobg from "../../images/Selected/HomeVideos/Sustainability.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Sustainability() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="Sustainability-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />

              {/* <h1>AVIYANA</h1> */}
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="A Company with a Purpose"
          des1="Aviyana is passionate about ensuring positive contributions to both our local economy and ecosystem, supporting the well-being of
          our people, society, and our planet. We have worked to create a unique value proposition focusing on sustainability as well as offering
          products and services with low environmental impacts while prioritizing the need for well-being of our guests. Committed to the
          community, we support local agriculture and culture. Ensuring environmental protection, we work with respect towards the
          preservation of our natural settings."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg2}
          header="Restoration & preservation "
          des1="Since development began in 2018, Aviyana has invested substantially in a meticulous restoration and enhancement
          programme that has restored the Knuckles mountains range to its former glory. The land and scale Aviyana presented
          unique challenges. However, our architects, designers and the extensive team of local craftspeople, together rallied with
          local suppliers and delivered a level of precision that ensured every detail was taken care of in an eco-friendly manner – and
          with breathtaking results."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Responsible consumption & production
          "
          des1="We support sustainable production and consumption by funding local enterprises that strengthen local supply chains. We
          also reduce our environmental impact by eliminating unnecessary single-use plastics and encouraging guests to choose
          electronic travel documents."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg4}
          header="Impact on planet, people, and wildlife "
          des1="Our strategy was developed to address these goals, anchored to the United Nations’ Global Goals, addressing sustainability and
          conservation by tackling climate change, cultural preservation by targeting over-tourism, and protection through animal welfare."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />

        <div className="space mt-5 mb-5"></div>
        {/* des sec end */}

        <div className="sustainability-header-img-sec1"></div>

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg5}
          header="Achieve carbon neutrality"
          des1="By 2030 or sooner we would like to be carbon neutral. We have LED lighting installed as well as BMS, AAA-rated equipment. In 2025,
          50% of our waste was recycled or composted and there are charging points for cars."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg6}
          header="Reduce food waste"
          des1="We work closely with local food suppliers and organic food suppliers. As well as working to increase our collaboration with these
          partners by 2025, we also have poly tunnels and focus on home-grown produce."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5"></div>

        <AboutHotel
          img={aboutHotelimg7}
          header="Use local & organic food products"
          des1="We work closely with local food suppliers and organic food suppliers. As well as working to increase our collaboration with these
          partners by 2025, we also have poly tunnels and focus on home-grown produce."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg8}
          header="Reduce printed brochures "
          des1="The Spa uses QR codes for its treatments and brochures have been discontinued in guest bedrooms. By 2025 we want to
          stop using printed brochures at the hotel."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />

        <div className="space mt-5 mb-5"></div>
        {/* des sec end */}

        <div className="sustainability-header-img-sec2"></div>

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg9}
          header="Wildlife experiences adhere to Animal Welfare Policy"
          des1="All of our animal and wildlife experiences comply with the National Animal Welfare Policy. We also produce honey as well as providing
          animal boxes to support conservation."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg10}
          header="Single-Use Plastics Elimination Initiative"
          des1="We aim to eliminate all single-use plastics from our operations and itineraries by the end of 2022. The kitchens have
          completely eliminated clingfilm and we operate a 100% compostable packaging policy."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="sustainability-intro-header mb-5 ">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1  mb-4">
                  A registered trademark: _______________ Foundation
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  We are honoured to have been selected to host a
                  _________________® Experiences, which directly advance the
                  United Nations Global Goals.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="sustainability-header-img-sec3"></div>
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Sustainability;
