import React from "react";
import "../Chalets.css";
import videobg from "../../images/AviyanaVideo.m4v";
import IntroHeader from "../../components/IntroHeader";
import Footer from "../../components/Footer";
import StayInTouch from "../../components/StayInTouch";
import Agree from "../../components/Agree";
import VillaData from "../../components/VillaData.js";
import { useEffect } from "react";
import {
  data1,
  data2,
  data3,
  data4,
  data5,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  PremiumVillaAmenities,
  RoyalVilla,
  RoyalGovernorsVilla,
  RoyalQueensVilla,
  RoyalKingsVilla,
} from "../../components/SliderData";

import AOS from "aos";
import "aos/dist/aos.css";
import NewCheckDate from "../../components/NewCheckDate.js";
import RoomsSilder from "../../components/RoomsSilder";
import ChaletsSliders from "../../components/ChaletsSliders.js";

import explorer from "../../images/7star.png";
import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import plan1 from "../../images/Selected/plans/premiumVilla.png";
import plan2 from "../../images/Selected/plans/royalVilla.png";
import plan3 from "../../images/Selected/plans/royalGover.png";
import plan4 from "../../images/Selected/plans/royalQueen.png";
import plan5 from "../../images/Selected/plans/royalKing.png";
import Map from "../../images/Selected/charlets/MapNew.jpg";

function ChaletsSubPage() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <Agree />

      <div className="home-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />

              {/* <h1>AVIYANA</h1> */}
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
              {/* <p>
                Leave the cares of the world behind, and reconnect with nature,
                rejoicing in a place where the only sound is the calming rhythm
                of Sri Lanka’s most spectacular Knuckles Mountain range.
              </p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 mt-5">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon11" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  A place of peace surrounded by nature
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 ">
                  With extensive space and discreet, dedicated personal service,
                  Aviyana’s Private Chalets offer travellers opportunities to
                  savour precious time with family and friends, or simply to
                  escape into romantic seclusion in one of the world’s most
                  beautiful settings. Set apart in their own lush enclave all
                  facilities of the Aviyana resort, offers the comfort and
                  reassurance of a private home.
                  <br />
                  <p></p>Each of the 60 Chalets at Aviyana represent traditional
                  elegance and finesse. All Chalets have been individually and
                  lovingly designed, combining the latest modern luxuries.
                  Meticulous attention to detail can be found in the unique,
                  carefully sourced luxury furniture and sumptuous fabrics,
                  bespoke beds, feature lighting, and other amenities.
                  <p></p>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="space" id="PremiumVilla"></div>

        <div className="Chalets-header-img-sec1"></div>

        <VillaData
          Header="Premium Villa"
          villaDes1="With picturesque views, the Royal Queen’s villa is ideal for families seeking a peaceful immersion in Aviyana’s tropical paradise. The Master-suite seamlessly integrates with the charming
        natural surroundings, a private living area balances privacy with plenty of space for socialising and a luxurious bathroom is complete with a deep-soaking tub. The One-bedroom features a
        sumptuous king-size bed with an ensuite bathroom."
          villaDes2="A private pool and sun deck are surrounded by calming greenery, celebrating outdoor living. Living and dining areas provide a flowing layout, ideal for gathering with friends or entertaining."
          featureheader="Key room features include"
          roomFeature={feature1}
          ApproximateSize="1800 sq. ft"
          Bedroom=""
          Hall=""
          Bathroom=""
          AmenitiesList={PremiumVillaAmenities}
        />
        <div className="plan-sec">
          <div className="plan-bar">
            <div className="Chalets-plan1">
              <img src={plan1} alt="" />
            </div>
          </div>
        </div>

        {/* <ChaletsSliders
          imgGallary={data1}
          roomFeature={feature1}
          visible={true}
          header="Premium Villa"
          describtion="The one-bedroom villa is uniquely private yet with within easy access to the resort.  "
          link="/corribRoom"
        /> */}

        <div className="space" id="RoyalVilla"></div>

        <div className="Chalets-header-img-sec2"></div>

        <VillaData
          Header="Royal Villa"
          villaDes1="Well-suited for families and couples seeking their own haven, the Royal Villa affords tranquil and serene views. The two bedrooms with king-size beds and luxurious ensuite bathrooms have outside
          sitting areas for much-needed moments of calm. Peaceful environments are abound with secluded private gardens and a swimming pool including a central dining and living areas ensuring privacy
          and space in perfect balance promising a memorable stay."
          villaDes2="For entertainment the rooms have fully interactive plasma screen TVs with movies and music on-demand, and a host of personal touches ensuring time is always well spent. While the resort’s
          amenities are also within easy reach, a butler service is also on hand to tend to the needs of every guest."
          featureheader="Key room features include"
          roomFeature={feature2}
          ApproximateSize="1400 - 1500 sq. ft "
          Bedroom=""
          Hall=""
          Bathroom=""
          AmenitiesList={RoyalVilla}
        />
        <div className="plan-sec">
          <div className="plan-bar">
            <div className="Chalets-plan1">
              <img src={plan2} alt="" />
            </div>
          </div>
        </div>

        {/* <ChaletsSliders
          imgGallary={data2}
          roomFeature={feature2}
          visible={true}
          header="Royal Villa"
          describtion="Well-suited for families and couples seeking their own haven, the Royal Villa affords tranquil and serene views."
          link="/corribRoom"
        /> */}

        <div className="space" id="RoyalGovernorsVilla"></div>
        <div className="Chalets-header-img-sec3"></div>

        <VillaData
          Header="Royal Governor’s Villa"
          villaDes1="A magnificent retreat for small groups or families. Enveloped by lush foliage, the Royal Governor’s three-bedroom including an opulent master bedroom and ensuite bathroom, tucked
          away in a quiet corner, providing guests with space and privacy and expansive secluded views to enjoy. "
          villaDes2="With a focus on outdoors, the villa features a central swimming pool and terrace for afternoons in the sun and winding down evenings. The large living and dining space provides a
          welcoming retreat where guests of all ages can gather to spend time and share stories of the day’s discoveries."
          featureheader="Key room features include"
          roomFeature={feature3}
          ApproximateSize=" 1700 - 1800 sq. ft "
          Bedroom=""
          Hall=""
          Bathroom=""
          AmenitiesList={RoyalGovernorsVilla}
        />
        <div className="plan-sec">
          <div className="plan-bar">
            <div className="Chalets-plan1">
              <img src={plan3} alt="" />
            </div>
          </div>
        </div>

        {/* <ChaletsSliders
          imgGallary={data3}
          roomFeature={feature3}
          visible={true}
          header="Royal Governor’s Villa"
          describtion="A magnificent retreat for small groups or families. "
          link="/corribRoom"
        /> */}

        <div className="space" id="RoyalQueensVilla"></div>
        <div className="Chalets-header-img-sec4"></div>

        <VillaData
          Header="Royal Queen’s Villa"
          villaDes1="With picturesque views, the Royal Queen’s villa is ideal for families seeking a peaceful immersion in Aviyana’s tropical paradise. The Master-suite seamlessly integrates with the charming
          natural surroundings, a private living area balances privacy with plenty of space for socialising and a luxurious bathroom is complete with a deep-soaking tub. The One-bedroom features a
          sumptuous king-size bed with an ensuite bathroom. "
          villaDes2="A private pool and sun deck are surrounded by calming greenery, celebrating outdoor living. Living and dining areas provide a flowing layout, ideal for gathering with friends or entertaining."
          featureheader="Key room features include"
          roomFeature={feature4}
          ApproximateSize="1800 sq. ft  "
          Bedroom=""
          Hall=""
          Bathroom=""
          AmenitiesList={RoyalQueensVilla}
        />
        <div className="plan-sec">
          <div className="plan-bar">
            <div className="Chalets-plan1">
              <img src={plan4} alt="" />
            </div>
          </div>
        </div>

        {/* <ChaletsSliders
          imgGallary={data4}
          roomFeature={feature4}
          visible={true}
          header="Royal Queen’s Villa"
          describtion="With picturesque views, the Royal Queen’s villa is ideal for families seeking a peaceful immersion in Aviyana’s tropical paradise. "
          link="/corribRoom"
        /> */}

        <div className="space" id="RoyalKingsVilla"></div>
        <div className="Chalets-header-img-sec5"></div>

        <VillaData
          Header="Royal King’s Villa"
          villaDes1="Set over two storeys, the Royal King’s villa offers breathtaking views of the towering mountains. The ground floor contains a luxurious Master bedroom with a spacious living area and tasteful local
          accents. The one-bed room exudes a rustic coziness. The large inviting private pool, terrace and deck areas provide ample space to relax and unwind.  "
          villaDes2="The upper deck hosts the living and dining areas with tranquil views of the picturesque mountains and ideal for evenings spent reconnecting. Both floors of the two-storey chalet are decorated with
          tasteful accents framing the surrounding."
          featureheader="Key room features include"
          roomFeature={feature5}
          ApproximateSize="2100 sq. ft "
          Bedroom=""
          Hall=""
          Bathroom=""
          AmenitiesList={RoyalKingsVilla}
        />
        <div className="plan-sec">
          <div className="plan-bar">
            <div className="Chalets-plan1">
              <img src={plan5} alt="" />
            </div>
          </div>
        </div>

        {/* <ChaletsSliders
          imgGallary={data5}
          roomFeature={feature5}
          visible={true}
          header="Royal King’s Villa"
          describtion="Set over two storeys, the Royal King’s villa offers breathtaking views of the towering mountains. "
          link="/corribRoom"
        /> */}

        <div className="space" id="TotalBuyoutAviyana"></div>
        <div className="Chalets-header-img-sec6"></div>
        <div>
          <IntroHeader
            header="Total Buyout Aviyana "
            Detail="Own a peaceful retreat with a timeless aura. Aviyana offers a total buyout of its chalets. Owners enjoy the benefits of Aviyana's professional management, and enjoy privileged access to all the
            resort’s facilities, as well as a maintenance and security team.  "
          />
        </div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default ChaletsSubPage;
