import image1 from "../images/Selected/charlets/img1.png";
import image2 from "../images/Selected/charlets/img2.png";
import image3 from "../images/Selected/charlets/img3.png";
import image4 from "../images/Selected/charlets/img4.png";
import image5 from "../images/Selected/charlets/img5.jpg";

import exImg1 from "../images/Selected/experiences/Home/expLand1.png";
import exImg2 from "../images/Selected/experiences/Home/expLand2.png";
import exImg3 from "../images/Selected/experiences/Home/expLand3.png";
import exImg4 from "../images/Selected/experiences/Home/expLand4.png";
import exImg5 from "../images/Selected/experiences/Home/expLand5.png";
import exImg6 from "../images/Selected/experiences/Home/expLand6.png";

import exGalTwo1 from "../images/Selected/experiences/Home/expLand7.png";
import exGalTwo2 from "../images/Selected/experiences/Home/expLand8.png";
import exGalTwo3 from "../images/Selected/experiences/Home/expLand9.png";
import exGalTwo4 from "../images/Selected/experiences/Home/expLand10.png";
import exGalTwo5 from "../images/Selected/experiences/Home/expLand11.png";
import exGalTwo6 from "../images/Selected/experiences/Home/expLand12.png";

import exImg11 from "../images/Selected/drinking/home/dinningHomeLand1.png";
import exImg22 from "../images/Selected/drinking/home/dinningHomeLand2.png";
import exImg33 from "../images/Selected/drinking/home/dinningHomeLand3.png";

import exImg111 from "../images/Selected/drinking/home/dinningHomeLand4.png";
import exImg222 from "../images/Selected/drinking/home/dinningHomeLand5.png";
import exImg333 from "../images/Selected/drinking/home/dinningHomeLand6.png";
import exImg444 from "../images/Selected/drinking/home/dinningHomeLand7.png";
import exImg555 from "../images/Selected/drinking/home/dinningHomeLand8.png";
import exImg666 from "../images/Selected/drinking/home/dinningHomeLand9.png";

import timage1 from "../images/sc-8-image-1.webp";
import timage2 from "../images/sc-8-image-2.webp";
import timage3 from "../images/sc-8-image-3.webp";

import dimage1 from "../images/dimg1.webp";
import dimage2 from "../images/dimg2.webp";
import dimage3 from "../images/dimg3.webp";

import spaimage1 from "../images/spaimg1.webp";
import spaimage2 from "../images/spaimg2.webp";
import spaimage3 from "../images/spaimg3.webp";

import expimage1 from "../images/Selected/experiences/Home/Box-1.png";
import expimage2 from "../images/Selected/experiences/Home/Box-2.png";
import expimage3 from "../images/Selected/experiences/Home/Box-3.png";

import wedImg1 from "../images/Selected/Wedding/home/gallary/gal1.png";
import wedImg2 from "../images/Selected/Wedding/home/gallary/gal2.png";
import wedImg3 from "../images/Selected/Wedding/home/gallary/gal3.png";
import wedImg4 from "../images/Selected/Wedding/home/gallary/gal4.png";
import wedImg5 from "../images/Selected/Wedding/home/gallary/gal5.png";
import wedImg6 from "../images/Selected/Wedding/home/gallary/gal6.png";

export const data1 = [image1];
export const data2 = [image5];
export const data3 = [image4];
export const data4 = [image2];

export const data5 = [image3];

export const ExGallary = [
  {
    id: 1,
    img: exImg1,
    title: "Paragliding",
    link: "../Sports#Paragliding",
  },
  {
    id: 2,
    img: exImg2,
    title: "River Walk",
    link: "../OutdoorAdventure#RiverWalk",
  },

  {
    id: 3,
    img: exImg3,
    title: "Hot Air Ballooning",
    link: "../Sports#HotAir",
  },
  {
    id: 4,
    img: exImg4,
    title: "Zip-lining & Tree climbing",
    link: "../Sports#Ziplining",
  },
  {
    id: 5,
    img: exImg5,
    title: "Rock Climbing",
    link: "../ports#RockClimbing",
  },
  {
    id: 6,
    img: exImg6,
    title: "Scenic Walking, Hiking, and Tracking",
    link: "../OutdoorAdventure#ScenicWalking",
  },
];

export const ExGallary2 = [
  {
    id: 1,
    img: exImg11,
    title: "Sri Lankan Authentic Restaurant",
    link: "../Restaurants#SriLankanAuthenticRestaurant",
  },
  {
    id: 2,
    img: exImg22,
    title: "The World is one Restaurant ",
    link: "../Restaurants#TheWorldisone",
  },

  {
    id: 3,
    img: exImg33,
    title: "The Aviyana Sky Bar",
    link: "../Bars#TheAviyanaSkyBar",
  },
];

export const ExGallary3 = [
  {
    id: 1,
    img: exImg111,
    title: "Sundowners",
    link: "../DiningAndDrinks#Sundowners",
  },
  {
    id: 2,
    img: exImg222,
    title: "Wine Tasting",
    link: "../Bars#Winetasting",
  },

  {
    id: 3,
    img: exImg333,
    title: "Cooking Lessons",
    link: "../DiningAndDrinks#CookingLessons",
  },
  {
    id: 4,
    img: exImg444,
    title: "The Billiards Room & Cigar Terrace",
    link: "../Bars#TheBilliardsRoom",
  },
  {
    id: 5,
    img: exImg555,
    title: "Taste the spice ",
    link: "../ExperienceSubItems#Tastethespice",
  },
  {
    id: 6,
    img: exImg666,
    title: "Make your Handcraf ",
    link: "../ExperienceSubItems#handcraft",
  },
];

export const ExGallary22 = [
  {
    id: 1,
    img: exGalTwo1,
    title: "Sri Lankan Paddy farming Experience",
    link: "../Culture#SriLankanPaddy",
  },
  {
    id: 2,
    img: exGalTwo2,
    title: "Estate gardens",
    link: "../OutdoorAdventure#Estategardens",
  },

  {
    id: 3,
    img: exGalTwo3,
    title: "Meet our Artisan Craftsman",
    link: "../Culture#Ourresident",
  },
  {
    id: 4,
    img: exGalTwo4,
    title: "The Spa",
    link: "",
  },
  {
    id: 5,
    img: exGalTwo5,
    title: "The Ashram ",
    link: "",
  },
  {
    id: 6,
    img: exGalTwo6,
    title: "Authentic & Traditional Dance  ",
    link: "",
  },
];

export const WeddingGallary = [
  {
    id: 1,
    img: wedImg1,
    title: "Destination Weddings",
    link: "../Culture#SriLankanPaddy",
  },
  {
    id: 2,
    img: wedImg2,
    title: "Honeymoons ",
    link: "../OutdoorAdventure#Estategardens",
  },

  {
    id: 3,
    img: wedImg3,
    title: "Conferences & Meetings",
    link: "../Culture#Ourresident",
  },
  {
    id: 4,
    img: wedImg4,
    title: "Birthdays & Celebrations",
    link: "",
  },
  {
    id: 5,
    img: wedImg5,
    title: "Department of Romance ",
    link: "",
  },
  {
    id: 6,
    img: wedImg6,
    title: "Department of Happiness  ",
    link: "",
  },
];
export const feature1 = [
  {
    id: 1,
    title: "Feature beds",
  },
  {
    id: 2,
    title: "Egyptian cotton bed linen",
  },

  {
    id: 3,
    title: "Bravat toiletries",
  },
  {
    id: 4,
    title: "Butler Service",
  },
];

export const feature2 = [
  {
    id: 1,
    title: "Feature beds",
  },
  {
    id: 2,
    title: "Egyptian cotton bed linen",
  },

  {
    id: 3,
    title: "Bravat toiletries",
  },
  {
    id: 4,
    title: "Butler Service",
  },
];

export const feature3 = [
  {
    id: 1,
    title: "Feature beds",
  },
  {
    id: 2,
    title: "Egyptian cotton bed linen",
  },

  {
    id: 3,
    title: "Bravat toiletries",
  },
];

export const feature4 = [
  {
    id: 1,
    title: "Feature beds",
  },
  {
    id: 2,
    title: "Egyptian cotton bed linen",
  },

  {
    id: 3,
    title: "Bravat toiletries",
  },
  {
    id: 4,
    title: "Butler Service",
  },
];

export const feature5 = [
  {
    id: 1,
    title: "Feature beds",
  },
  {
    id: 2,
    title: "Egyptian cotton bed linen",
  },

  {
    id: 3,
    title: "Bravat toiletries",
  },
  {
    id: 4,
    title: "Butler Service",
  },
];

export const OurTeamdata = [
  {
    id: 1,
    imgSrc: timage1,
    date: "2022-10-19",
    team: "red carnation family",
    title: "The talented chefs of Red Carnation Hotels",
    description:
      "To mark International Chefs Day this October, we are highlighting some of the talented and inspiring chefs from across our collection.",
    link: "/",
  },
  {
    id: 2,
    imgSrc: timage2,
    date: "2022-09-28",
    team: "red carnation family",
    title: "Characters of the Castle: Noli Alngohuro",
    description:
      "Meet our most recent Character of the Castle, Noli Alngohuro.",
    link: "/",
  },
  {
    id: 3,
    imgSrc: timage3,
    date: "2022-09-06",
    team: "red carnation family",
    title: "Introducing Ashford Castle's New Executive Chef",
    description:
      "Renowned chef, Liam Finnegan takes the helm in Ashford Castle's world-renowned kitchens.",
    link: "/",
  },
];

export const Deliciousdata = [
  {
    id: 1,
    imgSrc: dimage1,
    date: "2022 10 19",
    team: "red carnation family",
    title: "The talented chefs of Red Carnation Hotels",
    description:
      "To mark International Chefs Day this October, we are highlighting some of the talented and inspiring chefs from across our collection.",
    link: "/",
  },
  {
    id: 2,
    imgSrc: dimage2,
    date: "2022-09-28",
    team: "red carnation family",
    title: "Characters of the Castle: Noli Alngohuro",
    description:
      "Meet our most recent Character of the Castle, Noli Alngohuro.",
    link: "/",
  },
  {
    id: 3,
    imgSrc: dimage3,
    date: "2022-09-06",
    team: "red carnation family",
    title: "Introducing Ashford Castle's New Executive Chef",
    description:
      "Renowned chef, Liam Finnegan takes the helm in Ashford Castle's world-renowned kitchens.",
    link: "/",
  },
];

export const Spadata = [
  {
    id: 1,
    imgSrc: spaimage1,
    date: "",
    team: "",
    title: "Spa brands",
    description:
      "From our very own exclusive B|Africa product line to luxurious treatments from Elemis and Natura Bissé.",
    link: "/",
  },
  {
    id: 2,
    imgSrc: spaimage2,
    date: "",
    team: "",
    title: "The Salon",
    description:
      "Perfect to book after your spa experience, The Salon's services include a range of styling services and treatment mask therapies. ",
    link: "/",
  },
  {
    id: 3,
    imgSrc: spaimage3,
    date: "",
    team: "",
    title: "Facilities",
    description:
      "Learn more about our different facilities, including our relaxation pool, treatment rooms and fitness suite. ",
    link: "/",
  },
];

export const expdata = [
  {
    id: 1,
    imgSrc: expimage1,
    date: "",
    team: "",
    title: "Bird watching",
    description:
      "The area surrounding Aviyana and the Knuckles Forest reserve is undoubtedly a paradise for ornithologists and bird lovers.",
    link: "../OutdoorAdventure#BirdWatching",
  },
  {
    id: 2,
    imgSrc: expimage2,
    date: "",
    team: "",
    title: "Biking and Cycling Tour",
    description:
      "There is much to discover in Udispaththuwa, Sri Lanka’s ancient citadel in the hills, and Aviyana’s backyard. Steeped in history, there is no better way to take in the scenic surroundings blended with real mountain biking adventures.",
    link: "../OutdoorAdventure#BikingTour",
  },
  {
    id: 3,
    imgSrc: expimage3,
    date: "",
    team: "",
    title: "Village Guided Nature Experiences",
    description:
      "Get a feel of typical village life in addition to walking and sight-seeing  culminating with a culinary experience in true Sri Lankan style at Heeloya - a rural, remote agricultural village ",
    link: "../OutdoorAdventure#GuidedNature",
  },
];

export const PremiumVillaAmenities = [
  {
    id: 1,
    title: "120 sq. ft Pool ",
  },
  {
    id: 2,
    title: "Living Room ",
  },
  {
    id: 3,
    title: "Mini bar ",
  },
  {
    id: 4,
    title: "Complimentary wireless and cabled internet  ",
  },
  {
    id: 5,
    title: "Breath toiletries  ",
  },
  {
    id: 6,
    title: "Plush bathrobes and slippers  ",
  },
  {
    id: 7,
    title: "Mini bar  ",
  },
  {
    id: 8,
    title: "USB charger ",
  },
  {
    id: 9,
    title: "Individually controlled air-conditioning ",
  },
  {
    id: 10,
    title: "Electronic Car ",
  },
  {
    id: 11,
    title: "2 Bedroom ",
  },
  {
    id: 12,
    title: "Pantry ",
  },
  {
    id: 13,
    title: "Feature beds  ",
  },
  {
    id: 14,
    title: "Private Garden   ",
  },
  {
    id: 15,
    title: "Daily room service  ",
  },
  {
    id: 16,
    title: "24 hour in-room dining   ",
  },
  {
    id: 17,
    title: "Bottled mineral water  ",
  },
  {
    id: 18,
    title: "110 volt sockets ",
  },
  {
    id: 19,
    title: "Hair dryer ",
  },
  {
    id: 20,
    title: "Electronic Generator ",
  },
  {
    id: 21,
    title: "2 Bathroom  ",
  },
  {
    id: 22,
    title: "Dining Area",
  },
  {
    id: 23,
    title: "Custom designed carpets  ",
  },
  {
    id: 24,
    title: "Fresh flowers",
  },
  {
    id: 25,
    title: "Turndown service each evening ",
  },
  {
    id: 26,
    title: "Cable TV with movies on demand ",
  },
  {
    id: 27,
    title: "Bluetooth speaker ",
  },
  {
    id: 28,
    title: "Iron and ironing board",
  },
  {
    id: 29,
    title: "Electronic blinds",
  },
];

export const RoyalVilla = [
  {
    id: 1,
    title: "120 sq. ft Pool ",
  },
  {
    id: 2,
    title: "Living Room ",
  },
  {
    id: 3,
    title: "Mini bar ",
  },
  {
    id: 4,
    title: "Complimentary wireless and cabled internet  ",
  },
  {
    id: 5,
    title: "Breath toiletries  ",
  },
  {
    id: 6,
    title: "Plush bathrobes and slippers  ",
  },
  {
    id: 7,
    title: "Mini bar  ",
  },
  {
    id: 8,
    title: "USB charger ",
  },
  {
    id: 9,
    title: "Individually controlled air-conditioning ",
  },
  {
    id: 10,
    title: "Electronic Car ",
  },
  {
    id: 11,
    title: "2 Bedroom ",
  },
  {
    id: 12,
    title: "Pantry ",
  },
  {
    id: 13,
    title: "Feature beds  ",
  },
  {
    id: 14,
    title: "Private Garden   ",
  },
  {
    id: 15,
    title: "Daily room service  ",
  },
  {
    id: 16,
    title: "24 hour in-room dining   ",
  },
  {
    id: 17,
    title: "Bottled mineral water  ",
  },
  {
    id: 18,
    title: "110 volt sockets ",
  },
  {
    id: 19,
    title: "Hair dryer ",
  },
  {
    id: 20,
    title: "Electronic Generator ",
  },
  {
    id: 21,
    title: "2 Bathroom  ",
  },
  {
    id: 22,
    title: "Dining Area",
  },
  {
    id: 23,
    title: "Custom designed carpets  ",
  },
  {
    id: 24,
    title: "Fresh flowers",
  },
  {
    id: 25,
    title: "Turndown service each evening ",
  },
  {
    id: 26,
    title: "Cable TV with movies on demand ",
  },
  {
    id: 27,
    title: "Bluetooth speaker ",
  },
  {
    id: 28,
    title: "Iron and ironing board",
  },
  {
    id: 29,
    title: "Electronic blinds",
  },
];

export const RoyalGovernorsVilla = [
  {
    id: 1,
    title: "120 sq. ft Pool ",
  },
  {
    id: 2,
    title: "Living Room ",
  },
  {
    id: 3,
    title: "Mini bar ",
  },
  {
    id: 4,
    title: "Complimentary wireless and cabled internet  ",
  },
  {
    id: 5,
    title: "Breath toiletries  ",
  },
  {
    id: 6,
    title: "Plush bathrobes and slippers  ",
  },
  {
    id: 7,
    title: "Mini bar  ",
  },
  {
    id: 8,
    title: "USB charger ",
  },
  {
    id: 9,
    title: "Individually controlled air-conditioning ",
  },
  {
    id: 10,
    title: "Electronic Car ",
  },
  {
    id: 11,
    title: "3 Bedroom ",
  },
  {
    id: 12,
    title: "Pantry ",
  },
  {
    id: 13,
    title: "Feature beds  ",
  },
  {
    id: 14,
    title: "Private Garden   ",
  },
  {
    id: 15,
    title: "Daily room service  ",
  },
  {
    id: 16,
    title: "24 hour in-room dining   ",
  },
  {
    id: 17,
    title: "Bottled mineral water  ",
  },
  {
    id: 18,
    title: "110 volt sockets ",
  },
  {
    id: 19,
    title: "Hair dryer ",
  },
  {
    id: 20,
    title: "Electronic Generator ",
  },
  {
    id: 21,
    title: "3 Bathroom  ",
  },
  {
    id: 22,
    title: "Dining Area",
  },
  {
    id: 23,
    title: "Custom designed carpets  ",
  },
  {
    id: 24,
    title: "Fresh flowers",
  },
  {
    id: 25,
    title: "Turndown service each evening ",
  },
  {
    id: 26,
    title: "Cable TV with movies on demand ",
  },
  {
    id: 27,
    title: "Bluetooth speaker ",
  },
  {
    id: 28,
    title: "Iron and ironing board",
  },
  {
    id: 29,
    title: "Electronic blinds",
  },
];

export const RoyalQueensVilla = [
  {
    id: 1,
    title: "120 sq. ft Pool ",
  },
  {
    id: 2,
    title: " Smart Home System  ",
  },
  {
    id: 3,
    title: "Mini bar & Open Bar ",
  },
  {
    id: 4,
    title: "Complimentary wireless and cabled internet  ",
  },
  {
    id: 5,
    title: "Breath toiletries  ",
  },
  {
    id: 6,
    title: "Plush bathrobes and slippers  ",
  },
  {
    id: 7,
    title: "Mini bar  ",
  },
  {
    id: 8,
    title: "USB charger ",
  },
  {
    id: 9,
    title: "Individually controlled air-conditioning ",
  },
  {
    id: 10,
    title: "Electronic Car ",
  },
  {
    id: 11,
    title: "2 Bedroom ",
  },
  {
    id: 12,
    title: "Living Room & Pantry  ",
  },
  {
    id: 13,
    title: "Feature beds  ",
  },
  {
    id: 14,
    title: "Private Garden & Private Balcony   ",
  },
  {
    id: 15,
    title: "Daily room service  ",
  },
  {
    id: 16,
    title: "24 hour in-room dining   ",
  },
  {
    id: 17,
    title: "Bottled mineral water  ",
  },
  {
    id: 18,
    title: "110 volt sockets ",
  },
  {
    id: 19,
    title: "Hair dryer ",
  },
  {
    id: 20,
    title: "Electronic Generator ",
  },
  {
    id: 21,
    title: "2 Bathroom  ",
  },
  {
    id: 22,
    title: "Dining Area",
  },
  {
    id: 23,
    title: "Custom designed carpets  ",
  },
  {
    id: 24,
    title: "Fresh flowers",
  },
  {
    id: 25,
    title: "Turndown service each evening ",
  },
  {
    id: 26,
    title: "Cable TV with movies on demand ",
  },
  {
    id: 27,
    title: "Bluetooth speaker ",
  },
  {
    id: 28,
    title: "Iron and ironing board",
  },
  {
    id: 29,
    title: "Electronic blinds",
  },
  {
    id: 30,
    title: "Car Park",
  },
];

export const RoyalKingsVilla = [
  {
    id: 1,
    title: "120 sq. ft Pool ",
  },
  {
    id: 2,
    title: " Smart Home System  ",
  },
  {
    id: 3,
    title: "Mini bar & Open Bar ",
  },
  {
    id: 4,
    title: "Complimentary wireless and cabled internet  ",
  },
  {
    id: 5,
    title: "Breath toiletries  ",
  },
  {
    id: 6,
    title: "Plush bathrobes and slippers  ",
  },
  {
    id: 7,
    title: "Mini bar  ",
  },
  {
    id: 8,
    title: "USB charger ",
  },
  {
    id: 9,
    title: "Individually controlled air-conditioning ",
  },
  {
    id: 10,
    title: "Electronic Car ",
  },
  {
    id: 11,
    title: "2 Bedroom ( One Suite )  ",
  },
  {
    id: 12,
    title: "Living Room & Pantry  ",
  },
  {
    id: 13,
    title: "Feature beds  ",
  },
  {
    id: 14,
    title: "Private Garden & Private Balcony   ",
  },
  {
    id: 15,
    title: "Daily room service  ",
  },
  {
    id: 16,
    title: "24 hour in-room dining   ",
  },
  {
    id: 17,
    title: "Bottled mineral water  ",
  },
  {
    id: 18,
    title: "110 volt sockets ",
  },
  {
    id: 19,
    title: "Hair dryer ",
  },
  {
    id: 20,
    title: "Electronic Generator ",
  },
  {
    id: 21,
    title: "2 Bathroom  ",
  },
  {
    id: 22,
    title: "Dining Area",
  },
  {
    id: 23,
    title: "Custom designed carpets  ",
  },
  {
    id: 24,
    title: "Fresh flowers",
  },
  {
    id: 25,
    title: "Turndown service each evening ",
  },
  {
    id: 26,
    title: "Cable TV with movies on demand ",
  },
  {
    id: 27,
    title: "Bluetooth speaker ",
  },
  {
    id: 28,
    title: "Iron and ironing board",
  },
  {
    id: 29,
    title: "Electronic blinds",
  },
  {
    id: 30,
    title: "Car Park",
  },
];
