import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import "./Families.css";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Box-28.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Box-29.png";
import aboutHotelimg3 from "../../images/Selected/Box-30.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/Box-31.png";
import aboutHotelimg5 from "../../images/Selected/Box-32.png";
import aboutHotelimg6 from "../../images/Selected/Box-33.png";
import aboutHotelimg7 from "../../images/Selected/Box-34.png"; //chnage the img

import videobg from "../../images/Selected/HomeVideos/Families.m4v";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Families() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="familyHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Feels like home…
          "
          des1="No matter what age, time spent at Aviyana makes for an unforgettable family holiday. It is a destination that is sure to open a child’s eye to the beauty and
          wonders of the natural world and a truly life changing experience. Adventures for both children and adults at Aviyana are plentiful as our offering extends to
          the whole family ranging from visits to World Heritage Sites, a visit to our lush gardens? as well as yoga sessions, under the supervision of specially trained
          staff. All activities can be tailored to specifically to suit the interests of each family"
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg2}
          header="Memories to last a lifetime"
          des1="An exceptional experience awaits families - you will receive the VIP treatment from the moment you arrive. Expect a warm Sri Lankan welcome
          from our wonderful team and our resident butlers, followed by a welcome gift for children."
          des2=""
          des3=""
          linkVisible={true}
          link="/"
          linkName="View our multi-generational experiences "
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="family-intro-header mb-5 mt-5">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1  mb-4">
                  Exclusively for our youngest guests
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  <div className="family-list-sec row mt-5">
                    <ul className="family-list col">
                      <li className="family-list-item">
                        <p>Junior guests' menu</p>
                      </li>
                      <li className="family-list-item">
                        <p>Board and card games</p>
                      </li>
                      <li className="family-list-item">
                        <p>Junior guests' DVDs</p>
                      </li>
                      <li className="family-list-item">
                        <p>A welcome amenity</p>
                      </li>
                      <li className="family-list-item">
                        <p>LEGO Butler (additional charge)</p>
                      </li>
                      <li className="family-list-item">
                        <p>A morning visit from the estate Wolfhounds</p>
                      </li>
                      <li className="family-list-item">
                        <p>Personalised bedtime storybook</p>
                      </li>
                      <li className="family-list-item">
                        <p>
                          Movie matinee with popcornDistance from the Airport
                          and other locations – pls list can then expand
                        </p>
                      </li>
                    </ul>

                    <ul className="family-list col">
                      <li className="family-list-item">
                        <p>Experienced babysitters (additional charge)</p>
                      </li>
                      <li className="family-list-item">
                        <p>Cots and bedding for small babies</p>
                      </li>
                      <li className="family-list-item">
                        <p>Children’s Afternoon Tea (additional charge)</p>
                      </li>
                      <li className="family-list-item">
                        <p>Junior golf lessons (additional charge)</p>
                      </li>
                      <li className="family-list-item">
                        <p>Treasure hunt</p>
                      </li>
                      <li className="family-list-item">
                        <p>
                          Junior guests' friendly activities on the estate,
                          cycling, horse riding and tennis (all estate
                          activities are at an additional charge)
                        </p>
                      </li>
                    </ul>
                  </div>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="family-header-img-sec1"></div>
        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg3}
          header="The Aviyana Kids’ Butler
          "
          des1="The Aviyana Kids’ Butler is a bespoke service for our junior guests. We take into account the needs of our smallest guests
          and deliver what they need to your room on a silver tray. Please note, our Kids’ Butler is available on request only and a
          small fee would apply."
          des2=" "
          des3=""
          linkVisible={true}
          link="/"
          linkName="Enquire now"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg4}
          header="Chalet for the whole family"
          des1="Night times are just as special as the day at Aviyana with fluffy bathrobes and slippers to envelop guests in cosy comfort,
          and a delightful welcome amenity. Our beautiful Chalets are spacious and offer plenty of room offer luxurious and
          welcoming accommodation for the whole family. A true sanctuary those on holiday our Chalets offer two Queen size beds
          and a cosy relaxation area, with interconnecting rooms to allow total flexibility."
          des2=""
          des3=""
          linkVisible={true}
          link="/"
          linkName="Explore our family chalets "
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg5}
          header="A Magical destination
          "
          des1="With 24 acres ofEstate to explore, there are plenty of hidden adventures just waiting to be discovered. Our estate boasts many
          exhilarating activities, from Sri Lankan first air sports bay, to horse-riding, zip-wiring, tree-climbing andkayaking."
          des2=" "
          des3=""
          linkVisible={true}
          link="/"
          linkName="Discover our family activities "
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg6}
          header="Family Experience Butler"
          des1="Our dedicated Family Experience Butler is on hand to help you with any requests you may have to make your stay with us even more
          memorable. Their knowledge of our wonderful multigenerational experiences allows us to provide creative suggestions for activities
          and arrange special family activities for you to enjoy. No request is too large and no detail too small."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our family chalets "
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg7}
          header="Culinary adventures
          "
          des1="At Aviyana we make mealtimes fun! We understand that sometimes eating in a restaurant with little ones can be a challenge, and we do our utmost
          to accommodate the needs of the whole family, from adaptable seating to special cutlery and thoughtfully designed children’s menus. If your child is
          missing a favourite dish or yearning for something not found in our menu, please let us know and we will be happy to provide wherever possible."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover our family activities "
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}
        <div className="family-header-img-sec2"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Families;
