import img1 from "../galleryImages/img1.jpg";
import img2 from "../galleryImages/img2.jpg";
import img3 from "../galleryImages/img3.jpg";
import img4 from "../galleryImages/img4.jpg";
import img5 from "../galleryImages/img5.jpg";
import img6 from "../galleryImages/img6.jpg";
import img7 from "../galleryImages/img7.jpg";
import img8 from "../galleryImages/img8.jpg";
import img9 from "../galleryImages/img9.jpg";
import img10 from "../galleryImages/img10.png";
import img11 from "../galleryImages/img11.jpg";
import img12 from "../galleryImages/img12.jpg";

import galleryimg1 from "../images/galleryImg/galleryimg1.png";
import galleryimg2 from "../images/galleryImg/galleryimg2.png";
import galleryimg3 from "../images/galleryImg/galleryimg3.png";
import galleryimg4 from "../images/galleryImg/galleryimg4.png";
import galleryimg5 from "../images/galleryImg/galleryimg5.png";
import galleryimg6 from "../images/galleryImg/galleryimg6.png";
import galleryimg7 from "../images/galleryImg/galleryimg7.png";

const galleryData = [
  { id: 1, imgSrc: img1, name: "Image 01", text: "hello Sri Lanka 1" },
  { id: 2, imgSrc: img2, name: "Image 02", text: "hello Sri Lanka 2" },
  { id: 3, imgSrc: img3, name: "Image 03", text: "hello Sri Lanka 3" },
  { id: 4, imgSrc: img4, name: "Image 04", text: "hello Sri Lanka 4" },
  { id: 5, imgSrc: img5, name: "Image 05", text: "hello Sri Lanka 5" },
  { id: 6, imgSrc: img6, name: "Image 06", text: "hello Sri Lanka 6" },
  { id: 7, imgSrc: img7, name: "Image 07", text: "hello Sri Lanka 7" },
  { id: 8, imgSrc: img8, name: "Image 08", text: "hello Sri Lanka 8" },
  { id: 9, imgSrc: img9, name: "Image 09", text: "hello Sri Lanka 9" },
  { id: 10, imgSrc: img10, name: "Image 10", text: "hello Sri Lanka 10" },
  { id: 11, imgSrc: img11, name: "Image 11", text: "hello Sri Lanka 11" },
  { id: 12, imgSrc: img12, name: "Image 12", text: "hello Sri Lanka 12" },
];

export default galleryData;

export const gallaryPageImg = [
  { id: 1, gimg1: galleryimg1 },
  { id: 2, gimg2: galleryimg2 },
  { id: 3, gimg3: galleryimg3 },
  { id: 4, gimg4: galleryimg4 },
  { id: 5, gimg5: galleryimg5 },
  { id: 6, gimg6: galleryimg6 },
  { id: 7, gimg7: galleryimg7 },
];
