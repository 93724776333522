import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Wellness.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import AboutHotelWithBtn from "../../components/AboutHotelWithBtn";
import AboutHotelWithBtn2 from "../../components/AboutHotelWithBtn2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/wellness/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/wellness/Box-2.png";
import aboutHotelimg3 from "../../images/Selected/wellness/Box-3.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/wellness/Box-4.png";
import aboutHotelimg5 from "../../images/Selected/wellness/Box-5.png"; //chnage the img
import aboutHotelimg6 from "../../images/Selected/wellness/Box-6.png";

import explorer from "../../images/Selected/wellness/Wellness.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/spa/Wellness.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Wellness() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="WellnessHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 mt-5 ">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Wellness Ayurveda
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-5">
                  Ayurveda is India’s ancient healing system dating back more
                  than 5,000 years. Celebrated as the ‘Science of Life’ it
                  encourages balance in all things.
                  <br />
                  <p></p> At Aviyana, our peaceful haven is a place where you
                  can step back, slow down, and reconnect to your center, to
                  nature, and to a more authentic flow of life. Our Wellness
                  specialists at Aviyana draw on its wisdom in prescribing
                  treatments.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="wellness-header-img-sec1"></div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Recovery"></div>
        <AboutHotelWithBtn
          img={aboutHotelimg1}
          header="Recovery
          "
          des1="Our treatment will enable you to reach deep relaxation and rejuvenation. You can expect individual treatment and also effectively use your time to focus on balancing nutrition,
          strengthen your immune system resulting in improved immunity and energy, better metabolism and healthy, glowing, refreshed skin, besides regaining perfect normal health."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="ENQUIRE NOW"
        />
        <div className="space mt-5 mb-5" id="Detox"></div>
        <AboutHotelWithBtn2
          img={aboutHotelimg2}
          header="Detox
          "
          des1="In a world characterized by hectic and fast pace, peace and health are valuable. Our Detox program helps to detoxify body and mind, and a chance to refocus on the essentials
          in life. Our trained therapists prepare an Ayurvedic diet, individually tailored applications with cleansing on all levels of being. Programs may be customized for you to enjoy a
          new attitude towards life with lasting vitality. "
          des3=""
          linkVisible={false}
          link="/"
          linkName="ENQUIRE NOW"
        />
        <div className="space mt-5 mb-5" id="Antiaging"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Anti-aging
          "
          des1="We, at Aviyana, believe that the normal ageing process can be slowed down through alternative healing techniques, yoga and meditation. After detailed consultations on the
          possible reasons that could accelerate ageing, we recommend an ideal programme for the betterment of health and well-being in order to age gracefully."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="ENQUIRE NOW"
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="wellness-header-img-sec2"></div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Weightloss"></div>
        <AboutHotelWithBtn
          img={aboutHotelimg4}
          header="Weight loss
          "
          des1="Our holistic approach to sustainable weight loss blends mind and body. Guests will meet one-on-one with our experts to develop a customized healthy-eating plan. They will
          examine your relationship with food and level-up your fitness regimen to propel you forward achieving lasting results."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5" id="Sleep"></div>
        <AboutHotelWithBtn2
          img={aboutHotelimg5}
          header="Sleep
          "
          des1="When tension and stress become constant companions, it can be difficult for the body and mind to relax fully and experience ease. This treatment can help reset the nervous
          system to encourage deeper sleep and overcome insomnia issues."
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5" id="StressManagement"></div>

        <AboutHotelWithBtn
          img={aboutHotelimg6}
          header="Stress Management Rebalancing
          "
          des1="Many of us face everyday stresses which influence the body and the immune system. To combat this phenomena, we offer carefully composed and balanced treatments,
          nutritional basics and valuable inputs for a balanced mindset based. We will help you to find your inner balance, focus on your strengths and recharge your batteries to
          successfully master your everyday life."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="wellness-header-img-sec3"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Wellness;
