import React from "react";
import { useEffect } from "react";
import "./ChaletsSliders.css";
import { BsArrowRight } from "react-icons/bs";
import Button from "./Button";
import { HashLink } from "react-router-hash-link";

import AOS from "aos";
import "aos/dist/aos.css";
import { useRef } from "react";

function ChaletsSliders(props) {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 30,
    });
  }, []);

  return (
    <>
      <div className="chalet-slider-bar ">
        <div className="chalet-slider-sec ">
          <div className="chalet-slider-img ">
            {props.imgGallary.map((image) => (
              <div>
                <img src={image} alt="" className="slider-image" />
              </div>
            ))}
          </div>
          <div className="chalet-des-sec ">
            <div className="col " data-aos="fade-up">
              <div className="chalet-slider-header-sec">
                <h1 className="chalet-slider-header">{props.header}</h1>
              </div>
              <div className="chalet-slider-des-sec">
                <p className="chalet-slider-des">{props.describtion}</p>
              </div>
              <div className="chalet-slider-features">
                <div className="chalet-feature-list-sec">
                  {props.roomFeature.map((features) => (
                    <ul className="chalet-feature-list">
                      <li className={props.visible ? "featureItem" : "hide"}>
                        {features.title}
                      </li>
                    </ul>
                  ))}
                </div>
              </div>

              <div className="chalet-slider-booking-sec">
                <div className="chalet-slider-booking">
                  <a href="./PopUpserver">
                    <input
                      type="button"
                      className={"blacknew-navbar-booking-btn"}
                      value="BOOK"
                    />
                  </a>
                  <HashLink
                    to={props.link}
                    // onClick={() => redirectToPage(item.path)}
                    // className="submenu-item1"
                    className="chalet-slider-link"
                  >
                    Learn More
                    <BsArrowRight className="link-arrow" />
                  </HashLink>

                  {/* <a href={props.link} className="chalet-slider-link">
                    Learn More
                    <BsArrowRight className="link-arrow" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChaletsSliders;
