import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Location.css";
import LocationMap from "../../components/LocationMap";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Box-24.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Box-25.png";
import aboutHotelimg3 from "../../images/Selected/Box-26.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/Box-27.png";

import explorer from "../../images/Selected/Plan with us.png";
import videobg from "../../images/Selected/HomeVideos/location.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Location() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="locationHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 mt-5 pt-5">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address">KANDY, SRI LANKA</p>
              </div>
            </div>
          </div>
        </div>

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Paradise awaits…your journey to Aviyana…
          "
          des1="We are surrounded by 24 acres of beautifully manicured gardens, verdant forests and woodland rich with birdlife, all overlooking
          the shores of rippling Kandy, Sri Lanka. Your journey to Aviyana – from Colombo to Kandy, provides an unforgettable bird’s-eye
          perspective, a thrilling and entertaining experience. Revel in the unique and spectacular opportunity of flying over and admiring
          several kilometers our amazing shores and pristine nature with our private Helicopter ride."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg2}
          header="Open Air, new horizons…"
          des1="Begin a journey of inner peace and tranquillity. There is nowhere in the world like Aviyana. You will be surrounded by acres
          of rugged landscapes, natural wilderness, the pristine Knuckles Mountain range the chance to feel truly alive and where life
          slows down and everything is feels fresh and free."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="Location-header-img-sec1"></div>

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg3}
          header="Ground Access?
          "
          des1="Nearby attractions? Pls list what you need highlighted – can then expand
          Distance from the Airport and other locations – pls list can then expand"
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg4}
          header="Airport transfers
          "
          des1="Travel in comfort, arrive in style. Discover Aviyana and allow us to take all the effort of getting to and from our airports? with our
          private transfer service - for up to two persons and taking approximately xx minutes.We are also delighted to offer you chauffeured
          vehicles from the Airports to the Resort by a chauffeur-driven car. Leave the luggage to us, take a seat and enjoy an effortless journey"
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="Location-header-img-sec2"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Location;
