import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Covid19Update.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Box-47.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Box-48.png";
import aboutHotelimg3 from "../../images/Selected/Box-49.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/Box-50.png";

import videobg from "../../images/Selected/HomeVideos/Covid.m4v";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Covid19Update() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="Covid19Update-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        {/* des sec start */}
        <div className="space mt-2 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Your safety is a top priority
          "
          des1="As travel restrictions and guidelines continue to change, our helpful tool aims to make your journey as seamless and stressfree as possible. Check your journey to the Aviyana to find out all you need to know before travelling."
          des2=" "
          des3=""
          linkVisible={true}
          link="/"
          linkName="Find out more  "
        />
        <div className="space mt-5 mb-5"></div>

        <AboutHotel2
          img={aboutHotelimg2}
          header="Travel advisory"
          des1="Be sure to check for any specific country restrictions before traveling or contact the resort directly with your questions."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Health and Safety
          "
          des1="We follow the highest standards of cleanliness and continuously implement health and safety protocols. Throughout your
          stay, you will observe frequent cleaning and disinfecting of high-touch areas like lobbies and elevators. Complimentary
          travel-size hand sanitizers may be available at certain locations."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Find out more  "
        />
        <div className="space mt-5 mb-5"></div>

        <AboutHotel2
          img={aboutHotelimg4}
          header="Personal responsibility"
          des1="We have introduced enhanced hygiene protocols – for you, our other guests and our team members. An inherent risk of
          exposure to Covid-19 exists in any public place where people are present. We ask that you please take personal responsibility
          for your wellbeing. This begins with packing any personal protective equipment and sanitisers you require. In choosing to stay
          at Aviyana, you voluntarily assume all risks related to exposure to Covid-19. Let’s help keep each other safe and healthy."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="Covid19Update-header-img-sec1"></div>
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Covid19Update;
