import "bootstrap/dist/css/bootstrap.min.css";
import "./ExploreBars.css";
import { BsArrowRight } from "react-icons/bs";

//animation
import { useEffect } from "react";
import "./Animation.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AOS from "aos";
import "aos/dist/aos.css";
import { useRef } from "react";

function ExploreBars(props) {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 50,
      duration: 100,
      easing: "ease-in-sine",
      delay: 0,
    });
  }, []);

  const animRef2 = useRef(null);
  const animRef3 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef2.current,
      toggleClass: "active",
      end: "top -1500px",
    });
    ScrollTrigger.create({
      trigger: animRef3.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);

  return (
    <div>
      <div className="container-md mt-5 pt-5 pb-5 mb-5">
        <div className="row middle-sec  ">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div className="image">
              <div ref={animRef2} className="animation">
                <img src={props.BarImg1} alt="" />
              </div>
            </div>
            <div data-aos="fade-up" className="description mt-5">
              <h1 className="Exheader">{props.header1}</h1>
              <p className="desc2">{props.des1}</p>
              <a
                className="link2"
                // href={props.link1}
              >
                {props.linkName1}{" "}
                <BsArrowRight
                  className={props.arrowVisible1 ? "link-arrow-sc-5" : "hide"}
                />
              </a>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div className="image">
              <div ref={animRef3} className="animation">
                <img src={props.BarImg2} alt="" />
              </div>
            </div>
            <div data-aos="fade-up" className="description mt-5">
              <h1 className="Exheader">{props.header2}</h1>
              <p className="desc2">{props.des2}</p>
              <a
                className="link2"
                // href={props.link2}
              >
                {props.linkName2}{" "}
                <BsArrowRight
                  className={props.arrowVisible2 ? "link-arrow-sc-5" : "hide"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExploreBars;
