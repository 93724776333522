import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Entertainment.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/entertaintment/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/entertaintment/Box-2.png";
import aboutHotelimg3 from "../../images/Selected/entertaintment/Box-3.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/entertaintment/Box-4.png";

import explorer from "../../images/Selected/entertaintment/Entertaintment.png";

import videobg from "../../images/Selected/HomeVideos/exp/Entertainment.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Entertainment() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="entertainmentHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content " id="Authentic">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Authentic & Traditional Dance
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  The ritualistic dances of Sri Lanka have attained world fame
                  for their weird mystical beauty. Heavily influenced by India,
                  Sri Lanka's Kandyan dance and kōlam plays have South Indian
                  origins. But over the centuries these have been transformed
                  and now have a distinctly Sri Lankan character.There are
                  several different types of traditional dance. These include
                  Kandyan dances, Sabaragamuwa dances, low-country dances, devil
                  dances, and several others. Kandyan dances are probably the
                  most important of the various Sri Lankan traditional dances.
                  It is generally regarded as the national dance form. Kandyan
                  dances evolved in the Kandy Kingdom. This was the kingdom in
                  the interior of Sri Lanka that proved most resilient against
                  the Europeans. Many of the dances depict stories associated
                  with the Kandy kings, queens, princes and heroes.
                  <br />
                  <p></p>The dance style probably is a good representation of
                  dances in Kandy. Kandy costumes can be very impressive. Male
                  dance costumes can include spectacular headwear. Bare chests
                  are decorated with intricate silver regalia. There are also
                  silver bangles on arms and anklets. The dances are set to drum
                  rhythms. The dance itself is highly ritualistic. In devil
                  dances, the dances are performed by the artists who wear
                  different kind of masks and it also used as a treatment for
                  diseases caused by “Unseen Hands”. Many of these dances are
                  performed in solo and also group performances.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="entertaintment-header-img-sec1"></div>

        <div className="Header-Content " id="ArtAndMusic">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Art & Music Studio
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Colour, creativity, and curiosity – the walls, floors, and
                  interior spaces of Aviyana showcase pieces of art that are
                  bold and unapologetic.If you’re looking for a creative
                  hideaway to record and mix music, welcome to Music+Arts, a
                  state of the art recording facility featuring the perfect
                  blend of analog and digital gear.
                  <br />
                  <p></p>
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="entertaintment-header-img-sec2"></div>

        <div className="Header-Content " id="Musicsessions">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Music sessions
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Attend a concert or host an event with live music in our music
                  venues at the Aviyana. We've taken great care to ensure that
                  our venues are unique, making our event spaces an
                  extraordinary destination for music lovers. Whether you're
                  planning to host a lavish event with a live band or a
                  corporate function with a private acoustic set, you can have
                  your pick of an outdoor venue with a stage or an indoor venue
                  on the Aviyana.
                  <br />
                  <p></p>
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="entertaintment-header-img-sec3"></div>

        {/* des sec start */}
        <div className="space mt-2 mb-5" id="GemAuctions"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Gem & Jewellery Auctions
          "
          des1="Aviyana Gem & Jewellery Auction is one of the world's foremost innovative auctioneers and appraisers of jewellery gemstones and coins. Aviyana gem & Jewellery
          Auction offers weekly sales throughout the year that attract a broad base of buyers and consignors from around the world. Aviyana Gem & Jewellery Auctions an
          established reputation for professionalism, integrity, expertise and service.You’ll find pieces to suit all styles and budgets in our jewellery auctions, including rare, unique
          and vintage as well as new items and loose gemstones."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="AntiqueAuctions"></div>

        <AboutHotel2
          img={aboutHotelimg2}
          header="Antique Auctions "
          des1="There are some of us who live by the saying, ‘old is gold’, and that’s why we hold on to those quirky valuables and collectibles for as long as we can. Be it rare old ornaments,
          maps, paintings or furniture, door and window frames; there are people who just can’t resist but fall in love with their old charm. Lucky for them, there are many Sri Lankans that
          sell antiques, and also offer a wide collection to select from. Walk into our actions, and we guarantee that you won’t be disappointed."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5" id="CocktailEvenings"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Cocktail Evenings
          "
          des1="Unwind after a day out in Kandy and sip some of the best made classic cocktails, or try our inventive new mixes filled with surprises and exotic local ingredients. For whiskey
          aficionados choose from an exhaustive range of single malt whiskeys or just simply soak up the relaxing ambience of the unique, inimitable Residence bar.Our Bar + Kitchen
          Signature Cocktails take you through a unique list of local ingredients, with creatively crafted specialities featuring.A delicious tapas menu created by our Bar + Kitchen Chef is
          also available to satisfy gourmets, served by our professional barmen, who are adept at adapting to every guest’s wishes."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="FirePit"></div>

        <AboutHotel2
          img={aboutHotelimg4}
          header="Fire Pit

          "
          des1="You’re beautifully chalet in the views features an outdoor terrace with fire pit for relaxing in the evenings. The fire pit in this suite offers all of the privacy you would
          expect from an in-room fire pit. It is mostly for ambiance and doesn’t generate the heat that many of the fire pits we are featuring. And also the fire pit area
          provides for a more intimate setting perfect for your small party.
          "
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="entertaintment-header-img-sec4"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Entertainment;
