import React, { useState } from "react";
import { Link } from "react-router-dom";
import { theChaletsDropdown } from "./NavItems";
import "./ChaletsDropdown.css";
import { HashLink } from "react-router-hash-link";

function ChaletsDropdown() {
  const [dropdown, setDropdown] = useState(false); // create state

  const redirectToPage = (a) => {
    window.location = a;
  };

  return (
    <>
      <div className="chalet-subnav-bar-sec">
        <div className="chaler-subnav-bar">
          <div className="chaler-list-sec">
            <ul className="chaler-subnav-list row">
              {theChaletsDropdown.map((item) => {
                return (
                  <li key={item.id}>
                    <HashLink
                      to={item.path}
                      // onClick={() => redirectToPage(item.path)}
                      className="chaler-subnav-item col"
                    >
                      {item.title}
                    </HashLink>

                    {/* <Link
                      to={item.path}
                      className="chaler-subnav-item col"
                      // onClick={() => redirectToPage(item.path)}
                    >
                      {item.title}
                    </Link> */}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChaletsDropdown;
