import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavBar from "./components/Navbar.js";
import Home from "./pages/Home";
import Chalets from "./pages/Chalets";
import Offers from "./pages/Offers";
import Experience from "./pages/Experience";
import Dinning from "./pages/Dinning";
import Spa from "./pages/Spa";
import Wedding from "./pages/Wedding";
import Bouti from "./pages/Bouti";
import About from "./pages/SubPages/About";
import ReasonsWeAre from "./pages/SubPages/ReasonsWeAre";
import AviyanaConcept from "./pages/SubPages/AviyanaConcept";

import Aviyana from "./pages/SubPages/Aviyana.js";
import Sustainability from "./pages/SubPages/Sustainability";
import Awards from "./pages/SubPages/Awards.js";
import ImgGallaryPage from "./pages/SubPages/ImgGallaryPage";
import InPress from "./pages/SubPages/InPress";
import Location from "./pages/SubPages/Location";
import Families from "./pages/SubPages/Families";
import NavigationBar from "./components/NavigationBar";
import NewNavBar from "./components/NewNavBar";
import LuxuryTownCountry from "./pages/SubPages/LuxuryTownCountry";
import WellnessEscape from "./pages/SubPages/WellnessEscape";
import Pets from "./pages/SubPages/Pets";
import WeddingAndEvents from "./pages/SubPages/WeddingAndEvents";
import VirtualTour from "./pages/SubPages/VirtualTour.js";
import MeetOurTeam from "./pages/SubPages/MeetOurTeam.js";
import Accessibility from "./pages/SubPages/Accessibility.js";
import Covid19Update from "./pages/SubPages/Covid19Update.js";
import SpecialService from "./pages/SubPages/SpecialService.js";
import OutdoorAdventure from "./pages/SubPages/OutdoorAdventure.js";
import Sports from "./pages/SubPages/Sports.js";
import CULTURE from "./pages/SubPages/CULTURE";
import Entertainment from "./pages/SubPages/Entertainment.js";
import DiningAndDrinks from "./pages/SubPages/DiningAndDrinks.js";
import Restayrants from "./pages/SubPages/Restayrants.js";
import Bars from "./pages/SubPages/Bars.js";
import ExperienceSub from "./pages/SubPages/ExperienceSub.js";
import SpaFacilities from "./pages/SubPages/SpaFacilities.js";
import Ashram from "./pages/SubPages/Ashram";
import Wellness from "./pages/SubPages/Wellness";
import Partners from "./pages/SubPages/Partners";
import Members from "./pages/Members.js";
import GitfVouchers from "./pages/GitfVouchers.js";
import DestinationWeddings from "./pages/SubPages/DestinationWeddings.js";
import Honeymoons from "./pages/SubPages/Honeymoons.js";
import Conferences from "./pages/SubPages/Conferences.js";
import Birthdays from "./pages/SubPages/Birthdays.js";
import DepartmentofRomance from "./pages/SubPages/DepartmentofRomance.js";
import DepartmentofHappiness from "./pages/SubPages/DepartmentofHappiness.js";
import Faqs from "./pages/SubPages/Faqs";
import ChaletsSubPage from "./pages/SubPages/ChaletsSubPage.js";
import PopUpserver from "../src/components/PopUpserver.js";
function App() {
  return (
    <Router>
      {/* <NewNavBar /> */}

      <Switch>
        <Route exact path="/">
          <NewNavBar />
          <Home />
        </Route>

        <Route path="/chalets">
          <NewNavBar />
          <Chalets />
        </Route>

        <Route path="/offers">
          <NewNavBar />
          <Members />
        </Route>

        <Route path="/experiences">
          <NewNavBar />
          <Experience />
        </Route>

        <Route path="/discover">
          <NewNavBar />
          <Dinning />
        </Route>

        <Route path="/spa">
          <NewNavBar />
          <Spa />
        </Route>

        <Route path="/wedding">
          <NewNavBar />
          <Wedding />
        </Route>
        <Route path="/boutique">
          <NewNavBar />
          <Bouti />
        </Route>

        <Route path="/Aviyana">
          <NewNavBar />
          <Aviyana />
        </Route>

        <Route path="/reasonsWeAreUnique">
          <NewNavBar />
          <ReasonsWeAre />
        </Route>
        <Route path="/aviyanaConcept">
          <NewNavBar />
          <AviyanaConcept />
        </Route>
        <Route path="/Sustainability">
          <NewNavBar />
          <Sustainability />
        </Route>
        <Route path="/Awards">
          <NewNavBar />
          <Awards />
        </Route>
        <Route path="/imageGallery">
          <NewNavBar />
          <ImgGallaryPage />
        </Route>
        <Route path="/InThePress">
          <NewNavBar />
          <InPress />
        </Route>
        <Route path="/location">
          <NewNavBar />
          <Location />
        </Route>
        <Route path="/families">
          <NewNavBar />
          <Families />
        </Route>
        <Route path="/luxury-town-country-staycation">
          <NewNavBar />
          <LuxuryTownCountry />
        </Route>
        <Route path="/wellness-escapes">
          <NewNavBar />
          <WellnessEscape />
        </Route>
        <Route path="/pets">
          <NewNavBar />
          <Pets />
        </Route>
        <Route path="/weddingAndevents">
          <NewNavBar />
          <WeddingAndEvents />
        </Route>

        <Route path="/VirtualTour">
          <NewNavBar />
          <VirtualTour />
        </Route>
        <Route path="/MeetOurTeam">
          <NewNavBar />
          <MeetOurTeam />
        </Route>
        <Route path="/Accessibility">
          <NewNavBar />
          <Accessibility />
        </Route>
        <Route path="/Covid19Update">
          <NewNavBar />
          <Covid19Update />
        </Route>

        <Route path="/SpecialService">
          <NewNavBar />
          <SpecialService />
        </Route>
        <Route path="/OutdoorAdventure">
          <NewNavBar />
          <OutdoorAdventure />
        </Route>

        <Route path="/Sports">
          <NewNavBar />
          <Sports />
        </Route>

        <Route path="/Culture">
          <NewNavBar />
          <CULTURE />
        </Route>

        <Route path="/Entertainment">
          <NewNavBar />
          <Entertainment />
        </Route>

        <Route path="/DiningAndDrinks">
          <NewNavBar />
          <DiningAndDrinks />
        </Route>

        <Route path="/Restaurants">
          <NewNavBar />
          <Restayrants />
        </Route>
        <Route path="/Bars">
          <NewNavBar />
          <Bars />
        </Route>
        <Route path="/ExperienceSubItems">
          <NewNavBar />
          <ExperienceSub />
        </Route>

        <Route path="/spaFacilities">
          <NewNavBar />
          <SpaFacilities />
        </Route>
        <Route path="/Ashram">
          <NewNavBar />
          <Ashram />
        </Route>
        <Route path="/Wellness">
          <NewNavBar />
          <Wellness />
        </Route>
        <Route path="/Partners">
          <NewNavBar />
          <Partners />
        </Route>
        <Route path="/Faqs">
          <NewNavBar />
          <Faqs />
        </Route>
        <Route path="/DestinationWeddings">
          <NewNavBar />
          <DestinationWeddings />
        </Route>
        <Route path="/Honeymoons">
          <NewNavBar />
          <Honeymoons />
        </Route>
        <Route path="/Conferences">
          <NewNavBar />
          <Conferences />
        </Route>
        <Route path="/Birthdays">
          <NewNavBar />
          <Birthdays />
        </Route>
        <Route path="/DepartmentofRomance">
          <NewNavBar />
          <DepartmentofRomance />
        </Route>
        <Route path="/DepartmentofHappiness">
          <NewNavBar />
          <DepartmentofHappiness />
        </Route>
        <Route path="/ChaletsDetails">
          <NewNavBar />
          <ChaletsSubPage />
        </Route>
        <Route path="/PopUpserver">
          <PopUpserver />
        </Route>
        <Route path="/gift-vouchers">
          <GitfVouchers />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
