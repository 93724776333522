import React from "react";
import Slider from "react-slick";

import "./EXperinceImgSlider.css";
import { useRef } from "react";

//animation
import { useEffect } from "react";
import "./Animation.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AOS from "aos";
import "aos/dist/aos.css";

import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
// import { useRef } from "react";

function EXperinceImgSlider(props) {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);

  const sliderRef = useRef(null);
  const sliderProperties = {
    ref: sliderRef,

    className: "center",
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 2000,
    dots: false,
    draggable: true,
    arrows: false,

    centerMode: true,
    infinite: true,
    centerPadding: "100px",
    slidesToShow: 1,
    speed: 500,
  };

  return (
    <>
      <div className="experience-sec ">
        <div className="row mt-5 mb-5">
          <div className="ExslidingImage-sec">
            <div className="ex-slider-btn1">
              <BsArrowLeft
                className="ex-slider-arrow"
                onClick={() => sliderRef.current.slickPrev()}
              />
            </div>
            <div className="exp-header mt-4 mb-5">
              <h1>{props.header}</h1>
            </div>
            <div className="ex-slider">
              <Slider {...sliderProperties}>
                {props.arrayName.map((image) => (
                  <div className="ex-slider-img">
                    <div className="ex-slider-img-sec">
                      <img src={image.img} alt="" />
                    </div>
                    <div data-aos="fade-up" class="text-on-image">
                      <h1> {image.title} </h1>
                      <p>
                        <a className="ex-slider-link" href={image.link}>
                          Explore more <BsArrowRight className="link-arrow" />
                        </a>
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="ex-slider-btn2">
              <BsArrowRight
                className="ex-slider-arrow"
                onClick={() => sliderRef.current.slickNext()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EXperinceImgSlider;
