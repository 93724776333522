import React from "react";
import { useRef } from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Birthdays.css";
import AboutHotelWithBtn from "../../components/AboutHotelWithBtn";
import AboutHotelWithBtn2 from "../../components/AboutHotelWithBtn2";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Wedding/Birthdays/Box-2.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Wedding/Birthdays/Box-1.png";

import explorer from "../../images/Selected/Wedding/Birthdays/Celebrations.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/wedding/Birthday.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function checkScreen() {
  if (window.innerWidth > 1200) {
    document.getElementById("mainDivSec").style.className =
      "container mt-5 mb-5";
  } else {
    document.getElementById("mainDivSec").style.className =
      "container-fluid mt-5 mb-5";
  }
}

function Birthdays() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);
  return (
    <>
      <div className="DestinationHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 ">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 ">
                  We all love birthdays.
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 ">
                  {" "}
                  Whether is hosting your kids, surprising your friend, relative
                  or significant other we will help you plan any age birthday
                  party or celebration!. Make your next birthday celebration
                  something your group will never forget – and a day when you
                  can relax. We will take care of all the party details and
                  planning leaving you to focus on creating lasting memories.
                  <p></p> Our outdoor activities have something to offer for
                  everyone.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Spafacilities"></div>
        <AboutHotelWithBtn
          img={aboutHotelimg1}
          header="Majestic & Dramatic
          "
          des1="A successful event requires a suitably impressive and remarkable venue – and Aviyana is the perfect choice. With its misty mountains, surrounded by emerald forest and sparkling waters, one cannot fail to gasp in astonishment. Once inside, the grandeur of the interiors, the splendour of the furnishings, and the warmth of our welcome will enthral you."
          des2=" 
          "
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="TheSalon"></div>
        <AboutHotelWithBtn2
          img={aboutHotelimg2}
          header="Private Occasions "
          des1="The Aviyana offers a unique opportunity for its multiple function areas to host private functions successfully. Each function area provides the right mood and ambiance for birthday / anniversary parties, cocktail parties, formal launches and dinner events, children’s birthday parties etc.
          We offer a stunning range of outdoor spaces, rest assured that the setting will be just right. Couple those with Aviyana’s unfaltering attention to service, and it’s easy to see why it’s the ideal location
          Let us take care of the finer details and make your private event a resounding success.
          "
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="Spabrands"></div>

        {/* des sec end */}

        <div className="Birthday-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Birthdays;
