// import "bootstrap/dist/css/bootstrap.min.css";
import "./AboutHotel2.css";
import "./AboutHotelWithBtn2";
import { BsArrowRight } from "react-icons/bs";
//chnage the img

//animation
import { useEffect } from "react";
import "./Animation.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AOS from "aos";
import "aos/dist/aos.css";
import { useRef } from "react";

function AboutHotelWithBtn2(props) {
  // text animation
  // use "fade-up" class name with this function

  function checkScreen() {
    if (window.innerWidth > 1200) {
      document.getElementById("mainDivSec").style.className =
        "container mt-5 mb-5";
    } else {
      document.getElementById("mainDivSec").style.className =
        "container-fluid mt-5 mb-5";
    }
  }
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);

  return (
    <div onLoad={checkScreen} className="container mt-5 mb-5" id="mainDivSec">
      <div className="row about-content">
        <div className="about-hotel-bar">
          <div className="img-sec col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div className="img-area">
              <a className="linked-image" href="/">
                {/* img animation */}
                <div ref={animRef1} className="animation">
                  <img
                    className="AboutHotel-img"
                    src={props.img}
                    alt="castle"
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="txt-area col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div
              data-aos="fade-up" //text animation
              className="txt-sec "
            >
              <h1 className="header-welcome ">{props.header}</h1>
              <p className="desc-chapter-11 row">{props.des1}</p>
              <p className="desc-chapter-22">{props.des2}</p>
              <p className="desc-chapter-22">{props.des3}</p>
              <a
                className={props.linkVisible ? "link111" : "hide"}
                href={props.link}
              >
                <input
                  type="button"
                  name=""
                  id=""
                  value={props.linkName}
                  className="enquireBtn"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutHotelWithBtn2;
