import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./MeetOurTeam.css";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import videobg from "../../images/Selected/HomeVideos/Meet.m4v";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function MeetOurTeam() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="MeetOurTeamHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="sustainability-intro-header mt-5 pt-5">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1  mb-4">
                  Our Passionate Team
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-5">
                  We offer hospitality from the heart. Our passionate team of
                  professionals are always on hand to assist you during your
                  stay with utmost care and consideration. Leave everything in
                  their hands and they will make it happen
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="Meet-header-img-sec2"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default MeetOurTeam;
