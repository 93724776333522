import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./OutdoorAdventure.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/experiences/Box-2.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/experiences/Box-3.png";
import aboutHotelimg3 from "../../images/Selected/experiences/Box-4.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/experiences/Box-5.png";
import aboutHotelimg5 from "../../images/Selected/experiences/Box-6.png"; //chnage the img
import aboutHotelimg6 from "../../images/Selected/experiences/Box-7.png";
import aboutHotelimg7 from "../../images/Selected/experiences/Box-8.png"; //chnage the img
import aboutHotelimg8 from "../../images/Selected/experiences/Box-9.png";
import aboutHotelimg9 from "../../images/Selected/experiences/Box-10.png";
import aboutHotelimg10 from "../../images/Selected/experiences/Box-11.png"; //chnage the img
import aboutHotelimg11 from "../../images/Selected/experiences/Box-12.png";
import explorer from "../../images/Selected/experiences/Explorer.png";

import videobg from "../../images/Selected/HomeVideos/exp/OutdoorAdventure.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function OutdoorAdventure() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="outdoorAdventureHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content ">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Explore activities for mind body and soul
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Our expansive 24-acre estate lends itself perfectly to a
                  selection of exhilarating activities and experiences. The
                  grounds of Aviyana have provided space for thrilling estate
                  activities for its owners and their clients.
                  <br />
                  <p></p>The expansive 24-acre Aviyana Estate lends itself
                  perfectly to a range of activities, such as horse riding,
                  fishing,Air Ballooning, Bird watching, River Walk, Biking Tour
                  & Cycling, Scenic Walking, Hiking & Tracking Estate gardens,
                  Equestrian Jeep Safari Camping Day/Night, River Drifting,
                  Guided Nature Experiences,High tea by the lake to more modern
                  activities such as sky diving and cycling. So whether you are
                  the active type or prefer to relax and soak up the scenery,
                  you are spoilt for choice at Aviyana.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="outdoorAdventure-header-img-sec1"></div>

        {/* des sec start */}
        <div className="space mt-2 mb-5" id="BirdWatching"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Bird watching
          "
          des1="Sri Lanka’s biodiversity is rich and diverse. With more than 500 species recorded of which 34 are considered endemic. The area surrounding Aviyana and the Knuckles Forest
          reserve is undoubtedly a paradise for ornithologists and bird lovers. The mountain range has recorded 128 bird species of which 17 are endemic to the region. Even discover a
          new passion for bird watching while staying at Aviyana and become enchanted with countless hours witnessing nature’s wonders and a contented spirit."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="BikingTour"></div>

        <AboutHotel2
          img={aboutHotelimg2}
          header="Biking and Cycling Tour"
          des1="There is much to discover in Udispaththuwa, Sri Lanka’s ancient citadel in the hills, and Aviyana’s backyard. Steeped in history, there is no better way to take in the scenic
          surroundings blended with real mountain biking adventures. Our X kms full day fully-guided cycling tour will provide a nice on and off road biking experience creating an
          unforgettable and memorable activity during your stay in Sri Lanka."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5" id="ScenicWalking"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Scenic Walking, Hiking, and Tracking
          "
          des1="One of the best ways to experience the beauty and surroundings of Aviyana, is to go on a long scenic bike ride as our mountain range offers some of the most spectacular hikes
          in the island. The Knuckles Hike begins in a tea estate, entering high-altitude montane rain forests that are truly unique and stunning. The diverse tail offers stunning views, and
          your destination will undoubtedly be mind boggling."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="Estategardens"></div>

        <AboutHotel2
          img={aboutHotelimg4}
          header="Estate gardens 
          "
          des1="We pride ourselves on the magical setting of the Aviyana gardens. Combining a wonderful balance of formal and informal areas, discover borders of fruit and vegetables, or even
          stumble upon a small stream flowing through the gardens. Intimate walkways create the perfect pace to leisurely stroll and enjoy both the wildlife and natural environment."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="outdoorAdventure-header-img-sec2"></div>

        {/* des sec start */}
        <div className="space mt-2 mb-5" id="RiverWalk"></div>
        <AboutHotel
          img={aboutHotelimg5}
          header="River Walk
          "
          des1="Enjoy a casual walk through our own estate to the river that borders Aviyana. Our resident naturalist will guide you down a steep path to the shores. Along the way meet people
          harvesting tea on our estate and observe the local flora and fauna. The river shore is calm and serene, a great place to relax and catch your breath before heading back to the
          resort."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="Equestrian"></div>

        <AboutHotel2
          img={aboutHotelimg6}
          header="Equestrian"
          des1="A unique activity offered at Aviyana, discover the natural beauty of our countryside while riding our gentle trail horses. The beautiful woodland paths and spectacular scenery
          from the saddle will captivate novice and experienced riders alike, especially trials the from the Heel oya and into the Knuckles mountains to the west. Lessons and trail rides are
          available for all ages and abilities and are accompanied by an experienced guide."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5" id="JeepSafari"></div>

        <AboutHotel
          img={aboutHotelimg7}
          header="Jeep Safari
          "
          des1="Up for an adventurous tour? Our exciting jeep safari will drive you through the Knuckles forest passing plantations, stunning rural scenery, and off the beaten track into areas
          where few tourists ever see. As you climb higher and higher you will go deep into the dense mountain forest, from Mahiyanganaya you will head towards west, up to Sri Lanka’s
          mountainous terrain and tea country."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="Camping"></div>

        <AboutHotel2
          img={aboutHotelimg8}
          header="Camping Day/Night
          "
          des1="Our Aviyana Luxury Mobile Tented Safari Camps offer an adventure that inspires and dazzles. Choose from many locations surrounding the
          Knuckles mountain range, or to suit your own requirements. We offer you the choice of two different Camping Options - Elite and Explorer.
          Whatever your preference, we can tailor-make an exploration holiday just for you. Go on a wildlife holiday with us and experience the outdoors like
          never before."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}
        <div className="outdoorAdventure-header-img-sec3"></div>

        {/* des sec start */}
        <div className="space mt-2 mb-5" id="RiverDrifting"></div>
        <AboutHotel
          img={aboutHotelimg9}
          header="River Drifting

          "
          des1="Experience a memorable white water rafting adventure, in Sri Lanka’s Galmal oya and Heel ova as it flows across beautiful and panoramic series of rings of
          mountains. Covering X major rapids and X miner rapids, this activity is for anyone above the age of 10 years with safety gear and modern rafts with
          comprehensive safety briefing given by Aviyana specialists."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5" id="GuidedNature"></div>

        <AboutHotel2
          img={aboutHotelimg10}
          header="Village Guided Nature Experiences "
          des1="Get a feel of typical village life in addition to walking and sight-seeing culminating with a culinary experience in true Sri Lankan style at Heeloya - a rural, remote
          agricultural village in the Kandy district. Paddy cultivation, home gardening, livestock, spice plantations, toddy tapping, handicrafts, bee-keeping are among the
          attractions this village experience offers. The soothing surrounding of the walking paths will be definitely memorable and treasured."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5" id="Hightea"></div>

        <AboutHotel
          img={aboutHotelimg11}
          header="High tea by the lake
          "
          des1="Our high-tea experience by the lake is truly special, taking the art of dining to a new level. Our team will pick a beautiful spot where guests can indulge in some exquisite creations
          from our pastry kitchen including an exquisite selection of scones, tarts, and savory bites including a carefully selected and crafted tea menu designed to complement the spread. "
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />

        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default OutdoorAdventure;
