import { useEffect } from "react";
import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import TheAviyana from "../../images/Selected/TheAviyana.png";
import "./Aviyana.css";
import AOS from "aos";
import "aos/dist/aos.css";

import Footer from "../../components/Footer";
import StayInTouch from "../../components/StayInTouch";
import NewCheckDate from "../../components/NewCheckDate.js";

import videobg from "../../images/Selected/HomeVideos/Aviyana.m4v"; //replace the home banner to video

function Aviyana() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="Aviyana-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />

              {/* <h1>AVIYANA</h1> */}
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
              {/* <p>
                Leave the cares of the world behind, and reconnect with nature,
                rejoicing in a place where the only sound is the calming rhythm
                of Sri Lanka’s most spectacular Knuckles Mountain range.
              </p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content ">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <img src={TheAviyana} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  {/* header title */}
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Leave the cares of the world behind, and reconnect with
                  nature, rejoicing in a place where the only sound is the
                  calming rhythm of Sri Lanka’s most spectacular Knuckles
                  Mountain range.
                  <br />
                  <p></p>. With x degree views, Aviyana Private Chalets, nestled
                  in the picturesque hills, will help you explore your
                  wellbeing, surrounded by timeless serenity. We provide you an
                  unparalleled 7-star experience, a year-round destination to
                  escape and feed the soul, in tangible and intangible ways.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* landscape img 1 */}
        <div className="Aviyana-header-img-sec1"></div>

        <div className="Header-Content pb-5">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-2 mb-4">
                  Concept origins and how did we get here?
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-4">
                  Aviyana is located in the stunning famed peaks of the UNESCO
                  declared World Heritage Site. The subtropical rainforest
                  terrain offers a wide array of wildlife, natural scenery, and
                  plants to admire. The resort is located parallel to the
                  unceasingly flowing brooks of the Gal Mal Oya and Heel Oya at
                  the foothills of Knuckles. <p></p>
                  Highly ecological and rich in biodiversity, mountain peaks,
                  the crystal clear and perennial waterways, cloud forests and
                  exquisite fauna and flora, Knuckles is replete with history
                  running into several millennial, a veritable treasure house of
                  cultural heritage and a mirror to the past. <p></p>
                  Aviyana is majestically situated atop a towering rock with
                  breathtaking mountain views. The first ever 7-start hotel with
                  incomparable views of the Knuckles peaks. Being 2000 above sea
                  level, offers the ultimate in rest and rejuvenation. Guest are
                  surrounded by the purest air quality. The transitional state
                  of the climate creates a spectacular experience, that is
                  seldom found in such startling variety - where guests can
                  experience mist, fog, clouds and even heaven within touching
                  distance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="AviyanaLandscape-header-img-sec2"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Aviyana;
