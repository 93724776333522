import React from "react";
import "./WellnessEscape.css";
import NewCheckDate from "../../components/NewCheckDate.js";
import Footer from "../../components/Footer";
import StayInTouch from "../../components/StayInTouch";
import AboutHotel2 from "../../components/AboutHotel";
import wellnessImg1 from "../../images/wellnessImg1.webp";

import { BsArrowRight } from "react-icons/bs";
import { useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function WellnessEscape() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="Wellness-header">
        {/* <video autoPlay muted loop source src={videobg} /> */}
        <div className="container">
          <div data-aos="fade-up" className=" header-txt">
            <p></p>
            <h1>
              <br />
            </h1>
            <h4>
              <br />
            </h4>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />

        <div className="luxuryStaycation  mb-5">
          <div className="luxuryStaycation-bar ">
            <div className="luxuryStay-header mt-5 mb-5">
              <h2>Wellness escapes</h2>
            </div>
            <div className="Wellness-des-sec mt-5 mb-5">
              <div className="Wellness-des">
                <p>
                  To enhance your sense of wellbeing even further we invite you
                  to visit one of Ireland’s most wonderful spas for some extra
                  indulgence and pampering.
                </p>
                <p>
                  Our expert therapists offer a collection of treatments to
                  soothe, refresh and rejuvenate body and mind.
                </p>
              </div>
              <div className="luxuryStay-call-and-book-sec">
                <div className="luxuryStay-call-and-book">
                  <p className="luxuryStay-call">Call +353 94 954 6003 or</p>
                  <input
                    type="button"
                    className="luxuryStay-book-btn"
                    value="BOOK"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <AboutHotel2
          img={wellnessImg1}
          header="Explore our wellness offers"
          des1="The Spa at Ashford Castle was awarded 'Ireland’s Best Hotel Spa' in 2021 for the 6th year in a row by World Spa Awards and we were also voted 'Best in Sustainability' in the 2019 Irish Tatler Awards."
          des2="We are dedicated to restoring beauty and balance through treatments and spa journeys, personalised for each of our guests by exceptionally skilled therapists."
          des3="personalised for each of our guests by exceptionally skilled therapists. Guests staying with us on a wellness escape will leave feeling renewed and restored."
          linkVisible={false}
          link="/"
          linkName="Discover the history of Ashford"
        />

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default WellnessEscape;
