import React from "react";
import giftLogo from "../images/siteLogo/logo.png";
import "./GitfVouchers.css";

function GitfVouchers() {
  return (
    <>
      {/* logo section */}
      <div className="gift-logo-sec">
        <div className="gift-logo-bar">
          <div className="gift-logo">
            <img src={giftLogo} alt="" />
          </div>
        </div>
      </div>
      <div className="gift-home"></div>
    </>
  );
}

export default GitfVouchers;
