import { useRef } from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import "./Pets.css";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Box-35.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Box-36.png";
import aboutHotelimg3 from "../../images/Selected/Box-37.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/Box-38.png";
import videobg from "../../images/Selected/HomeVideos/Pets.m4v";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Pets() {
  function checkScreen() {
    if (window.innerWidth > 1200) {
      document.getElementById("mainDivSec").style.className =
        "container mt-5 mb-5";
    } else {
      document.getElementById("mainDivSec").style.className =
        "container-fluid mt-5 mb-5";
    }
  }
  const animRef1 = useRef(null);

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);

  return (
    <>
      <div className="petsHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Bring your pet along…
          "
          des1="Family includes four paws and a wagging tail, so do not leave them behind! We welcome your pup as a distinguished
          guest and pampering awaits them as well. Aviyana is proud to be a pet- friendly resort. Going for walks will be exhilarating
          for your and your pet! – with the vast expanse that Aviyana offers promising hours of exploration and enjoyment."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg2}
          header="Exclusively for your pet"
          des1="Please mention that your pet will be travelling with you and we will forward our special 'Pet Preference' form. There will be a
          charge per dog, per night. For the duration of your stay we will provide a supremely comfortable bed, fluffy towels, duvets,
          food and water bowls with fresh mineral water, and a turndown treat for your V.I.P (Very Important Pet)."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="View our multi-generational experiences "
        />
        <div className="space mt-5 mb-5"></div>

        <div
          onLoad={checkScreen}
          className="container mt-5 mb-5"
          id="mainDivSec"
        >
          <div className="row about-content">
            <div className="about-hotel-bar">
              <div className="txt-area col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div
                  data-aos="fade-up" //text animation
                  className="txt-sec "
                >
                  <h1 className="header-welcome ">
                    We are delighted to offer...
                  </h1>
                  <p className="desc-chapter-11 row">
                    <ul>
                      <li>Dog sitting - please book 24 hours in advance </li>
                      <li>Grooming service - at an additional cost </li>
                      <li>Leashes and litter boxes </li>
                      <li>Dog walking - please book 24 hours in advance </li>
                      <li>Dog treats and toys </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="img-sec col-xl-6 col-lg-12 col-md-12 col-sm-12 ">
                <div className="img-area">
                  <a className="linked-image" href="/">
                    {/* img animation */}
                    <div ref={animRef1} className="animation">
                      <img
                        className="AboutHotel-img"
                        src={aboutHotelimg3}
                        alt="castle"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
        <div className="col"></div>
      </div>
      <div className="row">
        <div className="col"></div>
        <div className="col"></div>
      </div> */}
        </div>
        <div className="space mt-5 mb-5"></div>

        <AboutHotel2
          img={aboutHotelimg4}
          header="Terms and Conditions:"
          des1="You are responsible for any and all property damage and/or personal injuries resulting from your pet. You must further agree to indemnify and hold harmless the hotel, its owners and its operators
          from all liability and damage suffered as a result of your pet. The hotel reserves the right to charge your account an amount commensurate to the cost of such damage, and it will be withheld from
          the damage deposit amount of _______ . This amount will be posted to your hotel folio, as a pet security deposit, and refunded or credited to your account following a room inspection upon
          departure. We invite you to attend this inspection. Should a charge be made against this security deposit, you agree that you will not dispute the charges posted to your credit card. Please be
          aware that these policies are stated to protect both parties, and in no way are a reflection on our desire to host your pet. Should you have any questions, or need clarification on these policies,
          please do not hesitate to contact the Duty Manager."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="View our multi-generational experiences "
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="pets-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Pets;
