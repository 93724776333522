import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./StayInTouch.css";

import arrowHead from "../images/icons/StayArrow.png";

import { theCountryDropdown } from "./NavItems";

function StayInTouch() {
  const [dropDownCounty, setDropdownCountry] = useState(false);

  const selectCountry = (title) => {
    setDropdownCountry(false);
    return (document.getElementById("CountryName").innerHTML = title);
  };

  return (
    <>
      {/* stay-bar for hug screens */}
      <div className="stay-huge-sec">
        <div className="container">
          <div className="stay-huge-bar">
            <div className="row">
              <div className="stay-huge-part col">
                <div className="stay-huge-header">
                  <div className="stay-hufe-header-txt">Stay in touch</div>
                </div>
              </div>
              <div className="stay-huge-part col">
                <div className="stay-huge-header">
                  <div className="input-sec ">
                    <div className="inputHeader">FIRST NAME</div>
                    <div className="data-input">
                      <input
                        type="text"
                        placeholder="Enter Name"
                        className="no-outline"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="stay-huge-part col">
                <div className="stay-huge-header">
                  <div className="input-sec ">
                    <div className="inputHeader">LAST NAME</div>
                    <div className="data-input">
                      <input
                        type="text"
                        placeholder="Enter Last Name"
                        className="no-outline"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="stay-huge-part col">
                <div className="stay-huge-header">
                  <div className="input-sec ">
                    <div className="inputHeader">EMAIL ADDRESS</div>
                    <div className="data-input">
                      <input
                        type="text"
                        placeholder="Enter Email"
                        className="no-outline"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="stay-huge-part col">
                <div className="stay-huge-header">
                  <div className="input-sec ">
                    <div className="inputHeader">COUNTRY</div>
                    <div
                      id="CountryName"
                      className="data-input"
                      onClick={() => setDropdownCountry(!dropDownCounty)}
                    >
                      Select Country
                      <div className="img-sec-stay">
                        <img
                          src={arrowHead}
                          alt=""
                          className={
                            dropDownCounty ? "arrowHead" : "arrowHeadClicked"
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={dropDownCounty ? "Country-drop-sec" : "hide"}
                    >
                      <ul>
                        {theCountryDropdown.map((item) => {
                          return (
                            <li
                              key={item.id}
                              onClick={() => {
                                selectCountry(item.title);
                              }}
                            >
                              <Link to={item.path}>{item.title}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stay-huge-part col">
                <div className="stay-huge-header">
                  <div className="stay-book-btn ">
                    <input
                      classname="stay-submit-btn"
                      type="button"
                      value="SUBMIT"
                      name="submit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* stay-bar for small screen */}
      <div className="stay-sec-small mb-5">
        <div className="container">
          <div className="row">
            <form action="">
              <div className="stay-small-bar ">
                <div className="stay-sec-small-part col">
                  <div className="stay-small-header">Stay in touch</div>
                  <div className="stay-small-btn">
                    <input
                      classname="stay-submit-btn"
                      type="button"
                      value="SUBMIT"
                      name="submit"
                    />
                  </div>
                </div>

                <div className="stay-sec-small-part col">
                  <div className="input-sec ">
                    <div className="inputHeader">FIRST NAME</div>
                    <div className="data-input">
                      <input
                        type="text"
                        placeholder="Enter Name"
                        className="no-outline"
                      />
                    </div>
                  </div>
                  <div className="input-sec ">
                    <div className="inputHeader">EMAIL ADDRESS</div>
                    <div className="data-input">
                      <input
                        type="text"
                        placeholder="Enter Email"
                        className="no-outline"
                      />
                    </div>
                  </div>
                </div>
                <div className="stay-sec-small-part col ">
                  <div className="input-sec ">
                    <div className="inputHeader">LAST NAME</div>
                    <div className="data-input">
                      <input
                        type="text"
                        placeholder="Enter Last Name"
                        className="no-outline"
                      />
                    </div>
                  </div>
                  <div className="input-sec ">
                    <div className="inputHeader">COUNTRY</div>
                    <div
                      id="CountryName"
                      className="data-input"
                      onClick={() => setDropdownCountry(!dropDownCounty)}
                    >
                      Select Country
                      <div className="img-sec-stay">
                        <img
                          src={arrowHead}
                          alt=""
                          className={
                            dropDownCounty ? "arrowHead" : "arrowHeadClicked"
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={dropDownCounty ? "Country-drop-sec" : "hide"}
                    >
                      <ul>
                        {theCountryDropdown.map((item2) => {
                          return (
                            <li
                              key={item2.id}
                              onClick={() => {
                                selectCountry(item2.title);
                              }}
                            >
                              <Link to={item2.path}>{item2.title}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default StayInTouch;
