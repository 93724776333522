import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Ashram.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import AboutHotelWithBtn from "../../components/AboutHotelWithBtn";
import AboutHotelWithBtn2 from "../../components/AboutHotelWithBtn2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Ashram/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Ashram/Box-2.png";
import aboutHotelimg3 from "../../images/Selected/Ashram/Box-3.png"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/Ashram/Box-4.png";

import explorer from "../../images/Selected/Ashram/Ashram.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/spa/Ashram.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Ashram() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="AshramHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 mt-5 ">
          <div className="Header-Content-bar ">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 ">
                  Ancient wisdom meets modern expertise
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 ">
                  Our ashram is a sacred space that encourages you to reconnect
                  to your center, to nature, and to a more authentic flow of
                  life. We are dedicated to restoring balance through treatments
                  and Ashram journeys, personalised for each guest by
                  exceptionally skilled therapists.
                  <br />
                  <p></p> Our commitment to wellbeing focuses on essential
                  ancient wisdom with modern expertise - a luxurious Ashram
                  experience, where guests leave feeling nurtured, purified,
                  pampered, and relaxed.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ashram-header-img-sec1"></div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Yoga"></div>
        <AboutHotelWithBtn
          img={aboutHotelimg1}
          header="Yoga 
          "
          des1="Daily yoga and meditation sessions with Aviyana’s resident yoga specialist restores balance and harmony from the inside out. Private sessions are offered in a range of styles at
          serene locations within the chalets, including at the Ashram. 
          "
          des2=" "
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="Meditation"></div>
        <AboutHotelWithBtn2
          img={aboutHotelimg2}
          header="Meditation"
          des1="Achieve a greater sense of balance and focus, into everyday life with a guided walking meditation session led by one of Aviyana’s master monks. Learn how to deal effectively
          with daily stresses and optimize energy levels, enhance mental clarity, or to elevate mindfulness practice"
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW"
        />
        <div className="space mt-5 mb-5" id="TemporaryOrdination"></div>

        <AboutHotelWithBtn
          img={aboutHotelimg3}
          header="Temporary Ordination
          "
          des1="Provides an opportunity for our guests to experience monasticism without making a lifetime commitment. A 14-day temporary ordination program offers appropriate training to
          conserve the vanishing forest tradition of Theravada Buddhism."
          des2=""
          des3=""
          linkVisible={false}
          link="./PopUpserver"
          linkName="ENQUIRE NOW"
        />
        <div className="space mt-5 mb-5" id="HealthyLiving"></div>

        <AboutHotelWithBtn2
          img={aboutHotelimg4}
          header="Healthy Living "
          des1="Tackling every aspect of health, from movement and nutrition to fitness and spiritual awareness, Aviyana’s Individual Wellness Immersions are year-round, results-driven, multiday programmes designed to leave you with a sense of wellness. A minimum of four nights, our immersive retreats offer different pathways to manifest specific wellness goals
          with uniquely tailored treatments together with expert guidance."
          des3=""
          linkVisible={false}
          link="./PopUpserver"
          linkName="ENQUIRE NOW"
        />
        <div className="space mt-5 "></div>

        <div className="ashram-header-img-sec3"></div>

        {/* des sec end */}

        <div className="Header-Content1">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Sandalwood Suite
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-5">
                  <br />
                  <p>
                    {" "}
                    Your very own space to enjoy the experience of wellness with
                    your loved one by your side. The ultimate experience of
                    private pampering.
                  </p>
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="Header-Content1">
          <div className="Header-Content-bar ">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Red sandalwood Suite
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-5">
                  <br />
                  <p>
                    {" "}
                    A luxuriant space made together with a divine experience of
                    wellness in the privacy of our Red Sandalwood Suite, will
                    leave you completely revived.
                  </p>
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ashram-header-img-sec2"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Ashram;
