import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Accessibility.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Box-44.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Box-45.png";
import aboutHotelimg3 from "../../images/Selected/Box-46.png";

import videobg from "../../images/Selected/HomeVideos/Accessibility.m4v";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function Accessibility() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="AccessibilityHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        {/* des sec start */}
        <div className="space mt-2 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="A warm welcome
          "
          des1="We are designed for people of all abilities. We are proud and honoured to offer an array of disabled facilities and
          accessibility throughout the Resort including rooms, amenities, and services. Enquire now"
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5"></div>

        <AboutHotel2
          img={aboutHotelimg2}
          header="We are here to help"
          des1="Due to our elevated surrounding, we are constricted with regards to structural changes to be totally accessible, however
          whether you have mobility, hearing or visual impairments, our staff are always on hand and more than happy to help
          you. We will ensure that your stay is as comfortable as it is luxurious. Explore our family chalets"
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Accessible room features
          "
          des1=""
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="Accessibility-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Accessibility;
