import React from "react";
import "./IntroHeader.css";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function IntroHeader(props) {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="container mt-5 mb-5" data-aos="fade-up">
        <div className="Intro-header">
          <h1>{props.header}</h1>
        </div>
        <div className="intro-detail">{props.Detail}</div>
      </div>
    </>
  );
}

export default IntroHeader;
