import React, { useState } from "react";
import "./NewCheckDate.css";
import Addrooms from "./Addrooms";
import CalenderDropDown from "./Calender";

function NewCheckDate() {
  const [dropdownCalender, setCalenderDrop] = useState(false);
  const [dropdownAddroom, setAddrooms] = useState(false);

  // const [guestNum, setGuestNum] = useState(1);
  const [roomNum, setRoomNum] = useState(1);
  const [rotate, setRotate] = useState(false);
  const [selectDate, setSelectDate] = useState("Select Date");
  const [adultNum, setAdultNum] = useState(1);
  const [childNum, setChildNum] = useState(0);

  const changeRoomNo = (roomNum) => {
    setRoomNum(roomNum);
  };

  const guestCount = () => {
    var totGuest = adultNum + childNum;
    return totGuest;
  };

  const changeAdultNum = (adultNum) => {
    setAdultNum(adultNum);
  };
  const changeChildNUm = (childNum) => {
    setChildNum(childNum);
  };

  const changeDate = (selectedDate) => {
    setSelectDate(selectedDate);
    document.getElementById("selectDate").innerHTML = { selectDate };
    document.getElementById("DateArrow").style.rotate = "360deg";
  };
  const ClickOnDiv1 = () => {
    setCalenderDrop(!dropdownCalender);
    setRotate(!rotate);
    document.getElementById("ateArrow").style.className =
      "select-arrowClicked ";
  };

  const ClickOnDiv2 = () => {
    setAddrooms(!dropdownAddroom);
  };

  return (
    <>
      <div className="check-data-sec ">
        <div className="booking-bar">
          <div className="check-part">
            <p> CHECK IN / CHECK OUT</p>
            <div className="selector-sec" onClick={() => ClickOnDiv1()}>
              <h3 id="selectDate">{selectDate}</h3>
              <svg
                className={
                  dropdownCalender ? "select-arrowClicked" : "select-arrow"
                }
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke-width="1.4"
                id="DateArrow"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
              <div className="calender-sec1">
                {dropdownCalender && (
                  <CalenderDropDown changeDate={changeDate} />
                )}
              </div>
            </div>
          </div>

          <div className="check-part">
            <p> NUMBER OF PEOPLE</p>
            <div className="selector-sec" onClick={() => ClickOnDiv2()}>
              <h3 id="roomCount">
                {guestCount()}
                Guests, {roomNum} Room
              </h3>
              <svg
                className={
                  dropdownAddroom ? "select-arrow2Clicked" : "select-arrow2"
                }
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke-width="1.4"
                id="RoomArrow"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
              <div className="Addroom-sec1 ">
                {dropdownAddroom && (
                  <Addrooms
                    changeRoomNo={changeRoomNo}
                    changeAdultNum={changeAdultNum}
                    changeChildNUm={changeChildNUm}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="check-part">
            <p> PROMO CODE</p>
            <div className="selector-sec">
              <h3 id="promoCode">
                <input
                  name="promoCode"
                  type="text"
                  placeholder="Enter Code"
                  className="no-outline no-bg"
                />
              </h3>
            </div>
          </div>
          <div className="check-part">
            <div className="selector-sec">
              <input
                type="button"
                name=""
                value="SEARCH"
                className="check_search-tbn"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewCheckDate;
