import React from "react";
import { BsArrowRight } from "react-icons/bs";
import "./HeadMsj.css";
import img1 from "../images/headMsj.jpg";
import img2 from "../images/blender.jpg";

//animation
import { useEffect } from "react";
import "./Animation.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AOS from "aos";
import "aos/dist/aos.css";
import { useRef } from "react";

function HeadMsj() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);
  const animRef2 = useRef(null);
  const animRef3 = useRef(null);
  const animRef4 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
    ScrollTrigger.create({
      trigger: animRef2.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);

  return (
    <>
      <div className="headMsj-bar-sec mt-5 mb-5">
        <div className="headMsj-bar">
          <div className="row">
            <div className="headMsj-part col-sm-12 col-md-12 col-lg-12 col-xl-12  col-xxl-5 ">
              <div className="haeder-des">
                <div className="headMsj-img-sec">
                  <div ref={animRef1} className="animation">
                    <img src={img1} alt="" className="headMsj-img" />
                  </div>
                </div>
                <div className="headMsj-txt-sec">
                  <div data-aos="fade-up" className="headMsj-txt">
                    <h1 className="headMsj-header">Wellness escapes</h1>
                    <p className="headmsj-des">
                      To enhance your sense of wellbeing even further we invite
                      you to visit one of Sri Lankan most wonderful spas for
                      some extra indulgence and pampering.
                    </p>
                    <a
                      className="link22"
                      // href="/luxury-town-country-staycation"
                    >
                      Discover more <BsArrowRight className="link-arrow-sc-5" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-0 col-md-0 col-lg-0 col-xl-0  col-xxl-2"></div>
            <div className="headMsj-part col-sm-12 col-md-12 col-lg-12 col-xl-12  col-xxl-5">
              <div className="haeder-des">
                <div className="headMsj-img-sec">
                  <div ref={animRef2} className="animation">
                    <img src={img2} alt="" className="headMsj-img" />
                  </div>
                </div>
                <div className="headMsj-txt-sec">
                  <div data-aos="fade-up" className="headMsj-txt">
                    <h1 className="headMsj-header">
                      We care about our natural splendour
                    </h1>
                    <p className="headmsj-des">
                      We believe deeply that our legacy is as important as the
                      joyful experiences we offer to guests. We are proud of our
                      many initiatives that care for our local community and our
                      spectacular surroundings.
                    </p>
                    <a
                      className="link22"
                      // href="/luxury-town-country-staycation"
                    >
                      Explore how we #MakeTravelMatter{" "}
                      <BsArrowRight className="link-arrow-sc-5" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeadMsj;
