import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";

import "./InPress.css";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";

function InPress() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="InPress-intro-header">
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />

              {/* <h1>AVIYANA</h1> */}
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />
        <div className="space mt-5 mb-5"></div>
        <div className="image-gallary-intro-header mb-5 mt-5">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1  mb-4">
                  In the Press
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Scroll through our latest updates and news or search by
                  keyword
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="space mt-5 mb-5"></div>
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default InPress;
