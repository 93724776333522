import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./ExperienceSub.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/drinking/box-1.jpg"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/drinking/box-2.jpg";
import aboutHotelimg3 from "../../images/Selected/drinking/box-3.jpg"; //chnage the img
import aboutHotelimg4 from "../../images/Selected/drinking/box-4.jpg";
import aboutHotelimg5 from "../../images/Selected/drinking/box-5.jpg"; //chnage the img
import aboutHotelimg6 from "../../images/Selected/drinking/box-6.jpg";

import explorer from "../../images/Selected/drinking/E.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/dinning/Experience.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function ExperienceSub() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="ExperienceSubHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content " id="PickandCook">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Pick and Cook
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Awaken your inner chef as you join our celebrity chefs for an
                  incredible hands-on experience of cooking an exciting menu of
                  your choice. At Aviyana its simply not just ‘Cooking with the
                  Chefs’, we have articulated a special garden compound farm in
                  the Aviyana Estate on the hill, exclusively for this novel
                  experience, where different varieties of vegetables, herbs are
                  planted.
                  <br />
                  <p></p>Guests could visit the farm, pick the vegetables and
                  herbs that are grown and ready to be harvested. Bring it to
                  the hotel and cook under the guidance of our Chef. Neat garden
                  baskets with garden tools and aprons are available for the
                  guests when they go on this unique harvesting excursion.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ExpSub-header-img-sec1"></div>

        <div className="Header-Content " id="Tastethespice">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-5 mb-4">
                  Taste the spice
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Aviyana invites you a journey at their newly launched ‘Taste
                  the spice’ culinary experience. Taste the spice offers an
                  open-kitchen style concept where guests can see what is
                  available on the menu before placing their order.
                  <br />
                  <p></p>. Action stations for hoppers and roti (authentic Sri
                  Lanka fare) ensure that food is prepared fresh to order.
                  Guests are invited to order from an A-la-carte style menu
                  (offered on a traditional cane kulla or basket) with a
                  detailed list of the staples and curries with drinks and
                  desserts. All menu items are individual portions.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ExpSub-header-img-sec2"></div>

        <div className="Header-Content " id="handcraft">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-5 mb-4">
                  Make your handcraft
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Aviyana Chocolate is the first bean to bar artisanal chocolate
                  made from Sri Lankan cocoa beans. Aviyana roasts at altitude
                  in the Sri Lankan hills. You can now join us for amazing
                  chocolate-making sessions in our Bean to Bar Experiences.
                  <br />
                  <p></p>Guests will learn the noble art of chocolate making,
                  where our chocolatier will show you how to roast and conch
                  cocoa and guide you to make your own bar of chocolate, and
                  master the art of creating heavenly morsels.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ExpSub-header-img-sec3"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default ExperienceSub;
