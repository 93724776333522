import React, { useState } from "react";
import { Link } from "react-router-dom";
import { theSpaDropdown } from "./NavItems";
import "./SpaDropdown.css";
import { HashLink } from "react-router-hash-link";

function SpaDropdown() {
  const [dropdown, setDropdown] = useState(false); // create stute

  const redirectToPage = (a) => {
    window.location = a;
  };

  return (
    <>
      <div className="navigation-sub-title-sec">
        <div className="navigation-sub-nav-bar ">
          <div className="navigation-sub-nav-part col">
            <div className="subNav-part-sec1">
              <ul className="sub-title-list">
                <div className="subnav-header-sec">
                  <li>
                    <p
                      className="subNavHeader"
                      onClick={() => redirectToPage("/spaFacilities")}
                    >
                      - Spa
                    </p>
                  </li>
                </div>
                {theSpaDropdown.map((item) => {
                  if (item.id < 6) {
                    return (
                      <div className="subnav-list-item">
                        <li key={item.id}>
                          <HashLink
                            to={item.path}
                            className="submenu-item1 "
                            // onClick={() => redirectToPage(item.path)}
                          >
                            {item.title}
                          </HashLink>
                        </li>
                      </div>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="navigation-sub-nav-part col">
            <div className="subNav-part-sec1">
              <ul className="sub-title-list">
                <div className="subnav-header-sec">
                  <li>
                    <p
                      className="subNavHeader"
                      onClick={() => redirectToPage("/Ashram")}
                    >
                      - Ashram
                    </p>
                  </li>
                </div>
                {theSpaDropdown.map((item) => {
                  if (item.id > 5 && item.id < 10) {
                    return (
                      <div className="subnav-list-item">
                        <li key={item.id}>
                          <HashLink
                            to={item.path}
                            className="submenu-item1"
                            // onClick={() => redirectToPage(item.path)}
                          >
                            {item.title}
                          </HashLink>
                        </li>
                      </div>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="navigation-sub-nav-part col">
            <div className="subNav-part-sec1">
              <ul className="sub-title-list">
                <div className="subnav-header-sec">
                  <li>
                    <p
                      className="subNavHeader"
                      onClick={() => redirectToPage("/Wellness")}
                    >
                      - Wellness Ayurveda{" "}
                    </p>
                  </li>
                </div>
                {theSpaDropdown.map((item) => {
                  if (item.id > 9) {
                    return (
                      <div className="subnav-list-item">
                        <li key={item.id}>
                          <HashLink
                            to={item.path}
                            className="submenu-item1"
                            // onClick={() => redirectToPage(item.path)}
                          >
                            {item.title}
                          </HashLink>
                        </li>
                      </div>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpaDropdown;
