import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./VirtualTour.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Box-42.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Box-43.png";

import videobg from "../../images/Selected/HomeVideos/VirtualTour.m4v";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function VirtualTour() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="VirtualTourHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="sustainability-intro-header mt-5 pt-5">
          <div className="Header-Content-bar">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1  mb-4">
                  Aviyana virtual tour
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-2">
                  Before embarking on your adventure to visit us, we are
                  delighted to offer you a rare opportunity to explore Aviyana
                  virtually. Visualise your upcoming stay and enjoy a glimpse of
                  the ins and outs of our magnificent hotel with our online
                  tour. Our 3D tour will allow you to survey some of the
                  creativity we poured into each of the spectacular spaces.
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <LocationMap /> */}

        {/* des sec start */}
        <div className="space mt-2 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Chalets
          "
          des1="Each of our 60 Chalets and suites represent traditional elegance and finesse. The rooms offers a soothing experience and
          have been individually and lovingly designed, combining the natural features with the latest modern luxuries."
          des2=" "
          des3=""
          linkVisible={true}
          link="/"
          linkName="Explore our rooms "
        />
        <div className="space mt-5 mb-5"></div>

        <AboutHotel2
          img={aboutHotelimg2}
          header="Dining & drinks"
          des1="Discover creative Sri Lankan cuisine inspired by the finest seasonal ingredients from the Sri Lanka and produce foraged
          from the Aviyana Estate, alongside an extensive wine cellar kept in the Aviyana's secret passageways."
          des2=""
          des3=""
          linkVisible={true}
          link="/"
          linkName="Explore dining at Ashford "
        />

        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="Virtual-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default VirtualTour;
