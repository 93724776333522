import img1 from "../galleryImages/img1.jpg";
import img2 from "../galleryImages/img2.jpg";
import img3 from "../galleryImages/img3.jpg";
import img4 from "../galleryImages/img4.jpg";
import img5 from "../galleryImages/img5.jpg";
import img6 from "../galleryImages/img6.jpg";
import img7 from "../galleryImages/img7.jpg";
import img8 from "../galleryImages/img8.jpg";
import img9 from "../galleryImages/img9.jpg";
import img10 from "../galleryImages/img10.png";
import img11 from "../galleryImages/img11.jpg";
import img12 from "../galleryImages/img12.jpg";

const prevGalImages = [
  { id: 13, imgSrc: img1, name: "Image 13", text: "hello Sri Lanka 13" },
  { id: 14, imgSrc: img2, name: "Image 14", text: "hello Sri Lanka 14" },
  { id: 15, imgSrc: img3, name: "Image 15", text: "hello Sri Lanka  15" },
  { id: 16, imgSrc: img4, name: "Image 16", text: "hello Sri Lanka16" },
  { id: 17, imgSrc: img5, name: "Image 17", text: "hello Sri Lanka 17" },
  { id: 18, imgSrc: img6, name: "Image 18", text: "hello Sri Lanka 18" },
  { id: 19, imgSrc: img7, name: "Image 19", text: "hello Sri Lanka 19" },
  { id: 20, imgSrc: img8, name: "Image 20", text: "hello Sri Lanka 20" },
  { id: 21, imgSrc: img9, name: "Image 21", text: "hello Sri Lanka 21" },
  { id: 22, imgSrc: img10, name: "Image 22", text: "hello Sri Lanka 22" },
  { id: 23, imgSrc: img11, name: "Image 23", text: "hello Sri Lanka 23" },
  { id: 24, imgSrc: img12, name: "Image 24", text: "hello Sri Lanka 24" },
];

export default prevGalImages;
