import galData from "./GalleryData.js";
import prevGalImages from "./PrevGalImages.js";
import "../Styles/ImageGalleryStyles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsInstagram } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { useState } from "react";

//animation
import { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import { useRef } from "react";

function ImageGallery(props) {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  let headerData = [
    {
      header: "Share your #AshfordCastle story",
      description:
        "We are always delighted to discover our guests' photos and we love to share those stories and connect in a meaningful way. Nothing makes us happier than when our guests become part of our world...",
    },
  ];
  const [popupcontent, setPopupContent] = useState([]);
  const [popuptogle, setpopuptogle] = useState(false);
  const getItem = (item) => {
    setPopupContent([item]);
    setpopuptogle(!popuptogle);
  };
  const [galleryData, setgalleryData] = useState(galData);

  const viewMoreImages = () => {
    setgalleryData((galleryData) => galleryData.concat(prevGalImages));
  };

  return (
    <div className="container-fluid p-2">
      {headerData.map((item, index) => {
        return (
          <div className="row justify-content-center" key={index}>
            <div className="container gallary-header-sec">
              <div
                data-aos="fade-up"
                className="gallery-header-content text-center mb-5 mt-5"
                key={item.index}
              >
                <h1 className="gal-header">{props.header}</h1>

                <p className="gal-desc">{props.description}</p>
              </div>
            </div>
          </div>
        );
      })}
      <div className="row">
        <div className="col">
          <div className="gallery-img-sec">
            <div className="row">
              {galleryData.map((item, index) => {
                return (
                  <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 px-1 py-1 ">
                    <div
                      className="gal-content"
                      key={index}
                      onClick={() => getItem(item)}
                    >
                      <div className="gal-image fadein">
                        <img src={item.imgSrc} alt="" />
                      </div>
                      <div className="image-content">
                        <p className="gal-image-desc position-absolute top-0">
                          {item.text}
                        </p>
                        <p className="gal-image-user position-absolute bottom-0">
                          <BsInstagram className="" />
                          {item.name}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {popuptogle && (
        <div className="row popup-container position-fixed">
          {popupcontent.map((pop) => {
            return (
              <div className="col">
                <div className="popup-body position-relative">
                  <div className="popup-close-button position-absolute top-0 end-0">
                    <GrClose onClick={getItem} className="close-icon" />
                  </div>
                  <div className="popup-header-content">
                    <div className="popup-user-content d-flex flex-column">
                      <a href="" className="popup-user-name">
                        <BsInstagram className="icon" /> {pop.name}
                      </a>
                      <a href="" className="popup-name-tag">
                        @{pop.name}
                      </a>
                    </div>
                  </div>
                  <div className="popup-image">
                    <img src={pop.imgSrc} alt="" />
                  </div>
                  <div className="popup-caption-content">
                    <p className="popup-caption">{pop.text}</p>
                    <p className="popup-timestamp position-absolute bottom-0 start-50 translate-middle-x">
                      week ago
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="row">
        <div className="col px-0">
          <div className="prev-gal-images">
            <button className="view-previmg-btn" onClick={viewMoreImages}>
              DISCOVER MORE STORIES
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageGallery;
