import React from "react";
import { useRef } from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./Conferences.css";
import AboutHotelWithBtn from "../../components/AboutHotelWithBtn";
import AboutHotelWithBtn2 from "../../components/AboutHotelWithBtn2";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Wedding/Conferences/Box-1.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Wedding/Conferences/Box-2.png";

import explorer from "../../images/Selected/Wedding/Conferences/Meetings.png"; //replace icon

import videobg from "../../images/Selected/HomeVideos/wedding/Conferences.m4v"; //replace the home banner to video

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function checkScreen() {
  if (window.innerWidth > 1200) {
    document.getElementById("mainDivSec").style.className =
      "container mt-5 mb-5";
  } else {
    document.getElementById("mainDivSec").style.className =
      "container-fluid mt-5 mb-5";
  }
}

export default function Conferences() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);
  return (
    <>
      <div className="ConferencesHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 ">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 ">
                  Host your meetings, inspired by nature!
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 ">
                  The Aviyana has curated a variety of locations on property
                  that can be utilized as venues for your conferences and
                  meetings.
                  <p></p> Our special spaces encourage delegates to truly ‘break
                  out’ of the normal confines of a meeting room, whether for
                  informal sessions or as an opportunity to bring some of the
                  outside elements inside. We will help you re-imagine meetings
                  with fewer barriers and greater rewards and be able to
                  accomplish your business goals while providing a full
                  itinerary of outdoor activity to motivate and inspire.
                  <p></p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* des sec start */}
        <div className="space mt-5 mb-5" id="Spafacilities"></div>
        <AboutHotelWithBtn
          img={aboutHotelimg1}
          header="Conferences, Meetings and Events
          "
          des1="Whether you are looking to engage, encourage or entertain on a corporate or a social setting, Aviyana has the right local venue from exotic mountains to rural settings to keep your guests pampered, entertained, and involved. Attention to detail and personalized service are the traditions we maintain and our approach to event planning is consistent and agile."
          des2=" 
          "
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="TheSalon"></div>
        <AboutHotelWithBtn2
          img={aboutHotelimg2}
          header="Engage and Inspire"
          des1="Aviyana has the most extensive and versatile events space in Sri Lanka. Offering a range of innovative venues including 60 chalets, the largest pillar-less ballroom in the region that can cater to seated banquets for up to xxx guests. The property also consists of accommodation for around 240 guests, a large outdoor space and Spices, our dedicated function chalets.
          We have made well-being a central part of meeting offerings. The sheer nature of our resort allows us to redefine healthfulness beyond the conventional descriptors. Whether you are hosting a high-powered brainstorm session, or an informative seminar, our inspiring flexible settings and responsible practices ensure attendees feel accomplished, invigorated and ready to perform at their best.
           
          "
          des3=""
          linkVisible={true}
          link="./PopUpserver"
          linkName="ENQUIRE NOW "
        />
        <div className="space mt-5 mb-5" id="Spabrands"></div>

        {/* des sec end */}

        <div className="Conference-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}
