import React from "react";
import { BsArrowRight } from "react-icons/bs";
import "./Experience.css";
import EXperinceImgSlider from "../components/EXperinceImgSlider";
import { ExGallary, expdata, ExGallary22 } from "../components/SliderData";
import OurteamSlider from "../components/OurteamSlider";
import Footer from "../components/Footer";
import StayInTouch from "../components/StayInTouch";

import ExploreBars from "../components/ExploreBars";

import aviyanaNameTag from "../images/aviyanaNameTag.png";

import explorer from "../images/Selected/experiences/Explorer.png";
import videobg from "../images/Selected/experiences/Home/ExperienceHomeVideo.m4v";
import eximg4 from "../images/Selected/experiences/Home/Box-5.png";
import eximg5 from "../images/Selected/experiences/Home/Box-4.png";
import NewCheckDate from "../components/NewCheckDate.js";
function Experience() {
  return (
    <>
      <div className="ExpMainHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 mt-5 ">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Explore activities for mind body and soul
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-5">
                  Our expansive 24-acre estate lends itself perfectly to a
                  selection of exhilarating activities and experiences. The
                  grounds of Aviyana have provided space for thrilling estate
                  activities for its owners and their clients.
                  <br />
                  <p></p> The expansive 24-acre Aviyana Estate lends itself
                  perfectly to a range of activities, such as horse riding,
                  fishing,Air Ballooning, Bird watching, River Walk, Biking Tour
                  & Cycling, Scenic Walking, Hiking & Tracking Estate gardens,
                  Equestrian Jeep Safari Camping Day/Night, River Drifting,
                  Guided Nature Experiences,High tea by the lake to more modern
                  activities such as sky diving and cycling. So whether you are
                  the active type or prefer to relax and soak up the scenery,
                  you are spoilt for choice at Aviyana.
                  <p></p>
                  <br />
                  <a className="ExpHomelink" href="/">
                    View our activities brochure
                    <BsArrowRight className="link-arrow" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <EXperinceImgSlider header="Outdoor Activities" arrayName={ExGallary} />
        <OurteamSlider
          header="Exhilarating activities"
          value={true}
          linkName="Explore more"
          arrayName={expdata}
          linkName2="Explore more "
        />

        <ExploreBars
          BarImg1={eximg4}
          header1="Cocktail Evenings"
          des1="Unwind after a day out in Kandy and sip some of the best made
          classic cocktails, or try our inventive new mixes filled with surprises
          and exotic local ingredients.
          "
          linkName1="Explore more"
          link1="/"
          BarImg2={eximg5}
          header2="Fire Pit"
          des2="You’re beautifully chalet in the views features an outdoor
          terrace with fire pit for relaxing in the evenings."
          linkName2="Explore more"
          link2="/"
        />
        <EXperinceImgSlider header="" arrayName={ExGallary22} />

        <div className="container advanced-booking-sec  mt-5 mb-5">
          <iv className="row">
            <div className="advanced-header">
              <h1>Advance bookings are recommended</h1>
            </div>
            <div className="details-sec mt-5 mb-5">
              <div className="details-col col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <p>
                  All the above mentioned activities are provided and organised
                  by independent companies/contractors and booked at your own
                  risk.
                </p>
                <p>
                  Safety information for gardens and grounds - there are low
                  walls around the gardens and grounds. Children must be
                  supervised by a responsible adult at all times. No
                  diving/paddling/swimming is permitted in the lake, river or
                  fountains.
                </p>
              </div>
              <div className="details-col col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <p>
                  Safety information for the lake and river - the waters in the
                  river and lake are deep. Children must be accompanied and
                  supervised by an adult at all times.
                </p>
                <p>
                  Safety information for ramblers - the terrain is uneven, all
                  guests must be suitably attired and activities are undertaken
                  at your own risk.
                </p>
              </div>
            </div>
          </iv>
        </div>

        <div className="Header-Content1 mt-5 pt-5">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <h1 data-aos="fade-up" className="hotel-semi-title1 ">
                  A HAVEN OF ADVENTURE
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-5">
                  <a className="ExpHomelink">
                    Activities at Aviyana
                    <BsArrowRight className="link-arrow" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="exHome-header-img-sec1"></div>
        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default Experience;
