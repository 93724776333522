import React from "react";
import { useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../pages/Spa.css";
import Footer from "../components/Footer";
import StayInTouch from "../components/StayInTouch";
import ImageGallery from "../components/ImageGallery";
import { BsArrowRight } from "react-icons/bs";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import ExploreBars from "../components/ExploreBars";
import NewCheckDate from "../components/NewCheckDate.js";

import videobg from "../images/Selected/spa/Spahome1.m4v";
import video1 from "../images/Selected/spa/ancientWisdom.m4v";

import aviyanaNameTag from "../images/aviyanaNameTag.png";
import explorer from "../images/Selected/spa/Spa.png";

import bar1 from "../images/Selected/spa/replace/Box-1.png";
import bar2 from "../images/Selected/spa/replace/Box-2.png";
import bar3 from "../images/Selected/spa/replace/Box-3.png";
import bar4 from "../images/Selected/spa/replace/Box-4.png";
import bar5 from "../images/Selected/spa/replace/Box-5.png";

function Spa() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef2 = useRef(null);
  const animRef3 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef2.current,
      toggleClass: "active",
      end: "top -1500px",
    });
    ScrollTrigger.create({
      trigger: animRef3.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);

  function checkScreen() {
    if (window.innerWidth > 1200) {
      document.getElementById("mainDivSec").style.className =
        "container mt-5 mb-5";
    } else {
      document.getElementById("mainDivSec").style.className =
        "container-fluid mt-5 mb-5";
    }
  }

  return (
    <div>
      <div className="spa-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="homeBody">
        <NewCheckDate />

        <div className="Header-Content1 mt-5 pt-5">
          <div className="Header-Content-bar mt-5 pt-3">
            <div className="details-sec row">
              <div className="col">
                <img src={explorer} alt="" className="TheAviyana-icon1" />
                <h2 className="hotel-title1">Aviyana</h2>
                <p className="hotel-address mb-5 pb-2">KANDY, SRI LANKA</p>

                <h1 data-aos="fade-up" className="hotel-semi-title1 mt-4 mb-4">
                  Spa
                </h1>
                <p data-aos="fade-up" className="hotel-desc1 mb-5 pb-5">
                  Utterly serene and seemingly beyond time’s reach in Aviyana’s
                  lush gardens, the resort’s holistic Aviyana Spa is a deeply
                  cocooning place in which to reconnect.
                  <br />
                  <p></p>Expert wellness specialists offer a range of therapies
                  and treatments, and the in-house Ayurvedic doctor personally
                  curates multi-day Individual Wellness Immersions drawing on
                  the wisdom of this ancient healing philosophy.
                  <p></p>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          onLoad={checkScreen}
          className="container mt-5 mb-5"
          id="mainDivSec"
        >
          <div className="row about-content">
            <div className="about-hotel-bar">
              <div className="txt-area col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div
                  data-aos="fade-up" //text animation
                  className="txt-sec "
                >
                  <h1 className="header-welcome ">
                    Ancient wisdom meets modern expertise
                  </h1>
                  <p className="desc-chapter-11 row">
                    We are dedicated to restoring and balance through treatments
                    and Ashram journeys, personalised for each of our guests by
                    exceptionally skilled therapists. Our commitment to
                    wellbeing focuses on essential ancient wisdom with modern
                    expertise.A luxurious Ashram experience, our guests leave
                    feeling nurtured, purified, pampered, and relaxed.
                  </p>
                  <p className="desc-chapter-22"></p>
                  <p className="desc-chapter-22"></p>
                  {/* <a className="link111" href="/">
                    lln <BsArrowRight className="link-arrow" />
                  </a> */}
                </div>
              </div>
              <div className="img-sec col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="img-area1">
                  <video
                    autoPlay
                    muted
                    loop
                    src={video1}
                    className="boxVideo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ExploreBars
          BarImg1={bar1}
          header1="Spa facilities "
          des1="The best spas in the world harmonise these philosophies, so we built Aviyana as
          Sri Lanka’s first full-fledged destination spa. Our spa tucked into the hillside’s
          natural contours has been thoughtfully designed with holistic wellness in mind."
          link1="/"
          linkName1="Explore more"
          BarImg2={bar2}
          header2="The Salon"
          des2="All hair services can be personalised to suit your needs and
          can be included with any service."
          linkName2="Explore more"
          link2="/"
        />

        <ExploreBars
          arrowVisible1={true}
          BarImg1={bar3}
          header1="Spa brands 
          "
          des1="Afternoon Tea is a quintessentially English tradition and is
          upheld in its finest form at the Aviyana."
          linkName1="Explore more"
          link1="/"
          arrowVisible2={true}
          BarImg2={bar4}
          header2="Yoga"
          des2="Daily yoga and meditation sessions with the Aviyana’s
          resident yoga specialist rebalances body and mind. 
          "
          linkName2="Explore more"
          link2="/"
        />

        <div>
          <div className="container-md mt-5 pt-5 pb-5 mb-5">
            <div className="row middle-sec  ">
              <div className="meditaionSec">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                  <div className="image">
                    <div ref={animRef3} className="animation">
                      <img src={bar5} alt="" />
                    </div>
                  </div>
                  <div data-aos="fade-up" className="description mt-5">
                    <h1 className="Exheader">Meditation</h1>
                    <p className="desc2">
                      Bring the power of relaxation into everyday life with a
                      guided walking meditation session led by one of Aviyana’s
                      master monks.
                    </p>
                    <a
                      className="link2"
                      // href={props.link2}
                    >
                      Explore more
                      <BsArrowRight className="link-arrow-sc-5" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="spaHome-header-img-sec1 mb-4"></div>

        <ImageGallery
          header="Share your #Aviyana Ashram experience"
          description=""
        />
        <StayInTouch />
        <Footer />
      </div>
    </div>
  );
}

export default Spa;
