import React from "react";

import StayInTouch from "../../components/StayInTouch";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import Footer from "../../components/Footer";
import "./AviyanaConcept.css";
import NewCheckDate from "../../components/NewCheckDate.js";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import aboutHotelimg1 from "../../images/Selected/Box-11.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Box-12.png";
import aboutHotelimg3 from "../../images/Selected/Box-13.png"; //chnage the img

import videobg from "../../images/Selected/HomeVideos/AviyanaConcept.m4v"; //replace the home banner to video

function AviyanaConcept() {
  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div className="AviyanaConcept-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />

              {/* <h1>AVIYANA</h1> */}
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Aviyana is Sri Lanka’s first ever 7-star hotel to be built 
          "
          des1="A one of a kind 7-star type luxury boutique hotel project in the heart of the Knuckles mountain range in the Kandy District, Aviyana Private Chalets will be the
          pivotal point of the local hotel industry. We are also the largest investment generated hotel being built in the country to-date, with 60 differently-styled star
          class chalets on offer. An exciting opportunity, inviting all prospective investors - we offer a guaranteed return unlike any other company in Sri Lanka. We
          intend to offer the very best in features and facilities to uphold the luxury lifestyle and to ensure the welfare of our clients unlike any other hotel in the country."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <AboutHotel2
          img={aboutHotelimg2}
          header="OWN | EARN | LIVE"
          des1="Aviyana hotel complex is precisely the innovation needed to uplift Sri Lanka’s tourism sector to its next level - a much-needed move for our nation’s economic recovery and
          sustainability. With a range of investment packages to choose from, we offer local investors a chance to be part of a game-changing business strategy. The concept will
          revolutionise the industry with its outside-the-box approach to hospitality and real estate, which combines spiritual well-being and opulence to offer our guests an experience
          that can be life-changing. By upgrading the standard 5-star category to 7-star splendour, the Aviyana brand takes on a remarkable transformation to produce optimal ROI."
          des2=""
          des3=""
          linkVisible={false}
          link="/"
          linkName="Discover more about Aviyana"
        />
        <div className="space mt-5 mb-5"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Unbroken bonds"
          des1="The wonderful community that surrounds us has always been an important part of our family. However, during these uncertain times,
          our people – and the fascinating stories that they weave – have become an essential part of the Aviyana story. In Sri Lanka, we use
          the word family to encompass everything our local community means to us… Together with our team, the local artisans, creators,
          families and partners whom we proudly call the ‘Aviyana Family’, we aim to provide you a true taste of Sri Lanka."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />

        <div className="space mt-5 mb-5"></div>
        {/* des sec end */}

        <div className="AviyanaConcept-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default AviyanaConcept;
