import React from "react";
import StayInTouch from "../../components/StayInTouch";
import Footer from "../../components/Footer";
import NewCheckDate from "../../components/NewCheckDate.js";
import "./WeddingAndEvents.css";
import AboutHotel from "../../components/AboutHotel";
import AboutHotel2 from "../../components/AboutHotel2";

import aviyanaNameTag from "../../images/aviyanaNameTag.png";
import aboutHotelimg1 from "../../images/Selected/Box-39.png"; //chnage the img
import aboutHotelimg2 from "../../images/Selected/Box-40.png";
import aboutHotelimg3 from "../../images/Selected/Box-41.png";
import videobg from "../../images/Selected/HomeVideos/WeddingE.m4v";

import { useEffect } from "react";
import { useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function WeddingAndEvents() {
  function checkScreen() {
    if (window.innerWidth > 1200) {
      document.getElementById("mainDivSec").style.className =
        "container mt-5 mb-5";
    } else {
      document.getElementById("mainDivSec").style.className =
        "container-fluid mt-5 mb-5";
    }
  }

  // text animation
  // use "fade-up" class name with this function
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const animRef1 = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: animRef1.current,
      toggleClass: "active",
      end: "top -1500px",
    });
  }, []);

  return (
    <>
      <div className="weddingAndeventsnHome-header">
        <video autoPlay muted loop source src={videobg} />
        <div className="container">
          <div className="home-header-txt1">
            <div className="home-header-sec">
              <img src={aviyanaNameTag} alt="" />
            </div>

            <div className="home-des-sec">
              <h4>The Magical Touch of Luxury</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="homeBody">
        <NewCheckDate />

        {/* <LocationMap /> */}

        {/* des sec start */}
        <div className="space mt-5 mb-5"></div>
        <AboutHotel
          img={aboutHotelimg1}
          header="Celebrate new Beginnings …and other memorable Events
          "
          des1="Weddings are the celebration of a new beginning. Toast to true love in a truly magical location. Our venue serves as the most important
          accessory to that one special day that is filled with love making your dreams come true. Our mesmerizing mountain views, breathtaking setting
          and peacefulness beautifully encapsulates that perfect dream celebration. Invite family and friends and mark unforgettable milestones in life
          or simply enjoy an event in a stunning setting. Our terraces boasting mountain and sunset views are perfect for relaxing and socializing."
          des2=" "
          des3=""
          linkVisible={false}
          link="/"
          linkName="View all our awards and accolades"
        />
        <div className="space mt-5 mb-5"></div>
        <div
          onLoad={checkScreen}
          className="container mt-5 mb-5"
          id="mainDivSec"
        >
          <div className="row about-content">
            <div className="about-hotel-bar">
              <div className="img-sec col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="img-area">
                  <a className="linked-image" href="/">
                    {/* img animation */}
                    <div ref={animRef1} className="animation">
                      <img
                        className="AboutHotel-img"
                        src={aboutHotelimg2}
                        alt="castle"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="txt-area col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div
                  data-aos="fade-up" //text animation
                  className="txt-sec "
                >
                  <h1 className="header-welcome ">Intimate weddings</h1>
                  <p className="desc-chapter-11 row">
                    For intimate weddings, the beautiful and serene surroundings
                    of Aviyana are perfectly suited to small celebrations as
                    well as large lavish affairs. Whether you are opting for a
                    celebration for just the two of you together, or with your
                    nearest and dearest, our dedicated staff will make sure your
                    special moment together is everything you could wish for.
                    <p>
                      <ul className="mt-3">
                        <li>Blessings</li>
                        <li>Vow renewals</li>
                        <li>
                          Beautiful rooms and bridal suites, or opt for our
                          Chalets
                        </li>
                      </ul>
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space mt-5 mb-5"></div>

        <AboutHotel
          img={aboutHotelimg3}
          header="Plan your big day...
          "
          des1="From the flowers to the photography, to the cake and the entertainment, to
          the order of the day and all of the fine details in between, we will arrange
          the day you have always dreamed about. The difference is in the detail."
          des2=" "
          des3=""
          linkVisible={true}
          link="/"
          linkName="Enquire now"
        />
        <div className="space mt-5 mb-5"></div>

        {/* des sec end */}

        <div className="weddingAndEvents-header-img-sec1"></div>

        <StayInTouch />
        <Footer />
      </div>
    </>
  );
}

export default WeddingAndEvents;
