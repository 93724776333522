import React from "react";
import { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import "./ImgGallaryPageComponent.css";

function ImgGallaryPageComponent(props) {
  const [veiwMoreGallery, setveiwMoreGallery] = useState(true);
  return (
    <>
      <div className="container">
        <div class="grid-container mt-5 mb-5">
          <div class="item1">
            <img src={props.gimg1} alt="" />
          </div>
          <div class="item2">
            <img src={props.gimg2} alt="" />
          </div>
          <div class="item3">
            <img src={props.gimg3} alt="" />
          </div>
          <div class="item4">
            <img src={props.gimg4} alt="" />
          </div>
          <div class="item5">
            <img src={props.gimg5} alt="" />
          </div>
          <div class="item6">
            <img src={props.gimg6} alt="" />
          </div>
          <div class="item7">
            <img src={props.gimg7} alt="" />
          </div>
        </div>

        <div className="dicover-more">
          <a
            className={veiwMoreGallery ? "link mt-3 mb-3" : "hide"}
            onClick={() => setveiwMoreGallery(!veiwMoreGallery)}
          >
            Dicsover More
            <BsArrowRight className="link-arrow" />
          </a>
        </div>
        <div className={veiwMoreGallery ? "hide" : "grid-container mt-5 mb-5"}>
          <div class="item1">
            <img src={props.gimg1} alt="" />
          </div>
          <div class="item2">
            <img src={props.gimg2} alt="" />
          </div>
          <div class="item3">
            <img src={props.gimg3} alt="" />
          </div>
          <div class="item4">
            <img src={props.gimg4} alt="" />
          </div>
          <div class="item5">
            <img src={props.gimg5} alt="" />
          </div>
          <div class="item6">
            <img src={props.gimg6} alt="" />
          </div>
          <div class="item7">
            <img src={props.gimg7} alt="" />
          </div>
        </div>
        <div className="dicover-more">
          <a
            className={veiwMoreGallery ? "hide" : "link mt-3 mb-3"}
            onClick={() => setveiwMoreGallery(!veiwMoreGallery)}
          >
            Dicsover More
            <BsArrowRight className="link-arrow" />
          </a>
        </div>
      </div>
    </>
  );
}

export default ImgGallaryPageComponent;
