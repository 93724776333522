import React from "react";

function LocationMap() {
  return (
    <>
      <div className="map-sec">
        <iframe
          width="100%"
          height="573"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          id="gmap_canvas"
          src="https://maps.google.com/maps?width=1920&amp;height=573&amp;hl=en&amp;q=Sri%20Wickrama%20Rajasinghe%20Mawatha,%20%20Kandy+(Kandy%20City%20center)&amp;t=&amp;z=21&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </div>
    </>
  );
}

export default LocationMap;
